<template>
  <div 
    class="chat-interface"
    @touchmove.stop
    @wheel.stop
  >
    <div 
      class="messages" 
      ref="messagesRef"
      @scroll="handleScroll"
      @touchmove.stop
      @wheel.stop
    >
      <div 
        class="messages-container"
        @touchmove.stop
        @wheel.stop
      >
        <div 
          v-for="message in displayMessages" 
          :key="message.id" 
          class="message" 
          :class="message.type"
        >
          <div class="message-content">{{ message.content }}</div>
          <div class="message-time">{{ formatTime(message.timestamp) }}</div>
        </div>
      </div>
      <div 
        v-if="loading" 
        class="loading"
        @touchmove.stop
        @wheel.stop
      >
        <el-icon class="loading-icon" :size="20"><Loading /></el-icon>
        加载更多...
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, nextTick, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { Loading } from '@element-plus/icons-vue'

export default {
  name: 'ChatInterface',
  components: {
    Loading
  },
  setup() {
    const store = useStore()
    const messagesRef = ref(null)
    const pageSize = 10
    const currentPage = ref(1)
    const loading = ref(false)
    
    const messages = computed(() => store.state.messages || [])
    const chatMessages = computed(() => messages.value)

    const displayMessages = computed(() => {
      const start = Math.max(chatMessages.value.length - (currentPage.value * pageSize), 0)
      return chatMessages.value.slice(start)
    })

    // 处理滚动事件
    const handleScroll = async (e) => {
      e.preventDefault()
      e.stopPropagation()
      
      if (loading.value) return
      
      const { scrollTop } = e.target
      // 当滚动到顶部时加载更多
      if (scrollTop === 0 && displayMessages.value.length < chatMessages.value.length) {
        loading.value = true
        // 模拟加载延迟
        await new Promise(resolve => setTimeout(resolve, 500))
        currentPage.value++
        loading.value = false
      }
    }

    // 阻止默认滚动行为
    const preventScroll = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }

    // 添加和移除事件监听
    onMounted(() => {
      if (messagesRef.value) {
        messagesRef.value.addEventListener('touchmove', preventScroll, { passive: false })
        messagesRef.value.addEventListener('wheel', preventScroll, { passive: false })
        messagesRef.value.addEventListener('mousewheel', preventScroll, { passive: false })
        messagesRef.value.addEventListener('DOMMouseScroll', preventScroll, { passive: false })
        
        // 添加父容器的事件监听
        const chatInterface = messagesRef.value.closest('.chat-interface')
        if (chatInterface) {
          chatInterface.addEventListener('touchmove', preventScroll, { passive: false })
          chatInterface.addEventListener('wheel', preventScroll, { passive: false })
          chatInterface.addEventListener('mousewheel', preventScroll, { passive: false })
          chatInterface.addEventListener('DOMMouseScroll', preventScroll, { passive: false })
        }
      }
    })

    onUnmounted(() => {
      if (messagesRef.value) {
        messagesRef.value.removeEventListener('touchmove', preventScroll)
        messagesRef.value.removeEventListener('wheel', preventScroll)
        messagesRef.value.removeEventListener('mousewheel', preventScroll)
        messagesRef.value.removeEventListener('DOMMouseScroll', preventScroll)
        
        // 移除父容器的事件监听
        const chatInterface = messagesRef.value.closest('.chat-interface')
        if (chatInterface) {
          chatInterface.removeEventListener('touchmove', preventScroll)
          chatInterface.removeEventListener('wheel', preventScroll)
          chatInterface.removeEventListener('mousewheel', preventScroll)
          chatInterface.removeEventListener('DOMMouseScroll', preventScroll)
        }
      }
    })

    const formatTime = (timestamp) => {
      // 如果时间戳已经是格式化的字符串（HH:MM），直接返回
      if (typeof timestamp === 'string' && /^\d{2}:\d{2}$/.test(timestamp)) {
        return timestamp
      }
      // 否则格式化时间
      const now = new Date()
      return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`
    }

    // 新消息时自动滚动到底部
    watch(() => chatMessages.value.length, () => {
      nextTick(() => {
        if (messagesRef.value) {
          messagesRef.value.scrollTop = messagesRef.value.scrollHeight
        }
      })
    })

    return {
      displayMessages,
      messagesRef,
      loading,
      handleScroll,
      formatTime
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-interface {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--card-background);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  position: relative;
  touch-action: none;
  isolation: isolate;

  .messages {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background: transparent;
    position: relative;
    z-index: 1;
    overscroll-behavior: contain;
    touch-action: pan-y;
    transform: translateZ(0);
    will-change: transform;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--el-border-color-light);
      border-radius: 2px;
    }

    .messages-container {
      max-width: 800px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
      touch-action: pan-y;
      transform: translateZ(0);
      will-change: transform;
    }

    .message {
      max-width: 80%;
      padding: 12px 16px;
      border-radius: 16px;
      font-size: 15px;
      line-height: 1.5;
      word-break: break-word;
      animation: fadeIn 0.3s ease;

      .message-content {
        margin-bottom: 6px;
        color: inherit;
      }

      .message-time {
        font-size: 12px;
        color: var(--text-secondary);
        text-align: right;
      }

      @media (min-width: 769px) {
        max-width: 60%;
        background: var(--el-fill-color-light);
        border: 1px solid var(--el-border-color-lighter);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

        &.system {
          align-self: flex-start;
          border-top-left-radius: 4px;
          margin-right: auto;
          background: var(--el-color-primary-light-9);
          border-color: var(--el-color-primary-light-7);
          color: var(--text-primary);

          .message-time {
            color: var(--text-secondary);
          }
        }

        &.user {
          align-self: flex-end;
          border-top-right-radius: 4px;
          margin-left: auto;
          background: var(--el-color-success-light-9);
          border-color: var(--el-color-success-light-7);
          color: var(--text-primary);

          .message-time {
            color: var(--text-secondary);
          }
        }
      }

      @media (max-width: 768px) {
        background: transparent;
        
        &.system {
          align-self: flex-start;
          background: rgba(0, 0, 0, 0.6);
          color: #ffffff !important;
          border-top-left-radius: 4px;
          margin-left: 12px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

          .message-content {
            color: #ffffff !important;
          }

          .message-time {
            color: rgba(255, 255, 255, 0.7);
          }
        }

        &.user {
          align-self: flex-end;
          background: rgba(0, 114, 255, 0.6);
          color: #ffffff !important;
          border-top-right-radius: 4px;
          margin-right: 12px;
          box-shadow: 0 2px 8px rgba(0, 114, 255, 0.2);
          border-color: rgba(0, 114, 255, 0.3);

          .message-content {
            color: #ffffff !important;
          }

          .message-time {
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px;
    color: var(--text-secondary);
    font-size: 14px;

    .loading-icon {
      animation: rotate 1s linear infinite;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style> 