<template>
  <div class="model-manager">
    <div class="header">
      <div class="title">
        <h3>模型管理</h3>
        <el-radio-group v-model="currentType" size="small">
          <el-radio-button value="inference">推理模型</el-radio-button>
          <el-radio-button value="embedding">嵌入模型</el-radio-button>
        </el-radio-group>
      </div>
      <el-button type="primary" @click="showDialog = true">
        <el-icon><Plus /></el-icon>
        <span class="button-text">添加模型</span>
      </el-button>
    </div>

    <div class="model-list">
      <el-table :data="modelList" style="width: 100%">
        <el-table-column prop="name" label="模型名称" min-width="120">
          <template #default="{ row }">
            <div class="cell-content">
              <span class="mobile-label">模型名称</span>
              <span>{{ row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="provider" label="提供商" width="120">
          <template #default="{ row }">
            <div class="cell-content">
              <span class="mobile-label">提供商</span>
              <el-tag size="small">{{ row.provider }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="modelId" label="模型ID" min-width="150">
          <template #default="{ row }">
            <div class="cell-content">
              <span class="mobile-label">模型ID</span>
              <span>{{ row.modelId }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isEnabled" label="状态" width="100">
          <template #default="{ row }">
            <div class="cell-content">
              <span class="mobile-label">状态</span>
              <el-switch
                v-model="row.isEnabled"
                @change="handleStatusChange(row)"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="160">
          <template #default="{ row }">
            <div class="cell-content">
              <span class="mobile-label">创建时间</span>
              <span>{{ formatDate(row.createTime) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template #default="{ row }">
            <div class="action-buttons">
              <el-button 
                link 
                type="primary" 
                @click="handleEdit(row)"
              >
                <el-icon><Edit /></el-icon>
                <span class="button-text">编辑</span>
              </el-button>
              <el-button 
                link 
                type="danger" 
                @click="handleDelete(row)"
              >
                <el-icon><Delete /></el-icon>
                <span class="button-text">删除</span>
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 创建/编辑对话框 -->
    <el-dialog
      v-model="showDialog"
      :title="isEdit ? '编辑模型' : '添加模型'"
      :width="isMobile ? '90%' : '600px'"
      :top="isMobile ? '10vh' : '15vh'"
    >
      <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        label-position="top"
      >
        <el-form-item label="模型名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入模型名称" />
        </el-form-item>

        <el-form-item label="模型类型" prop="type">
          <el-select v-model="form.type" placeholder="请选择模型类型" style="width: 100%">
            <el-option label="推理模型" value="inference" />
            <el-option label="嵌入模型" value="embedding" />
          </el-select>
        </el-form-item>

        <el-form-item label="提供商" prop="provider">
          <el-select v-model="form.provider" placeholder="请选择提供商" style="width: 100%">
            <el-option label="OpenAI" value="openai" />
            <el-option label="Anthropic" value="anthropic" />
            <el-option label="百度文心" value="wenxin" />
            <el-option label="讯飞星火" value="spark" />
          </el-select>
        </el-form-item>

        <el-form-item label="模型ID" prop="modelId">
          <el-input v-model="form.modelId" placeholder="请输入模型ID" />
        </el-form-item>

        <el-form-item label="API地址" prop="baseUrl">
          <el-input v-model="form.baseUrl" placeholder="请输入API地址（可选）" />
        </el-form-item>

        <el-form-item label="API密钥" prop="apiKey">
          <el-input 
            v-model="form.apiKey" 
            type="password" 
            placeholder="请输入API密钥" 
            show-password
          />
        </el-form-item>

        <el-form-item label="温度" prop="temperature" v-if="form.type === 'inference'">
          <el-slider
            v-model="form.temperature"
            :min="0"
            :max="2"
            :step="0.1"
            show-input
          />
        </el-form-item>

        <el-form-item label="最大Token" prop="maxTokens" v-if="form.type === 'inference'">
          <el-input-number
            v-model="form.maxTokens"
            :min="1"
            :max="32768"
            style="width: 100%"
          />
        </el-form-item>
      </el-form>
      
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showDialog = false">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确认</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { Plus, Edit, Delete } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import emitter from '@/utils/eventBus'

export default {
  name: 'ModelManager',
  components: {
    Plus,
    Edit,
    Delete
  },
  setup() {
    const currentType = ref('inference')
    const modelList = ref([])
    const showDialog = ref(false)
    const isEdit = ref(false)
    const formRef = ref(null)
    
    const form = ref({
      name: '',
      type: 'inference',
      provider: '',
      modelId: '',
      baseUrl: '',
      apiKey: '',
      temperature: 0.7,
      maxTokens: 2048,
      isEnabled: true
    })
    
    const rules = {
      name: [
        { required: true, message: '请输入模型名称', trigger: 'blur' },
        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
      ],
      type: [
        { required: true, message: '请选择模型类型', trigger: 'change' }
      ],
      provider: [
        { required: true, message: '请选择提供商', trigger: 'change' }
      ],
      modelId: [
        { required: true, message: '请输入模型ID', trigger: 'blur' }
      ],
      apiKey: [
        { required: true, message: '请输入API密钥', trigger: 'blur' }
      ]
    }

    // 移动端检测
    const isMobile = computed(() => {
      return window.innerWidth <= 768
    })

    // 加载模型列表
    const loadModelList = async () => {
      try {
        const response = await axios.get('/api/llm-models/list', {
          params: {
            type: currentType.value
          }
        })
        if (response.data.code === 0) {
          modelList.value = response.data.data
        }
      } catch (error) {
        console.error('加载模型列表失败:', error)
        ElMessage.error('加载模型列表失败')
      }
    }

    // 监听类型变化
    watch(currentType, () => {
      loadModelList()
    })

    // 处理创建/编辑
    const handleSubmit = async () => {
      if (!formRef.value) return
      
      try {
        await formRef.value.validate()
        
        const url = isEdit.value 
          ? `/api/llm-models/${form.value.id}`
          : '/api/llm-models/create'
        
        const method = isEdit.value ? 'put' : 'post'
        const response = await axios[method](url, form.value)
        
        if (response.data.code === 0) {
          ElMessage.success(isEdit.value ? '更新成功' : '创建成功')
          showDialog.value = false
          loadModelList()
          // 触发模型列表更新事件
          emitter.emit('model-list-updated')
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        console.error('保存失败:', error)
        ElMessage.error('保存失败')
      }
    }

    // 处理编辑
    const handleEdit = (row) => {
      isEdit.value = true
      form.value = { ...row }
      showDialog.value = true
    }

    // 处理删除
    const handleDelete = async (row) => {
      try {
        await ElMessageBox.confirm('确定要删除这个模型吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        
        const response = await axios.delete(`/api/llm-models/${row.id}`)
        if (response.data.code === 0) {
          ElMessage.success('删除成功')
          loadModelList()
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除失败:', error)
          ElMessage.error('删除失败')
        }
      }
    }

    // 处理状态变更
    const handleStatusChange = async (row) => {
      try {
        const response = await axios.put(`/api/llm-models/${row.id}`, {
          isEnabled: row.isEnabled
        })
        
        if (response.data.code === 0) {
          ElMessage.success('状态更新成功')
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        console.error('状态更新失败:', error)
        ElMessage.error('状态更新失败')
        row.isEnabled = !row.isEnabled // 恢复状态
      }
    }

    // 格式化日期
    const formatDate = (date) => {
      if (!date) return ''
      return new Date(date).toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      })
    }

    onMounted(() => {
      loadModelList()
    })

    return {
      currentType,
      modelList,
      showDialog,
      isEdit,
      formRef,
      form,
      rules,
      isMobile,
      formatDate,
      handleSubmit,
      handleEdit,
      handleDelete,
      handleStatusChange
    }
  }
}
</script>

<style lang="scss" scoped>
.model-manager {
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 12px;

    .title {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;

      h3 {
        margin: 0;
        font-size: 16px;
        color: var(--el-text-color-primary);

        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .el-button {
      @media screen and (max-width: 768px) {
        padding: 8px 12px;
        .el-icon {
          margin-right: 0;
        }
        .button-text {
          display: none;
        }
      }
    }
  }

  .model-list {
    background: var(--el-bg-color);
    border-radius: 8px;
    padding: 20px;
    box-shadow: var(--el-box-shadow-light);

    @media screen and (max-width: 768px) {
      padding: 12px;
      margin: 0 -12px;
      border-radius: 0;
      box-shadow: none;
    }
  }

  :deep(.el-table) {
    @media screen and (max-width: 768px) {
      font-size: 12px;
      
      .el-table__header-wrapper {
        display: none;
      }

      .el-table__body-wrapper {
        .el-table__row {
          display: flex;
          flex-direction: column;
          padding: 12px;
          border-bottom: 1px solid var(--el-border-color-lighter);

          td {
            display: flex;
            align-items: center;
            padding: 4px 0;
            border: none;
            
            &::before {
              content: attr(data-label);
              font-weight: 500;
              margin-right: 8px;
              min-width: 80px;
              color: var(--el-text-color-secondary);
            }
          }

          .el-table__cell:last-child {
            margin-top: 8px;
            justify-content: flex-end;
            
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }

  .cell-content {
    display: flex;
    align-items: center;
    
    .mobile-label {
      display: none;
      font-weight: 500;
      margin-right: 8px;
      color: var(--el-text-color-secondary);
      
      @media screen and (max-width: 768px) {
        display: inline-block;
        min-width: 70px;
      }
    }
  }

  .action-buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      
      .el-button {
        padding: 4px 8px;
        
        .el-icon {
          margin-right: 4px;
        }
        
        .button-text {
          font-size: 12px;
        }
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    
    .el-button {
      width: 100%;
      margin: 0;
      height: 40px;
    }
  }
}
</style> 