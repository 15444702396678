<template>
  <div class="training-upload">
    <div class="upload-container" @click="triggerFileInput" @drop.prevent="handleDrop" @dragover.prevent>
      <input
        type="file"
        ref="fileInput"
        accept="video/*,image/*"
        style="display: none"
        @change="handleFileChange"
      />
      
      <div v-if="!selectedFile" class="upload-placeholder">
        <el-icon class="upload-icon"><Upload /></el-icon>
        <div class="upload-text">
          <span class="primary">点击上传</span>
          <span class="secondary">或将视频文件拖拽到此处</span>
        </div>
        <div class="upload-tips">
          支持 jpg、png的格式图片以及mp4、mov 格式视频，文件大小不超过 100MB
        </div>
      </div>
      
      <div v-else class="file-preview">
        <img 
          v-if="selectedFile.type.startsWith('image/')" 
          :src="videoUrl" 
          class="preview-image"
        >
        <video
          v-if="selectedFile.type.startsWith('video/')"
          :src="videoUrl"
          controls
          class="preview-video"
        ></video>
        <div class="file-info">
          <span class="file-name">{{ selectedFile.name }}</span>
          <span class="file-size">{{ formatFileSize(selectedFile.size) }}</span>
        </div>
        <el-button type="danger" size="small" @click.stop="removeFile">
          删除文件
        </el-button>
      </div>
    </div>

    <div class="upload-actions" v-if="selectedFile">
      <div class="upload-form">
        <el-input
          v-model="avatarName"
          placeholder="请输入数字人名称"
          class="avatar-name-input"
        />
        <el-checkbox
          v-model="needMatting"
          class="matting-checkbox"
        >
          是否清除背景
        </el-checkbox>
      </div>
      <el-button
        type="primary"
        :loading="uploading"
        :disabled="!canUpload"
        @click="handleUpload"
      >
        开始训练
      </el-button>
    </div>

    <el-dialog
      v-model="showProgress"
      title="训练进度"
      :close-on-click-modal="false"
      :show-close="false"
      width="90%"
      max-width="500px"
      @close="closeProgress"
    >
      <div class="progress-content">
        <div class="progress-status">
          <el-steps :active="currentStep" finish-status="success">
            <el-step title="准备上传" />
            <el-step title="文件预处理" />
            <el-step title="模型训练" />
            <el-step title="完成配置" />
          </el-steps>
        </div>
        
        <template v-if="currentStep < 4">
          <el-progress
            :percentage="uploadProgress"
            :status="uploadStatus"
            :stroke-width="10"
            :format="progressFormat"
          />
          <div class="progress-info">
            <div class="progress-text">{{ progressText }}</div>
            <div class="time-elapsed" v-if="timeElapsed">
              已用时间: {{ formatTime(timeElapsed) }}
            </div>
          </div>
        </template>

        <template v-else>
          <div class="config-form">
            <div class="preview-image" v-if="previewImage">
              <img :src="previewImage" alt="训练预览">
            </div>
            <h3>配置数字人信息</h3>
            <el-form
              ref="configFormRef"
              :model="configForm"
              :rules="configRules"
              label-position="top"
            >
              <el-form-item label="名称" prop="name">
                <el-input v-model="configForm.name" placeholder="请输入数字人名称" />
              </el-form-item>
              <el-form-item label="描述" prop="description">
                <el-input
                  v-model="configForm.description"
                  type="textarea"
                  :rows="3"
                  placeholder="请输入数字人描述"
                />
              </el-form-item>
              <el-form-item label="语音" prop="voice">
                <el-select v-model="configForm.voice" placeholder="请选择语音" style="width: 100%">
                  <el-option label="胡桃" value="hutao" />
                  <el-option label="语音2" value="zh_voice_2" />
                  <el-option label="语音3" value="zh_voice_3" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <template v-if="currentStep === 4">
            <el-button type="primary" @click="handleSubmitConfig">
              完成配置
            </el-button>
          </template>
          <template v-else-if="uploadStatus === 'exception'">
            <el-button @click="closeProgress">关闭</el-button>
            <el-button type="primary" @click="retryUpload">
              重试
            </el-button>
          </template>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      v-model="showResult"
      title="训练完成"
      width="90%"
      max-width="500px"
    >
      <div class="result-content">
        <div class="result-image">
          <img :src="resultImage" alt="训练结果">
        </div>
        <div class="result-info">
          <h3>{{ avatarName }}</h3>
          <p>训练已完成，可以开始使用了</p>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeResult">关闭</el-button>
          <el-button type="primary" @click="useNewAvatar">
            立即使用
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { Upload } from '@element-plus/icons-vue'
import socketClient from '@/utils/socket'
import { trainingAPI } from '@/api/training'

export default {
  name: 'TrainingUpload',
  components: {
    Upload
  },
  props: {
    defaultName: {
      type: String,
      default: ''
    }
  },
  emits: ['success', 'retry-training'],
  setup(props, { emit }) {
    const store = useStore()
    const fileInput = ref(null)
    const selectedFile = ref(null)
    const videoUrl = ref('')
    const avatarName = ref('')
    const needMatting = ref(true)
    const uploading = ref(false)
    const showProgress = ref(false)
    const uploadProgress = ref(0)
    const uploadStatus = ref('')
    const progressText = ref('')
    const currentTrainingId = ref(null)
    const currentStep = ref(0)
    const timeElapsed = ref(0)
    const previewImage = ref('')
    const showResult = ref(false)
    const resultImage = ref('')
    const configFormRef = ref(null)
    const configForm = ref({
      name: '',
      description: '',
      voice: 'hutao'
    })

    const configRules = {
      name: [
        { required: true, message: '请输入数字人名称', trigger: 'blur' },
        { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
      ],
      description: [
        { required: true, message: '请输入数字人描述', trigger: 'blur' },
        { min: 2, max: 200, message: '长度在 2 到 200 个字符', trigger: 'blur' }
      ],
      voice: [
        { required: true, message: '请选择语音', trigger: 'change' }
      ]
    }

    const isDev = computed(() => process.env.NODE_ENV === 'development')

    const canUpload = computed(() => {
      return selectedFile.value && 
             avatarName.value.trim() && 
             !uploading.value
    })

    const triggerFileInput = () => {
      fileInput.value?.click()
    }

    const handleFileChange = (event) => {
      const file = event.target.files[0]
      if (file) {
        validateAndSetFile(file)
      }
    }

    const handleDrop = (event) => {
      const file = event.dataTransfer.files[0]
      if (file) {
        validateAndSetFile(file)
      }
    }

    const validateAndSetFile = (file) => {
      // 验证文件类型
      const validTypes = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/png']
      if (!validTypes.includes(file.type)) {
        ElMessage.error('请上传 mp4 或 mov 格式的视频文件，或 jpg、png 格式的图片文件')
        return
      }

      // 验证文件大小（100MB）
      const maxSize = 100 * 1024 * 1024
      if (file.size > maxSize) {
        ElMessage.error('文件大小不能超过 100MB')
        return
      }

      selectedFile.value = file
      videoUrl.value = URL.createObjectURL(file)
    }

    const removeFile = () => {
      if (videoUrl.value) {
        URL.revokeObjectURL(videoUrl.value)
      }
      selectedFile.value = null
      videoUrl.value = ''
      avatarName.value = ''
      fileInput.value.value = ''
    }

    const formatFileSize = (bytes) => {
      if (bytes === 0) return '0 B'
      const k = 1024
      const sizes = ['B', 'KB', 'MB', 'GB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    }

    const progressFormat = (percentage) => {
      if (uploadStatus.value === 'exception') {
        return '失败'
      }
      return `${percentage}%`
    }

    const updateTrainingProgress = (status) => {
      if (!status) return
      
      console.log('更新训练进度:', status)
      
      switch (status.status) {
        case 0: // 初始状态
          currentStep.value = 0
          uploadProgress.value = 0
          progressText.value = status.msg || '准备开始上传...'
          uploadStatus.value = ''
          break
          
        case 1: // 上传成功，开始预处理
          currentStep.value = 1
          uploadProgress.value = 50 // 固定50%
          progressText.value = status.msg || '上传成功，开始预处理...'
          uploadStatus.value = ''
          break
          
        case 2: // 预处理成功，开始训练
          currentStep.value = 2
          uploadProgress.value = 75 // 固定75%
          progressText.value = status.msg || '预处理完成，开始模型训练...'
          uploadStatus.value = ''
          break
          
        case 3: // 训练完成
          currentStep.value = 3
          uploadProgress.value = 100 // 固定100%
          progressText.value = status.msg || '训练完成'
          uploadStatus.value = 'success'
          // 显示配置表单
          setTimeout(() => {
            currentStep.value = 4
            if (status.coverImg) {
              previewImage.value = status.coverImg
            }
          }, 1000)
          break
          
        case -1: // 训练失败
          uploadStatus.value = 'exception'
          progressText.value = status.msg || '训练失败'
          uploadProgress.value = 0
          break
      }
    }

    watch(() => store.state.training.currentTraining, (training) => {
      if (training) {
        showProgress.value = true
        currentTrainingId.value = training.digitalId
        updateTrainingProgress(training)
      }
    }, { deep: true })

    // 监听 defaultName 属性变化
    watch(() => props.defaultName, (newName) => {
      if (newName) {
        avatarName.value = newName
      }
    })

    // 检查名称是否重复
    const checkNameExists = async (name) => {
      try {
        const allAvatars = store.getters['training/allAvatars']
        const trainingList = store.state.training.trainingList
        
        // 检查已完成的数字人
        const existsInAvatars = allAvatars.some(avatar => avatar.name === name)
        // 检查训练中的数字人
        const existsInTraining = trainingList.some(training => training.avatarName === name)
        
        return existsInAvatars || existsInTraining
      } catch (error) {
        console.error('检查名称失败:', error)
        return false
      }
    }

    const handleUpload = async () => {
      if (!canUpload.value) return

      try {
        // 检查名称是否重复
        const nameExists = await checkNameExists(avatarName.value.trim())
        if (nameExists) {
          ElMessage.error('该名称已存在，请使用其他名称')
          return
        }

        uploading.value = true
        uploadProgress.value = 0
        uploadStatus.value = ''
        progressText.value = '准备开始上传...'
        showProgress.value = true
        currentStep.value = 0

        // 根据文件类型判断是图片还是视频
        const fileType = selectedFile.value.type.startsWith('image/') ? 'image' : 'video'

        const training = await store.dispatch('training/startTraining', {
          type: fileType,
          file: selectedFile.value,
          avatarName: avatarName.value.trim(),
          needMatting: needMatting.value,
          onProgress: (progress) => {
            if (progress === 100) {
              progressText.value = '上传完成，等待服务器处理...'
              uploadProgress.value = 25 // 上传完成固定25%
            } else {
              progressText.value = `上传进度: ${progress}%`
              // 上传过程中进度为0-25%之间
              uploadProgress.value = Math.floor(progress * 0.25)
            }
          }
        })

        if (training && training.digitalId) {
          ElMessage.success('上传成功，开始训练')
          // 设置选中的数字人
          store.commit('SET_SELECTED_AVATAR', training.digitalId)
          // 关闭上传对话框但保持进度显示
          removeFile()
          emit('success')
        }
      } catch (error) {
        console.error('上传失败:', error)
        ElMessage.error('上传失败：' + error.message)
        uploadStatus.value = 'exception'
        progressText.value = '上传失败'
      } finally {
        uploading.value = false
      }
    }

    const retryUpload = async () => {
      uploadStatus.value = ''
      progressText.value = '准备重新上传...'
      currentStep.value = 0
      await handleUpload()
    }

    const closeProgress = () => {
      // 只有在出错或配置完成后才允许关闭
      if (uploadStatus.value === 'exception' || currentStep.value !== 4) {
        showProgress.value = false
        removeFile()
      } else {
        ElMessage.warning('请先完成数字人配置')
      }
    }

    const closeResult = () => {
      showResult.value = false
      removeFile()
    }

    const useNewAvatar = () => {
      closeResult()
      emit('success')
    }

    const handleTrainingStatus = (status) => {
      store.dispatch('training/updateTrainingStatus', status)
    }

    const handleSubmitConfig = async () => {
      if (!configFormRef.value) return
      
      try {
        await configFormRef.value.validate()
        
        console.log('提交配置:', {
          id: currentTrainingId.value,
          data: configForm.value
        })
        
        // 使用新的配置方法
        const success = await store.dispatch('training/configureAvatar', {
          digitalId: currentTrainingId.value,
          config: {
            name: configForm.value.name,
            description: configForm.value.description,
            voice: configForm.value.voice
          }
        })

        if (success) {
          ElMessage.success('配置成功')
          showProgress.value = false
          
          // 清理状态
          currentTrainingId.value = null
          configForm.value = {
            name: '',
            description: '',
            voice: 'hutao'
          }
          removeFile()
          emit('success')
        } else {
          throw new Error('配置失败')
        }
      } catch (error) {
        console.error('配置失败:', error)
        ElMessage.error(error.message || '配置失败')
      }
    }

    return {
      fileInput,
      selectedFile,
      videoUrl,
      avatarName,
      needMatting,
      uploading,
      showProgress,
      uploadProgress,
      uploadStatus,
      progressText,
      canUpload,
      triggerFileInput,
      handleFileChange,
      handleDrop,
      removeFile,
      formatFileSize,
      handleUpload,
      currentStep,
      timeElapsed,
      previewImage,
      showResult,
      resultImage,
      closeResult,
      useNewAvatar,
      closeProgress,
      retryUpload,
      isDev,
      configFormRef,
      configForm,
      configRules,
      handleSubmitConfig,
      updateTrainingProgress,
      progressFormat
    }
  }
}
</script>

<style lang="scss" scoped>
.training-upload {
  padding: 20px;

  .upload-container {
    border: 2px dashed var(--el-border-color);
    border-radius: var(--border-radius-md);
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      border-color: var(--primary-color);
    }

    .upload-placeholder {
      .upload-icon {
        font-size: 48px;
        color: var(--text-secondary);
        margin-bottom: 16px;
      }

      .upload-text {
        margin-bottom: 8px;

        .primary {
          color: var(--primary-color);
          margin-right: 8px;
        }

        .secondary {
          color: var(--text-secondary);
        }
      }

      .upload-tips {
        font-size: 12px;
        color: var(--text-secondary);
      }
    }

    .file-preview {
      .preview-image {
        max-width: 100%;
        max-height: 300px;
        margin-bottom: 16px;
        border-radius: var(--border-radius-md);
      }
      .preview-video {
        max-width: 100%;
        max-height: 300px;
        margin-bottom: 16px;
        border-radius: var(--border-radius-md);
      }

      .file-info {
        margin-bottom: 16px;

        .file-name {
          font-weight: 500;
          margin-right: 8px;
        }

        .file-size {
          color: var(--text-secondary);
        }
      }
    }
  }

  .upload-actions {
    margin-top: 20px;
    display: flex;
    gap: 16px;
    align-items: flex-start;

    .upload-form {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .avatar-name-input {
        width: 100%;
      }

      .matting-checkbox {
        margin-left: 2px;
      }
    }

    .el-button {
      min-width: 120px;
      margin-top: 2px;
    }
  }

  .progress-content {
    padding: 20px;

    .progress-text {
      margin-top: 16px;
      text-align: center;
      color: var(--text-secondary);
    }
  }

  .config-form {
    margin-top: 20px;
    padding: 24px;
    background: var(--el-fill-color-light);
    border-radius: var(--border-radius-md);

    .preview-image {
      margin-bottom: 24px;
      text-align: center;
      
      img {
        max-width: 100%;
        max-height: 300px;
        border-radius: 8px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
        object-fit: contain;
        background-color: #fff;
        padding: 4px;
      }
    }

    h3 {
      margin-bottom: 24px;
      color: var(--text-primary);
      font-size: 16px;
      text-align: center;
      font-weight: 600;
    }

    :deep(.el-form) {
      .el-form-item {
        margin-bottom: 20px;
        
        .el-form-item__label {
          font-size: 14px;
          color: var(--text-primary);
          padding-bottom: 8px;
          font-weight: 500;
        }

        .el-input__wrapper,
        .el-textarea__inner {
          background-color: var(--el-fill-color-blank);
          border: 1px solid var(--el-border-color);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
          transition: all 0.3s ease;

          &:hover, &:focus {
            border-color: var(--primary-color);
            box-shadow: 0 2px 8px rgba(0, 114, 255, 0.1);
          }
        }

        .el-textarea__inner {
          padding: 8px 12px;
        }

        .el-select {
          width: 100%;
          
          .el-input__wrapper {
            background-color: var(--el-fill-color-blank);
          }
        }
      }
    }
  }
}

@include mobile {
  .training-upload {
    padding: 15px;

    .upload-container {
      padding: 15px;

      .upload-placeholder {
        .upload-icon {
          font-size: 36px;
        }

        .upload-text {
          font-size: 14px;
        }

        .upload-tips {
          font-size: 12px;
        }
      }

      .file-preview {
        .preview-image,
        .preview-video {
          max-height: 200px;
        }
      }
    }

    .upload-actions {
      flex-direction: column;
      gap: 12px;
      margin-top: 15px;

      .upload-form {
        width: 100%;
        gap: 8px;
      }

      .el-button {
        width: 100%;
        margin-top: 0;
      }
    }

    .config-form {
      padding: 16px;

      .preview-image img {
        max-width: 150px;
        max-height: 150px;
      }

      h3 {
        margin-bottom: 20px;
        font-size: 15px;
      }

      :deep(.el-form) {
        .el-form-item {
          margin-bottom: 16px;
        }
      }
    }
  }
}

.result-content {
  text-align: center;
  padding: 20px;

  .result-image {
    margin-bottom: 20px;
    
    img {
      max-width: 100%;
      max-height: 300px;
      border-radius: 8px;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
      object-fit: contain;
      background-color: #fff;
      padding: 4px;
    }
  }

  .result-info {
    h3 {
      margin-bottom: 10px;
      color: var(--text-primary);
    }

    p {
      color: var(--text-secondary);
    }
  }
}
</style> 