import { io } from 'socket.io-client'

class SocketClient {
  constructor() {
    this.socket = null
    this.connected = false
    this.callbacks = new Map()
    this.reconnectAttempts = 0
    this.maxReconnectAttempts = 5
    this.subscribedIds = new Set()
    this.autoReconnect = true
    this.disconnectCaller = null
  }

  connect() {
    if (this.socket?.connected) {
      console.log('Socket 已连接，跳过连接')
      return
    }

    console.log('正在连接 Socket.IO 服务器...')
    
    if (this.socket) {
      console.log('重用现有 Socket 连接')
      this.socket.connect()
      return
    }
    
    const socketURL = process.env.VUE_APP_SOCKET_URL || process.env.VUE_APP_CALLBACK_URL || 'http://localhost:3000'
    console.log('Socket 连接地址:', socketURL)
    
    this.socket = io(socketURL, {
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: this.maxReconnectAttempts,
      transports: ['websocket', 'polling'],
      forceNew: false
    })

    this.setupEventListeners()
  }

  setupEventListeners() {
    if (!this.socket) return

    this.socket.on('connect', () => {
      console.log('Socket 连接成功')
      this.connected = true
      this.reconnectAttempts = 0
      this.disconnectCaller = null
      
      if (this.subscribedIds.size > 0) {
        console.log('重新订阅训练状态:', Array.from(this.subscribedIds))
        this.subscribedIds.forEach(digitalId => {
          this.socket.emit('subscribe', digitalId)
        })
      }
    })

    this.socket.on('disconnect', (reason) => {
      console.log('Socket 断开连接，原因:', reason, '调用来源:', this.disconnectCaller)
      this.connected = false
      
      // 只有在明确需要清理时才执行清理
      if (reason === 'io client disconnect' && this.disconnectCaller === 'cleanup') {
        this.cleanup()
      } else {
        // 其他情况尝试重连
        console.log('准备重新连接...')
        setTimeout(() => {
          if (!this.socket?.connected) {
            this.connect()
          }
        }, 1000)
      }
    })

    this.socket.on('connect_error', (error) => {
      console.error('Socket 连接错误:', error)
      this.reconnectAttempts++
      
      if (this.reconnectAttempts >= this.maxReconnectAttempts) {
        console.error('Socket 重连次数超过限制，停止重连')
        this.cleanup()
      }
    })

    this.socket.on('training_status', (data) => {
      if (!data || !data.digitalId) return
      
      const callback = this.callbacks.get(data.digitalId)
      if (callback && this.subscribedIds.has(data.digitalId)) {
        console.log('处理训练状态更新:', data.digitalId)
        callback(data)
      }
    })
  }

  cleanup() {
    console.log('执行完整清理...')
    this.disconnectCaller = 'cleanup'
    
    if (this.socket) {
      this.socket.removeAllListeners()
      this.socket.disconnect()
      this.socket = null
    }
    this.connected = false
    this.callbacks.clear()
    this.subscribedIds.clear()
    this.reconnectAttempts = 0
  }

  subscribeToTraining(digitalId, callback) {
    if (!digitalId || this.subscribedIds.has(digitalId)) {
      console.log('跳过重复订阅:', digitalId)
      return
    }
    
    console.log('订阅训练状态:', digitalId)
    this.subscribedIds.add(digitalId)
    this.callbacks.set(digitalId, callback)
    
    if (!this.socket || !this.connected) {
      console.log('Socket 未连接，尝试连接...')
      this.connect()
    } else {
      this.socket.emit('subscribe', digitalId)
    }
  }

  unsubscribeFromTraining(digitalId) {
    if (!digitalId || !this.subscribedIds.has(digitalId)) {
      console.log('跳过未订阅的取消:', digitalId)
      return
    }
    
    console.log('取消订阅训练状态:', digitalId)
    this.subscribedIds.delete(digitalId)
    this.callbacks.delete(digitalId)
    
    if (this.socket?.connected) {
      this.socket.emit('unsubscribe', digitalId)
    }
  }

  disconnect(cleanup = false) {
    // 如果还有订阅的训练任务，不执行断开操作
    if (this.subscribedIds.size > 0 && !cleanup) {
      console.log('还有活跃的训练订阅，跳过断开连接:', Array.from(this.subscribedIds))
      return
    }

    this.disconnectCaller = new Error().stack
    console.log('断开 Socket 连接, cleanup:', cleanup, '调用来源:', this.disconnectCaller)
    
    if (cleanup) {
      this.cleanup()
    } else if (this.socket?.connected) {
      // 标记为临时断开，不清理状态
      this.socket.disconnect()
    }
  }

  // 检查是否有活跃的训练订阅
  hasActiveSubscriptions() {
    return this.subscribedIds.size > 0
  }

  // 获取当前订阅的训练ID列表
  getSubscribedIds() {
    return Array.from(this.subscribedIds)
  }
}

export default new SocketClient() 