import request from '@/utils/request'
import axios from 'axios'

const MAX_RETRIES = 3
const RETRY_DELAY = 2000

export const trainingAPI = {
  /**
   * 上传视频并开始训练
   * @param {File} file - 视频文件
   * @param {string} avatarName - 数字人名称
   * @param {boolean} needMatting - 是否需要抠图
   * @param {Function} onProgress - 上传进度回调
   * @param {Function} onError - 错误回调
   * @returns {Promise}
   */
  async uploadAndTrain(type, file, avatarName, needMatting, onProgress, onError) {
    let retryCount = 0
    let lastError = null

    while (retryCount < MAX_RETRIES) {
      try {
        console.log(`开始第 ${retryCount + 1} 次上传尝试，文件类型: ${type}`)
        
        const formData = new FormData()
        formData.append('type', type)
        formData.append('file', file)
        formData.append('avatarName', avatarName)
        formData.append('needMatting', needMatting ? 'true' : 'false')
        formData.append('callbackUrl', `${process.env.VUE_APP_CALLBACK_URL}/api/training/callback`)

        const config = {
          timeout: 300000, // 5分钟超时
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': '*/*'
          },
          onUploadProgress: (progressEvent) => {
            if (onProgress && progressEvent.total) {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              console.log(`上传进度: ${percentCompleted}%`)
              onProgress(percentCompleted)
            }
          }
        }

        const response = await request({
          url: '/prewav2lip',
          method: 'post',
          data: formData,
          ...config
        })

        console.log('上传成功，服务器响应:', response)
        return response
      } catch (error) {
        lastError = error
        console.error(`第 ${retryCount + 1} 次上传失败:`, error)
        
        if (onError) {
          onError(error, retryCount + 1)
        }

        retryCount++
        
        if (retryCount < MAX_RETRIES) {
          console.log(`等待 ${RETRY_DELAY/1000} 秒后重试...`)
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY))
        }
      }
    }

    throw lastError
  },

  /**
   * 获取自定义数字人列表
   * @returns {Promise}
   */
  getCustomAvatars() {
    return request({
      url: '/custom-avatars',
      method: 'get'
    })
  },

  /**
   * 获取训练列表
   * @returns {Promise}
   */
  getTrainingList() {
    return request({
      url: '/training/list',
      method: 'get'
    })
  },

  /**
   * 获取训练状态
   * @param {string} digitalId - 数字人ID
   * @returns {Promise}
   */
  async getTrainingStatus(digitalId) {
    return request.get(`/api/training/status/${digitalId}`)
  },

  /**
   * 获取数据库中的数字人列表
   * @returns {Promise}
   */
  getDbAvatars() {
    return request({
      url: '/avatars',
      method: 'get'
    })
  },

  // 配置数字人
  async configureAvatar(id, data) {
    return request({
      url: `/avatars/configure/${id}`,
      method: 'post',
      data
    })
  },

  // 删除数字人
  async deleteAvatar(digitalId) {
    return request.delete(`/avatars/${digitalId}`)
  }
} 