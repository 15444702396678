import { webrtcAPI } from '@/api'
import config from '@/config'

export default class WebRTCManager {
  constructor(config) {
    this.config = config
    this.pc = null
    this.dc = null
    this.stream = null
    this.mediaRecorder = null
    this.onMessage = null
    this.onConnectionStateChange = null
    this.onTrack = null
    this.isPreloading = false
    this.sessionId = null
  }

  async initialize() {
    this.pc = new RTCPeerConnection(this.config)
    
    // 设置数据通道
    this.dc = this.pc.createDataChannel('chat', {
      ordered: true
    })
    
    this.setupEventListeners()
    
    // 获取音频输入
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      })
      
      // 添加音频轨道到连接中
      this.stream.getTracks().forEach(track => {
        this.pc.addTrack(track, this.stream)
      })
    } catch (err) {
      console.error('无法获取音频输入:', err)
      throw new Error('无法获取音频输入: ' + err.message)
    }
  }

  setupEventListeners() {
    if (!this.pc || !this.dc) return

    // 处理远程媒体流
    this.pc.ontrack = (event) => {
      if (this.onTrack) {
        this.onTrack(event)
      }
    }

    // 处理连接状态变化
    this.pc.oniceconnectionstatechange = () => {
      console.log('ICE connection state:', this.pc.iceConnectionState)
      if (this.onConnectionStateChange) {
        this.onConnectionStateChange(this.pc.iceConnectionState)
      }
    }

    // 处理数据通道消息
    this.dc.onmessage = event => {
      if (this.onMessage) {
        this.onMessage(event.data)
      }
    }

    // 处理数据通道状态变化
    this.dc.onopen = () => {
      console.log('Data channel is open')
    }

    this.dc.onclose = () => {
      console.log('Data channel is closed')
    }

    this.dc.onerror = (error) => {
      console.error('Data channel error:', error)
    }
  }

  async negotiate(username, avatar) {
    if (!this.pc) {
      throw new Error('WebRTC 连接未初始化')
    }

    if (!avatar || !avatar.id) {
      throw new Error('无效的数字人参数')
    }

    try {
      // 添加音频和视频的接收器
      this.pc.addTransceiver('video', { direction: 'recvonly' })
      this.pc.addTransceiver('audio', { direction: 'recvonly' })

      const offer = await this.pc.createOffer()
      await this.pc.setLocalDescription(offer)

      // 记录发送的参数
      const params = {
        sdp: this.pc.localDescription.sdp,
        type: this.pc.localDescription.type,
        username,
        avatar: avatar.id,
        voice: avatar.voice || 'hutao', // 使用默认值
        system_prompt: avatar.systemPrompt || '' // 添加系统提示词
      }
      
      console.log('发送 offer 参数:', params)
      
      // 发送 offer 并获取响应
      const response = await webrtcAPI.sendOffer(params)
      console.log('服务器响应:', response)

      if (!response || !response.data) {
        throw new Error('服务器响应数据格式错误')
      }

      // 检查响应中是否包含必要的 SDP 信息
      const { sdp, type } = response.data
      if (!sdp || !type) {
        throw new Error('服务器响应缺少 SDP 信息')
      }

      // 保存会话 ID
      if (response.data.sessionid) {
        this.sessionId = response.data.sessionid
      }

      // 设置远程描述
      const remoteDesc = new RTCSessionDescription({
        sdp,
        type
      })
      
      console.log('设置远程描述:', remoteDesc)
      await this.pc.setRemoteDescription(remoteDesc)

      return response.data
    } catch (error) {
      console.error('协商失败:', error)
      console.error('错误详情:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      })
      throw error
    }
  }

  destroy() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop())
      this.stream = null
    }
    
    if (this.dc) {
      this.dc.close()
      this.dc = null
    }
    
    if (this.pc) {
      this.pc.close()
      this.pc = null
    }
  }

  async createPreloader(avatar) {
    if (!avatar || !avatar.id) {
      console.error('无效的数字人参数:', avatar)
      throw new Error('无效的数字人参数')
    }

    try {
      this.isPreloading = true
      console.log('开始预加载数字人:', avatar)
      
      const params = {
        avatar_id: avatar.id,
        voice: avatar.voice || 'hutao'
      }
      console.log('预加载请求参数:', params)
      
      const response = await webrtcAPI.createPreloader(params)
      console.log('预加载成功:', response)
      return response
    } catch (error) {
      console.error('预加载失败:', error)
      if (error.response) {
        console.error('服务器响应:', error.response.data)
        console.error('状态码:', error.response.status)
      } else if (error.request) {
        console.error('请求未收到响应')
        console.error('请求配置:', error.config)
      } else {
        console.error('请求配置错误:', error.message)
      }
      throw error
    } finally {
      this.isPreloading = false
    }
  }

  async deletePreloader(sessionId) {
    try {
      const response = await webrtcAPI.deletePreloader(sessionId)
      console.log('删除预加载成功:', response)
      return response
    } catch (error) {
      console.error('删除预加载失败:', error)
      throw error
    }
  }

  isPreloadingAvatar() {
    return this.isPreloading
  }
} 