<template>
  <div class="knowledge-manager">
    <!-- 左侧目录树 -->
    <div class="directory-tree">
      <div class="tree-header">
        <h3>知识库</h3>
        <el-button type="primary" @click="showUploadDialog = true">
          <el-icon><Plus /></el-icon>
          <span class="button-text">添加</span>
        </el-button>
      </div>
      <div class="tree-content">
        <el-table :data="directories" style="width: 100%">
          <el-table-column prop="name" label="名称" min-width="120">
            <template #default="{ row }">
              <div class="knowledge-name" @click="handleSelect(row)" :class="{ 'is-selected': selectedKnowledge?.id === row.id }">
                {{ row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="embeddingModel" label="嵌入模型" width="120">
            <template #default="{ row }">
              <el-tag size="small" type="info">
                {{ getModelName(row.embeddingModel) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template #default="{ row }">
              <el-button 
                type="danger" 
                link
                @click="handleDeleteKnowledge(row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 右侧文件列表 -->
    <div class="file-list">
      <div class="file-header">
        <div v-if="selectedKnowledge" class="file-content">
          <div class="file-title">
            <h3>{{ selectedKnowledge.name }}</h3>
            <p class="file-subtitle">嵌入模型: {{ getModelName(selectedKnowledge.embeddingModel) }}</p>
          </div>
          <div class="upload-section">
            <el-upload
              class="upload-button"
              :action="`/api/knowledge/${selectedKnowledge.id}/upload`"
              :before-upload="beforeUpload"
              :on-success="handleUploadSuccess"
              :on-error="handleUploadError"
              :show-file-list="false"
              :multiple="true"
              :data="{ embeddingModel: selectedKnowledge?.embeddingModel }"
              drag
            >
              <div class="upload-area">
                <el-icon><Upload /></el-icon>
                <div class="upload-text">
                  <p>拖拽文件到这里或点击上传</p>
                  <p class="upload-tip">支持 TXT, PDF, DOCX 等格式</p>
                </div>
              </div>
            </el-upload>
          </div>
          
          <!-- 文件列表 -->
          <div class="uploaded-files" v-if="fileList.length > 0">
            <el-table :data="fileList" style="width: 100%">
              <el-table-column prop="name" label="文件名" min-width="200">
                <template #default="{ row }">
                  <div class="file-name">
                    <el-icon><Document /></el-icon>
                    <span>{{ row.name }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="fileType" label="类型" width="120">
                <template #default="{ row }">
                  <el-tag :type="getFileTypeTag(row.fileType)" size="small">
                    {{ row.fileType?.toUpperCase() || '未知' }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="size" label="大小" width="120">
                <template #default="{ row }">
                  {{ formatFileSize(row.size) }}
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="上传时间" width="180">
                <template #default="{ row }">
                  {{ formatDate(row.createTime) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150" fixed="right">
                <template #default="{ row }">
                  <el-button 
                    type="primary" 
                    link
                    @click="handlePreview(row)"
                  >
                    预览
                  </el-button>
                  <el-button 
                    type="danger" 
                    link
                    @click="handleDelete(row)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- 网址列表 -->
          <div class="section-header">
            <h3>网址</h3>
            <el-button type="primary" @click="showAddUrlDialog = true">
              <el-icon><Plus /></el-icon>
              添加网址
            </el-button>
          </div>
          <div class="url-list" v-if="urlList.length > 0">
            <el-table :data="urlList" style="width: 100%">
              <el-table-column prop="name" label="名称" min-width="200">
                <template #default="{ row }">
                  <div class="url-name">
                    <el-icon><Link /></el-icon>
                    <span>{{ row.name }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="url" label="网址" min-width="300">
                <template #default="{ row }">
                  <a :href="row.url" target="_blank" class="url-link">{{ row.url }}</a>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="添加时间" width="180">
                <template #default="{ row }">
                  {{ formatDate(row.createTime) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150" fixed="right">
                <template #default="{ row }">
                  <el-button 
                    type="primary" 
                    link
                    @click="handleEditUrl(row)"
                  >
                    编辑
                  </el-button>
                  <el-button 
                    type="danger" 
                    link
                    @click="handleDeleteUrl(row)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else class="no-urls">
            <el-empty description="暂无网址" />
          </div>
        </div>
        <div v-else class="empty-state">
          <el-empty description="请选择一个知识库" />
        </div>
      </div>
    </div>

    <!-- 添加/编辑目录对话框 -->
    <el-dialog
      v-model="showAddDirectoryDialog"
      :title="isEditDirectory ? '编辑知识库' : '添加知识库'"
      width="500px"
    >
      <el-form
        ref="directoryFormRef"
        :model="directoryForm"
        :rules="directoryRules"
        label-width="80px"
      >
        <el-form-item label="知识库名称" prop="name">
          <el-input v-model="directoryForm.name" placeholder="请输入知识库名称" />
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input
            v-model="directoryForm.description"
            type="textarea"
            :rows="3"
            placeholder="请输入知识库描述"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showAddDirectoryDialog = false">取消</el-button>
          <el-button type="primary" @click="handleDirectorySubmit">确认</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 预览对话框 -->
    <el-dialog
      v-model="showPreviewDialog"
      title="文件预览"
      width="800px"
      class="preview-dialog"
    >
      <div class="preview-content" v-loading="previewLoading">
        <pre v-if="previewContent">{{ previewContent }}</pre>
        <div v-else class="no-preview">
          <el-empty description="暂不支持预览此类型文件" />
        </div>
      </div>
    </el-dialog>

    <!-- 添加网址对话框 -->
    <el-dialog
      v-model="showAddUrlDialog"
      title="添加网址"
      width="500px"
    >
      <el-form
        ref="urlFormRef"
        :model="urlForm"
        :rules="urlRules"
        label-width="80px"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="urlForm.name" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="网址" prop="url">
          <el-input v-model="urlForm.url" placeholder="请输入网址" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showAddUrlDialog = false">取消</el-button>
          <el-button type="primary" @click="handleUrlSubmit">确认</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 上传对话框 -->
    <el-dialog
      v-model="showUploadDialog"
      title="新建知识库"
      width="500px"
    >
      <el-form
        ref="uploadFormRef"
        :model="uploadForm"
        :rules="uploadRules"
        label-position="top"
      >
        <el-form-item label="知识库名称" prop="name">
          <el-input v-model="uploadForm.name" placeholder="请输入知识库名称" />
        </el-form-item>
        
        <el-form-item label="嵌入模型" prop="embeddingModel">
          <el-select v-model="uploadForm.embeddingModel" placeholder="请选择嵌入模型" style="width: 100%">
            <el-option
              v-for="model in embeddingModels"
              :key="model.id"
              :label="model.name"
              :value="model.modelId"
            />
          </el-select>
        </el-form-item>
      </el-form>
      
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showUploadDialog = false">取消</el-button>
          <el-button type="primary" @click="handleUpload">确认</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { Plus, Edit, Delete, Upload, Document, Link } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import emitter from '@/utils/eventBus'

export default {
  name: 'KnowledgeManager',
  components: {
    Plus,
    Edit,
    Delete,
    Upload,
    Document,
    Link
  },
  setup() {
    // 添加缺失的 ref
    const treeRef = ref(null)
    const uploadRef = ref(null)
    const uploadFormRef = ref(null)

    // 目录树相关
    const directories = ref([])
    const selectedKnowledge = ref(null)

    // 文件列表相关
    const fileList = ref([])
    const uploadUrl = '/api/knowledge/upload'

    // 对话框控制
    const showAddDirectoryDialog = ref(false)
    const showPreviewDialog = ref(false)
    const isEditDirectory = ref(false)
    const editingDirectory = ref(null)
    const previewLoading = ref(false)
    const previewContent = ref('')

    // 表单相关
    const directoryFormRef = ref(null)
    const directoryForm = ref({
      name: '',
      description: ''
    })
    const directoryRules = {
      name: [
        { required: true, message: '请输入知识库名称', trigger: 'blur' },
        { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
      ],
      description: [
        { max: 200, message: '不能超过 200 个字符', trigger: 'blur' }
      ]
    }

    // 网址列表相关
    const urlList = ref([])
    const showAddUrlDialog = ref(false)
    const urlFormRef = ref(null)
    const urlForm = ref({
      name: '',
      url: ''
    })
    const urlRules = {
      name: [
        { required: true, message: '请输入名称', trigger: 'blur' },
        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
      ],
      url: [
        { required: true, message: '请输入网址', trigger: 'blur' },
        { type: 'url', message: '请输入有效的网址', trigger: 'blur' }
      ]
    }

    // 上传相关
    const showUploadDialog = ref(false)
    const uploadForm = ref({
      name: '',
      embeddingModel: ''
    })
    const uploadRules = {
      name: [
        { required: true, message: '请输入知识库名称', trigger: 'blur' },
        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
      ],
      embeddingModel: [
        { required: true, message: '请选择嵌入模型', trigger: 'change' }
      ]
    }

    const embeddingModels = ref([])

    // 加载文件列表
    const loadFileList = async () => {
      if (!selectedKnowledge.value?.id) return
      
      try {
        const response = await axios.get('/api/knowledge/list', {
          params: { 
            directory: selectedKnowledge.value.id,
            type: 'file'  // 指定只获取文件类型
          }
        })
        if (response.data.code === 0) {
          fileList.value = response.data.data || []
        } else {
          fileList.value = []
        }
      } catch (error) {
        console.error('加载文件列表失败:', error)
        ElMessage.error('加载文件列表失败')
        fileList.value = []
      }
    }

    // 加载知识库目录列表
    const loadDirectories = async () => {
      try {
        const response = await axios.get('/api/knowledge/directories')
        if (response.data.code === 0) {
          directories.value = response.data.data
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        console.error('加载知识库目录列表失败:', error)
        ElMessage.error('加载知识库目录列表失败')
      }
    }

    // 处理知识库选择
    const handleSelect = (knowledge) => {
      selectedKnowledge.value = knowledge
      loadFileList()
      loadUrlList()
    }

    // 获取模型名称
    const getModelName = (modelId) => {
      const model = embeddingModels.value.find(m => m.modelId === modelId)
      return model ? model.name : modelId
    }

    // 处理知识库删除
    const handleDeleteKnowledge = async (knowledge) => {
      try {
        await ElMessageBox.confirm('确定要删除这个知识库吗？', '提示', {
          type: 'warning'
        })
        
        const response = await axios.delete(`/api/knowledge/${knowledge.id}`)
        if (response.data.code === 0) {
          ElMessage.success('删除成功')
          if (selectedKnowledge.value?.id === knowledge.id) {
            selectedKnowledge.value = null
          }
          loadDirectories()
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除失败:', error)
          ElMessage.error('删除失败')
        }
      }
    }

    // 文件上传前验证
    const beforeUpload = (file) => {
      const allowedTypes = [
        'text/plain',
        'text/markdown',
        'text/html',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/epub+zip'
      ]

      if (!allowedTypes.includes(file.type)) {
        ElMessage.error('不支持的文件类型')
        return false
      }

      const maxSize = 10 * 1024 * 1024 // 10MB
      if (file.size > maxSize) {
        ElMessage.error('文件大小不能超过 10MB')
        return false
      }

      return true
    }

    // 上传成功处理
    const handleUploadSuccess = (response) => {
      if (response.code === 0) {
        ElMessage.success('上传成功')
        loadFileList()
      } else {
        ElMessage.error(response.message || '上传失败')
      }
    }

    // 上传失败处理
    const handleUploadError = (error) => {
      console.error('上传失败:', error)
      ElMessage.error(error.response?.data?.message || '上传失败')
    }

    // 处理文件预览
    const handlePreview = async (file) => {
      showPreviewDialog.value = true
      previewLoading.value = true
      previewContent.value = ''

      try {
        const response = await axios.get(`/api/knowledge/preview/${file.id}`)
        if (response.data.code === 0) {
          previewContent.value = response.data.data
        }
      } catch (error) {
        console.error('预览失败:', error)
        ElMessage.error('预览失败')
      } finally {
        previewLoading.value = false
      }
    }

    // 处理文件删除
    const handleDelete = async (file) => {
      try {
        await ElMessageBox.confirm('确定要删除这个文件吗？', '提示', {
          type: 'warning'
        })
        
        const response = await axios.delete(`/api/knowledge/${file.id}`)
        if (response.data.code === 0) {
          ElMessage.success('删除成功')
          loadFileList()
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除失败:', error)
          ElMessage.error('删除失败')
        }
      }
    }

    // 处理目录提交
    const handleDirectorySubmit = async () => {
      if (!directoryFormRef.value) return
      
      try {
        await directoryFormRef.value.validate()
        // TODO: 实现目录创建/编辑逻辑
        
        showAddDirectoryDialog.value = false
        directoryForm.value = { name: '', description: '' }
        isEditDirectory.value = false
        editingDirectory.value = null
      } catch (error) {
        // 表单验证失败
      }
    }

    // 处理网址提交
    const handleUrlSubmit = async () => {
      if (!urlFormRef.value) return
      
      try {
        await urlFormRef.value.validate()
        
        const response = await axios.post(`/api/knowledge/${selectedKnowledge.value.id}/url`, {
          name: urlForm.value.name,
          url: urlForm.value.url
        })
        
        if (response.data.code === 0) {
          ElMessage.success('添加成功')
          showAddUrlDialog.value = false
          urlForm.value = { name: '', url: '' }
          loadUrlList()
        }
      } catch (error) {
        console.error('添加失败:', error)
        ElMessage.error(error.response?.data?.message || '添加失败')
      }
    }

    // 处理网址编辑
    const handleEditUrl = (url) => {
      urlForm.value = {
        name: url.name,
        url: url.url
      }
      showAddUrlDialog.value = true
    }

    // 处理网址删除
    const handleDeleteUrl = async (url) => {
      try {
        await ElMessageBox.confirm('确定要删除这个网址吗？', '提示', {
          type: 'warning'
        })
        
        const response = await axios.delete(`/api/knowledge/${url.id}`)
        if (response.data.code === 0) {
          ElMessage.success('删除成功')
          loadUrlList()
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除失败:', error)
          ElMessage.error('删除失败')
        }
      }
    }

    // 格式化文件大小
    const formatFileSize = (size) => {
      if (size < 1024) {
        return size + ' B'
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      } else {
        return (size / 1024 / 1024).toFixed(2) + ' MB'
      }
    }

    // 格式化日期
    const formatDate = (date) => {
      if (!date) return '-'
      const d = new Date(date)
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')} ${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`
    }

    // 获取文件类型标签样式
    const getFileTypeTag = (fileType) => {
      const typeMap = {
        'pdf': 'danger',
        'doc': 'primary',
        'docx': 'primary',
        'txt': 'info',
        'unknown': 'info'
      }
      return typeMap[fileType?.toLowerCase()] || 'info'
    }

    // 加载嵌入模型列表
    const loadEmbeddingModels = async () => {
      try {
        const response = await axios.get('/api/llm-models/list', {
          params: { type: 'embedding' }
        })
        if (response.data.code === 0) {
          embeddingModels.value = response.data.data
        }
      } catch (error) {
        console.error('加载嵌入模型列表失败:', error)
        ElMessage.error('加载嵌入模型列表失败')
      }
    }

    // 处理上传
    const handleUpload = async () => {
      if (!uploadFormRef.value) return
      
      try {
        await uploadFormRef.value.validate()
        
        const response = await axios.post('/api/knowledge/create', {
          name: uploadForm.value.name,
          embeddingModel: uploadForm.value.embeddingModel
        })
        
        if (response.data.code === 0) {
          ElMessage.success('创建成功')
          showUploadDialog.value = false
          uploadForm.value = {
            name: '',
            embeddingModel: ''
          }
          // 重新加载目录列表
          await loadDirectories()
          // 选中新创建的知识库
          selectedKnowledge.value = response.data.data
          // 加载新知识库的文件列表
          await loadFileList()
          await loadUrlList()
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        console.error('创建失败:', error)
        ElMessage.error(error.message || '创建失败')
      }
    }

    // 加载网址列表
    const loadUrlList = async () => {
      if (!selectedKnowledge.value?.id) return
      
      try {
        const response = await axios.get('/api/knowledge/list', {
          params: { 
            directory: selectedKnowledge.value.id,
            type: 'url'  // 指定只获取网址类型
          }
        })
        if (response.data.code === 0) {
          urlList.value = response.data.data || []
        } else {
          urlList.value = []
        }
      } catch (error) {
        console.error('加载网址列表失败:', error)
        ElMessage.error('加载网址列表失败')
        urlList.value = []
      }
    }

    onMounted(() => {
      loadDirectories()
      loadEmbeddingModels()
      emitter.on('model-list-updated', loadEmbeddingModels)
    })

    onUnmounted(() => {
      emitter.off('model-list-updated', loadEmbeddingModels)
    })

    return {
      treeRef,
      uploadRef,
      uploadFormRef,
      directories,
      selectedKnowledge,
      fileList,
      uploadUrl,
      showAddDirectoryDialog,
      showPreviewDialog,
      isEditDirectory,
      previewLoading,
      previewContent,
      directoryFormRef,
      directoryForm,
      directoryRules,
      handleSelect,
      getModelName,
      handleDeleteKnowledge,
      beforeUpload,
      handleUploadSuccess,
      handleUploadError,
      handlePreview,
      handleDelete,
      handleDirectorySubmit,
      formatFileSize,
      formatDate,
      getFileTypeTag,
      showAddUrlDialog,
      urlFormRef,
      urlForm,
      urlRules,
      handleUrlSubmit,
      showUploadDialog,
      uploadForm,
      uploadRules,
      embeddingModels,
      handleUpload,
      urlList,
      handleEditUrl,
      handleDeleteUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.knowledge-manager {
  display: flex;
  height: 100%;
  background: var(--el-bg-color);
  border-radius: 8px;
  overflow: hidden;

  .directory-tree {
    width: 260px;
    border-right: 1px solid var(--el-border-color-light);
    display: flex;
    flex-direction: column;

    .tree-header {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--el-border-color-light);

      h3 {
        margin: 0;
        font-size: 16px;
        color: var(--el-text-color-primary);
      }

      @media screen and (max-width: 768px) {
        .button-text {
          display: none;
        }
      }
    }

    .tree-content {
      flex: 1;
      overflow: auto;
      padding: 16px;

      .tree-node {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .node-actions {
          display: none;
        }

        &:hover .node-actions {
          display: flex;
          gap: 4px;
        }
      }
    }
  }

  .file-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .file-header {
      padding: 20px;
      border-bottom: 1px solid var(--el-border-color-light);

      h3 {
        margin: 0 0 16px;
        font-size: 16px;
        color: var(--el-text-color-primary);
      }

      .header-actions {
        .uploaded-files {
          margin-top: 20px;
          border: 1px solid var(--el-border-color-light);
          border-radius: 8px;
          overflow: hidden;

          .el-table {
            --el-table-border-color: var(--el-border-color-light);
          }
        }
      }
    }

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px;
      border-bottom: 1px solid var(--el-border-color-light);

      h3 {
        margin: 0;
        font-size: 16px;
        color: var(--el-text-color-primary);
      }
    }
  }

  .knowledge-name {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--el-fill-color-light);
    }

    &.is-selected {
      background-color: var(--el-color-primary-light-9);
      color: var(--el-color-primary);
    }
  }

  .file-content {
    .file-title {
      margin-bottom: 20px;

      h3 {
        margin: 0;
        font-size: 18px;
        color: var(--el-text-color-primary);
      }

      .file-subtitle {
        margin: 8px 0 0;
        font-size: 14px;
        color: var(--el-text-color-secondary);
      }
    }

    .upload-section {
      margin-bottom: 20px;

      .upload-area {
        padding: 40px 0;
        text-align: center;
        
        .el-icon {
          font-size: 48px;
          color: var(--el-text-color-secondary);
          margin-bottom: 16px;
        }

        .upload-text {
          p {
            margin: 0;
            font-size: 14px;
            color: var(--el-text-color-regular);

            &.upload-tip {
              margin-top: 8px;
              font-size: 12px;
              color: var(--el-text-color-secondary);
            }
          }
        }
      }
    }

    .uploaded-files {
      border: 1px solid var(--el-border-color-light);
      border-radius: 8px;
      overflow: hidden;

      .file-name {
        display: flex;
        align-items: center;
        gap: 8px;

        .el-icon {
          font-size: 20px;
          color: var(--el-text-color-secondary);
        }
      }
    }
  }

  .empty-state {
    padding: 60px 0;
    text-align: center;
  }
}

.preview-dialog {
  .preview-content {
    max-height: 60vh;
    overflow: auto;
    padding: 16px;
    background: var(--el-bg-color-page);
    border-radius: 4px;

    pre {
      margin: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
      font-family: monospace;
    }

    .no-preview {
      padding: 40px 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .knowledge-manager {
    flex-direction: column;

    .directory-tree {
      width: 100%;
      height: 300px;
      border-right: none;
      border-bottom: 1px solid var(--el-border-color-light);
    }
  }
}

.url-list {
  margin-top: 16px;
  border: 1px solid var(--el-border-color-light);
  border-radius: 8px;
  overflow: hidden;

  .url-name {
    display: flex;
    align-items: center;
    gap: 8px;

    .el-icon {
      font-size: 20px;
      color: var(--el-text-color-secondary);
    }
  }

  .url-link {
    color: var(--el-color-primary);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.no-urls {
  margin-top: 16px;
  padding: 40px 0;
  text-align: center;
}

.section-header {
  margin-top: 24px;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
    font-size: 16px;
    color: var(--el-text-color-primary);
  }
}
</style> 