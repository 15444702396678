<template>
  <div class="voice-selector">
    <el-select 
      v-model="selectedVoice"
      :loading="loading"
      placeholder="请选择语音" 
      style="width: 100%"
    >
      <el-option
        v-for="voice in voiceList"
        :key="voice.id"
        :label="voice.name"
        :value="voice.id"
      />
    </el-select>
    <div v-if="error" class="error-message">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted } from 'vue'
import { avatarAPI } from '@/api'
import emitter from '@/utils/eventBus'

export default {
  name: 'VoiceSelector',
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const voiceList = ref([])
    const loading = ref(false)
    const error = ref('')
    const selectedVoice = ref(props.modelValue)

    // 监听 modelValue 的变化
    watch(() => props.modelValue, (newValue) => {
      selectedVoice.value = newValue
    })

    // 监听 selectedVoice 的变化
    watch(() => selectedVoice.value, (newValue) => {
      emit('update:modelValue', newValue)
    })

    // 加载语音列表
    const loadVoiceList = async () => {
      loading.value = true
      error.value = ''
      
      try {
        const response = await avatarAPI.getVoiceList()
        console.log('语音列表响应:', response)
        
        if (response.data && response.data.code === 0 && Array.isArray(response.data.data)) {
          voiceList.value = response.data.data
          console.log('设置语音列表:', voiceList.value)
          
          if (voiceList.value.length === 0) {
            error.value = '暂无可用的语音'
          }
        } else {
          error.value = '获取语音列表失败'
          console.error('获取语音列表失败:', response)
        }
      } catch (err) {
        error.value = '获取语音列表失败'
        console.error('获取语音列表错误:', err)
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      loadVoiceList()
      emitter.on('voice-list-updated', loadVoiceList)
    })

    onUnmounted(() => {
      emitter.off('voice-list-updated', loadVoiceList)
    })

    return {
      voiceList,
      loading,
      error,
      selectedVoice
    }
  }
}
</script>

<style lang="scss" scoped>
.voice-selector {
  width: 100%;
  
  .error-message {
    color: var(--el-color-danger);
    font-size: 12px;
    margin-top: 4px;
  }
}

:deep(.el-select-dropdown__item) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;

  .voice-name {
    color: var(--el-text-color-primary);
  }

  .voice-description {
    color: var(--el-text-color-secondary);
    font-size: 12px;
  }
}
</style> 