<template>
  <div class="test-page">
    <h1>系统测试页面</h1>
    <div class="test-container">
      <el-card>
        <template #header>
          <div class="card-header">
            <span>基础功能测试</span>
          </div>
        </template>
        <div class="test-items">
          <div class="test-item">
            <span class="label">Element Plus:</span>
            <el-button type="primary" @click="testElementPlus">
              测试Element Plus
            </el-button>
          </div>

          <div class="test-item">
            <span class="label">Vuex状态:</span>
            <el-button type="success" @click="testVuex">
              测试Vuex
            </el-button>
          </div>

          <div class="test-item">
            <span class="label">路由跳转:</span>
            <el-button type="warning" @click="testRouter">
              测试路由
            </el-button>
          </div>

          <div class="test-item">
            <span class="label">API请求:</span>
            <el-button type="info" @click="testAPI">
              测试API
            </el-button>
          </div>

          <div class="test-item">
            <span class="label">WebRTC:</span>
            <el-button type="danger" @click="testWebRTC">
              测试WebRTC
            </el-button>
          </div>
        </div>
      </el-card>

      <el-card class="mt-4">
        <template #header>
          <div class="card-header">
            <span>测试结果</span>
            <el-button type="primary" link @click="clearResults">
              清除结果
            </el-button>
          </div>
        </template>
        <div class="test-results">
          <div v-for="(result, index) in testResults" :key="index" class="result-item">
            <el-tag :type="result.success ? 'success' : 'danger'" size="small">
              {{ result.success ? '成功' : '失败' }}
            </el-tag>
            <span class="result-message">{{ result.message }}</span>
            <span class="result-time">{{ result.time }}</span>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { webrtcAPI, chatAPI } from '@/api'
import { WebRTCManager } from '@/utils/webrtc'

export default {
  name: 'TestPage',
  setup() {
    const store = useStore()
    const router = useRouter()
    const testResults = ref([])

    const addResult = (success, message) => {
      testResults.value.unshift({
        success,
        message,
        time: new Date().toLocaleTimeString()
      })
    }

    const clearResults = () => {
      testResults.value = []
    }

    const testElementPlus = () => {
      try {
        ElMessage.success('Element Plus 组件测试成功')
        addResult(true, 'Element Plus 组件工作正常')
      } catch (error) {
        addResult(false, 'Element Plus 测试失败: ' + error.message)
      }
    }

    const testVuex = () => {
      try {
        store.commit('SET_USER', { username: 'test', avatar: 'T' })
        const user = store.getters.currentUser
        if (user && user.username === 'test') {
          addResult(true, 'Vuex 状态管理测试成功')
        } else {
          throw new Error('状态更新失败')
        }
      } catch (error) {
        addResult(false, 'Vuex 测试失败: ' + error.message)
      }
    }

    const testRouter = () => {
      try {
        router.push('/test')
        addResult(true, '路由功能测试成功')
      } catch (error) {
        addResult(false, '路由测试失败: ' + error.message)
      }
    }

    const testAPI = async () => {
      try {
        await chatAPI.sendMessage({
          user: 'test',
          query: 'test message',
          sound: '小Y'
        })
        addResult(true, 'API 请求测试成功')
      } catch (error) {
        addResult(false, 'API 测试失败: ' + error.message)
      }
    }

    const testWebRTC = async () => {
      try {
        const webrtc = new WebRTCManager()
        webrtc.initialize()
        if (webrtc.pc && webrtc.dc) {
          addResult(true, 'WebRTC 初始化测试成功')
        } else {
          throw new Error('WebRTC 初始化失败')
        }
        webrtc.destroy()
      } catch (error) {
        addResult(false, 'WebRTC 测试失败: ' + error.message)
      }
    }

    return {
      testResults,
      clearResults,
      testElementPlus,
      testVuex,
      testRouter,
      testAPI,
      testWebRTC
    }
  }
}
</script>

<style lang="scss" scoped>
.test-page {
  padding: 20px;

  h1 {
    text-align: center;
    margin-bottom: 30px;
    color: var(--text-primary);
  }

  .test-container {
    max-width: 800px;
    margin: 0 auto;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .test-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .test-item {
    display: flex;
    align-items: center;
    gap: 15px;

    .label {
      min-width: 100px;
      color: var(--text-secondary);
    }
  }

  .test-results {
    max-height: 300px;
    overflow-y: auto;

    .result-item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px 0;
      border-bottom: 1px solid #eee;

      &:last-child {
        border-bottom: none;
      }

      .result-message {
        flex: 1;
        color: var(--text-primary);
      }

      .result-time {
        color: var(--text-secondary);
        font-size: 12px;
      }
    }
  }
}

.mt-4 {
  margin-top: 16px;
}
</style> 