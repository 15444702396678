<template>
  <div class="video-container">
    <div id="imageContainer" class="image-container">
      <template v-if="isVideoBackground">
        <video 
          ref="bgVideoRef"
          class="background-video"
          autoplay
          loop
          muted
          playsinline
          :src="currentBackground"
        ></video>
      </template>
      <img 
        v-else
        ref="displayedImage" 
        :src="currentBackground" 
        alt="背景图片"
        @error="handleImageError"
      >
    </div>
    <div id="media" class="media-container">
      <video ref="videoRef" autoplay playsinline></video>
    </div>
    <audio 
      ref="audioRef" 
      autoplay 
      playsinline
      @error="handleAudioError"
      @playing="handleAudioPlaying"
    ></audio>
    
    <!-- 添加背景切换控制面板 -->
    <div class="background-controls" v-if="showBackgroundControls">
      <div class="background-header">
        <h4>预设背景</h4>
        <el-button 
          type="primary" 
          size="small" 
          @click="showMaterialManager = true"
        >
          <i class="el-icon-plus"></i>
          素材管理
        </el-button>
      </div>
      <div class="background-options">
        <div 
          v-for="(bg, index) in backgroundOptions" 
          :key="index"
          class="background-option"
          :class="{ active: currentBackground === bg.url, video: bg.type === 'video' }"
          @click="selectBackground(bg)"
        >
          <template v-if="bg.type === 'video'">
            <video 
              class="preview-video"
              :src="bg.url"
              autoplay
              loop
              muted
              playsinline
            ></video>
          </template>
          <template v-else>
            <img :src="bg.url" :alt="bg.name">
          </template>
          <span>{{ bg.name }}</span>
        </div>
      </div>
    </div>

    <div class="controls-toggle" @click="toggleBackgroundControls">
      <i :class="showBackgroundControls ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
      背景设置
    </div>
    
    <el-dialog
      v-model="showMaterialManager"
      title="素材管理"
      width="80%"
      :before-close="handleMaterialManagerClose"
    >
      <MaterialManager @select-background="handleBackgroundSelect" />
    </el-dialog>
    
    <slot></slot>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import MaterialManager from './MaterialManager.vue'

export default {
  name: 'WebRTCVideo',
  components: {
    MaterialManager
  },
  setup() {
    const store = useStore()
    const videoRef = ref(null)
    const audioRef = ref(null)
    const displayedImage = ref(null)
    const isConnected = ref(false)
    const showBackgroundControls = ref(false)
    const defaultImage = ref('/assets/images/image.png')
    const currentBackground = ref(defaultImage.value)
    const recentMessages = ref([])
    let videoTrack = null
    let messageId = 0
    let currentMessage = ref('')
    let messageTimer = null
    const bgVideoRef = ref(null)
    const isVideoBackground = ref(false)
    const showMaterialManager = ref(false)
    
    // 背景选项
    const backgroundOptions = ref([
      { name: '默认背景', url: '/assets/images/image.png', type: 'image' },
      { name: '动态背景', url: '/assets/bg.mp4', type: 'video' },
      { name: '书房', url: '/assets/backgrounds/study.jpg', type: 'image' },
      { name: '客厅', url: '/assets/backgrounds/living.jpg', type: 'image' },
      { name: '办公室', url: '/assets/backgrounds/office.jpg', type: 'image' },
      { name: '自然', url: '/assets/backgrounds/nature.jpg', type: 'image' }
    ])
    
    // 监听 store 中的背景变化
    watch(() => store.state.currentBackground, (newBackground) => {
      if (newBackground) {
        currentBackground.value = newBackground
      }
    })
    
    // 切换背景控制面板显示
    const toggleBackgroundControls = () => {
      showBackgroundControls.value = !showBackgroundControls.value
    }
    
    // 选择背景
    const selectBackground = (bg) => {
      currentBackground.value = bg.url
      isVideoBackground.value = bg.type === 'video'
      
      if (isVideoBackground.value && bgVideoRef.value) {
        bgVideoRef.value.play().catch(error => {
          console.error('背景视频播放失败:', error)
        })
      }
      
      store.commit('SET_BACKGROUND', bg.url)
      ElMessage.success(`已切换到${bg.name}背景`)
    }

    // 添加新消息
    const addMessage = (content, type = 'system') => {
      console.log('WebRTCVideo - addMessage:', { content, type, messageId })
      messageId++
      const newMessage = {
        id: messageId,
        content,
        type,
        timestamp: new Date().toLocaleTimeString()
      }
      console.log('WebRTCVideo - 新消息对象:', newMessage)
      recentMessages.value.push(newMessage)
      // 保持最多4条消息
      if (recentMessages.value.length > 4) {
        console.log('WebRTCVideo - 移除最旧消息')
        recentMessages.value.shift()
      }
      console.log('WebRTCVideo - 当前消息列表:', recentMessages.value)
    }

    // 处理图片加载错误
    const handleImageError = () => {
      currentBackground.value = defaultImage.value
      isVideoBackground.value = false
    }

    // 添加透明度处理函数
    const addAlpha = (() => {
      // 缓存计算结果
      let lastImageData = null
      let lastResult = null
      let frameCount = 0
      
      // 创建离屏 Canvas 用于处理
      const offscreenCanvas = new OffscreenCanvas(1, 1)
      const ctx = offscreenCanvas.getContext('2d', {
        willReadFrequently: true,
        alpha: true
      })
      
      // 使用 WebGL 进行处理
      const vertexShaderSource = `
        attribute vec2 a_position;
        attribute vec2 a_texCoord;
        varying vec2 v_texCoord;
        void main() {
          gl_Position = vec4(a_position, 0, 1);
          v_texCoord = a_texCoord;
        }
      `
      
      const fragmentShaderSource = `
        precision highp float;
        uniform sampler2D u_image;
        uniform float u_gFloor;
        uniform float u_rbCeiling;
        uniform float u_tolerance;
        varying vec2 v_texCoord;

        // RGB to YUV 转换
        vec3 rgb2yuv(vec3 rgb) {
          return vec3(
            rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114,
            rgb.r * -0.169 + rgb.g * -0.331 + rgb.b * 0.5 + 0.5,
            rgb.r * 0.5 + rgb.g * -0.419 + rgb.b * -0.081 + 0.5
          );
        }

        // 获取周围像素的平均值
        vec4 sampleNeighbors(sampler2D image, vec2 uv, float stepSize) {
          vec4 sum = vec4(0.0);
          float count = 0.0;
          
          for(float x = -2.0; x <= 2.0; x += 1.0) {
            for(float y = -2.0; y <= 2.0; y += 1.0) {
              vec2 offset = vec2(x, y) * stepSize;
              sum += texture2D(image, uv + offset);
              count += 1.0;
            }
          }
          
          return sum / count;
        }

        void main() {
          // 获取当前像素和周围像素的平均值
          vec4 center = texture2D(u_image, v_texCoord);
          vec4 neighbors = sampleNeighbors(u_image, v_texCoord, 0.001);
          
          // 转换到 YUV 空间
          vec3 yuv = rgb2yuv(center.rgb);
          vec3 nYuv = rgb2yuv(neighbors.rgb);
          
          // 计算色度差异
          float chromaDiff = distance(yuv.yz, vec2(0.5, 0.5));
          float nChromaDiff = distance(nYuv.yz, vec2(0.5, 0.5));
          
          // 绿色检测
          float isGreen = 0.0;
          if (center.g > u_gFloor / 255.0 && 
              center.g > center.r * 1.4 && 
              center.g > center.b * 1.4 &&
              chromaDiff > 0.1) {
            isGreen = 1.0;
            
            // 边缘检测和羽化
            float edgeFactor = abs(chromaDiff - nChromaDiff);
            if (edgeFactor > 0.01) {
              // 在边缘区域进行平滑过渡
              isGreen = smoothstep(0.0, 0.1, chromaDiff);
            }
          }
          
          // 颜色校正
          vec3 finalColor = center.rgb;
          if (isGreen < 1.0) {
            // 去除绿色溢出
            float greenSpill = max(0.0, center.g - max(center.r, center.b));
            finalColor.g = max(0.0, center.g - greenSpill * (1.0 - isGreen));
            
            // 增强对比度和饱和度
            vec3 contrast = (finalColor - 0.5) * 1.1 + 0.5;
            float luminance = dot(contrast, vec3(0.299, 0.587, 0.114));
            finalColor = mix(vec3(luminance), contrast, 1.2);
            
            // 确保颜色值在有效范围内
            finalColor = clamp(finalColor, 0.0, 1.0);
          }
          
          // 设置最终的透明度和颜色
          float alpha = 1.0 - isGreen;
          gl_FragColor = vec4(finalColor, alpha);
        }
      `
      
      // 初始化 WebGL
      const gl = offscreenCanvas.getContext('webgl', { 
        premultipliedAlpha: false,
        preserveDrawingBuffer: true
      })
      
      if (!gl) {
        console.warn('WebGL not supported, falling back to Canvas 2D')
        return (imageData) => {
          // 降级处理：使用 Canvas 2D
          const data = imageData.data
          const width = imageData.width
          const height = imageData.height
          
          // 每隔几帧才进行完整处理
          if (frameCount++ % 2 === 0) {
            lastImageData = imageData
            lastResult = new Uint8ClampedArray(data)
            
            // 使用 Uint32Array 加速处理
            const buf32 = new Uint32Array(lastResult.buffer)
            const gFloor = 80
            const rbCeiling = 180
            const tolerance = 40
            
            for (let i = 0; i < buf32.length; i++) {
              const r = data[i * 4]
              const g = data[i * 4 + 1]
              const b = data[i * 4 + 2]
              
              if (g > gFloor && 
                  g > (r + tolerance) && 
                  g > (b + tolerance) &&
                  r < rbCeiling && 
                  b < rbCeiling) {
                buf32[i] = 0 // 完全透明
              }
            }
            
            imageData.data.set(lastResult)
            return imageData
          } else {
            // 复用上一帧的结果
            if (lastResult) {
              imageData.data.set(lastResult)
            }
            return imageData
          }
        }
      }
      
      // 编译着色器
      function createShader(gl, type, source) {
        const shader = gl.createShader(type)
        gl.shaderSource(shader, source)
        gl.compileShader(shader)
        return shader
      }
      
      // 创建程序
      const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource)
      const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource)
      const program = gl.createProgram()
      gl.attachShader(program, vertexShader)
      gl.attachShader(program, fragmentShader)
      gl.linkProgram(program)
      gl.useProgram(program)
      
      // 设置顶点缓冲区
      const positionBuffer = gl.createBuffer()
      const positions = new Float32Array([
        -1, -1,
        1, -1,
        -1, 1,
        1, 1,
      ])
      gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer)
      gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW)
      
      // 设置纹理坐标缓冲区
      const texCoordBuffer = gl.createBuffer()
      const texCoords = new Float32Array([
        0, 0,
        1, 0,
        0, 1,
        1, 1,
      ])
      gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer)
      gl.bufferData(gl.ARRAY_BUFFER, texCoords, gl.STATIC_DRAW)
      
      // 获取属性位置
      const positionLocation = gl.getAttribLocation(program, 'a_position')
      const texCoordLocation = gl.getAttribLocation(program, 'a_texCoord')
      
      // 获取 uniform 位置
      const imageLocation = gl.getUniformLocation(program, 'u_image')
      const gFloorLocation = gl.getUniformLocation(program, 'u_gFloor')
      const rbCeilingLocation = gl.getUniformLocation(program, 'u_rbCeiling')
      const toleranceLocation = gl.getUniformLocation(program, 'u_tolerance')
      
      // 创建纹理
      const texture = gl.createTexture()
      gl.bindTexture(gl.TEXTURE_2D, texture)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST)
      
      let previousFrame = null
      const smoothingFactor = 0.2 // 平滑系数

      return (imageData) => {
        const width = imageData.width
        const height = imageData.height
        
        // 设置 Canvas 尺寸
        if (offscreenCanvas.width !== width) {
          offscreenCanvas.width = width
          offscreenCanvas.height = height
          gl.viewport(0, 0, width, height)
        }
        
        // 更新纹理
        gl.bindTexture(gl.TEXTURE_2D, texture)
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, imageData)
        
        // 设置顶点属性
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer)
        gl.enableVertexAttribArray(positionLocation)
        gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0)
        
        gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer)
        gl.enableVertexAttribArray(texCoordLocation)
        gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0)
        
        // 设置 uniform 值 - 调整参数
        gl.uniform1i(imageLocation, 0)
        gl.uniform1f(gFloorLocation, 90)     // 降低绿色基准值
        gl.uniform1f(rbCeilingLocation, 160) // 调整红蓝上限
        gl.uniform1f(toleranceLocation, 25)  // 降低容差值
        
        // 绘制
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)
        
        // 读取结果
        const pixels = new Uint8Array(width * height * 4)
        gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels)
        
        // 更新图像数据
        imageData.data.set(new Uint8ClampedArray(pixels))
        return imageData
      }
    })()

    // 处理视频帧
    const processVideoFrame = async (frame, controller) => {
      try {
        const { timestamp, displayWidth, displayHeight } = frame
        
        // 使用 requestAnimationFrame 来控制帧率
        if (window.requestAnimationFrame) {
          await new Promise(resolve => requestAnimationFrame(resolve))
        }
        
        controller.enqueue(frame)
      } catch (error) {
        console.error('处理视频帧时出错:', error)
        controller.enqueue(frame)
      }
    }

    // 处理音频错误
    const handleAudioError = (error) => {
      console.error('音频播放错误:', error)
      // 尝试重新播放
      if (audioRef.value && audioRef.value.srcObject) {
        console.log('尝试重新播放音频...')
        audioRef.value.play()
          .then(() => console.log('音频重新播放成功'))
          .catch(e => console.error('音频重新播放失败:', e))
      }
    }

    // 处理音频开始播放
    const handleAudioPlaying = () => {
      console.log('音频开始播放')
    }

    const handleTrack = (event) => {
      console.log('收到媒体轨道:', {
        kind: event.track.kind,
        id: event.track.id,
        label: event.track.label,
        enabled: event.track.enabled,
        muted: event.track.muted,
        readyState: event.track.readyState
      })
      
      if (event.track.kind === 'video') {
        console.log('处理视频轨道...')
        
        // 检测移动设备
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        const isModernBrowser = 'MediaStreamTrackProcessor' in window
        
        console.log('设备检测:', {
          isMobile,
          isModernBrowser,
          userAgent: navigator.userAgent
        })
        
        if (isMobile || !isModernBrowser) {
          // 移动设备使用Canvas处理
          console.log('移动设备或不支持 MediaStreamTrackProcessor：使用Canvas处理视频')
          const videoStream = new MediaStream([event.track])
          const tempVideo = document.createElement('video')
          tempVideo.srcObject = videoStream
          tempVideo.autoplay = true
          tempVideo.playsInline = true
          tempVideo.muted = true
          
          // 创建Canvas元素
          const canvas = document.createElement('canvas')
          canvas.style.width = '100%'
          canvas.style.height = '100%'
          canvas.style.objectFit = 'cover'
          canvas.style.background = 'transparent'
          
          // 替换video元素为canvas
          if (videoRef.value) {
            videoRef.value.style.display = 'none'
            const mediaContainer = videoRef.value.parentElement
            if (mediaContainer) {
              // 移除可能存在的旧canvas
              const oldCanvas = mediaContainer.querySelector('canvas')
              if (oldCanvas) {
                oldCanvas.remove()
              }
              mediaContainer.appendChild(canvas)
              mediaContainer.style.display = 'block'
            }
          }
          
          const ctx = canvas.getContext('2d', { willReadFrequently: true })
          let animationFrameId = null
          
          // 处理视频帧
          const processFrame = () => {
            if (tempVideo.videoWidth && tempVideo.videoHeight) {
              // 设置canvas尺寸
              if (canvas.width !== tempVideo.videoWidth) {
                canvas.width = tempVideo.videoWidth
                canvas.height = tempVideo.videoHeight
              }
              
              // 绘制视频帧
              ctx.drawImage(tempVideo, 0, 0, canvas.width, canvas.height)
              
              // 处理透明背景
              const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
              const data = imageData.data
              const gFloor = 100
              const rbCeiling = 100
              
              for (let i = 0; i < data.length; i += 4) {
                if (data[i + 1] >= gFloor && data[i] <= rbCeiling && data[i + 2] <= rbCeiling) {
                  data[i + 3] = 0 // 设置透明
                }
              }
              
              ctx.putImageData(imageData, 0, 0)
            }
            
            // 继续下一帧
            animationFrameId = requestAnimationFrame(processFrame)
          }
          
          tempVideo.onloadedmetadata = () => {
            console.log('临时视频元数据加载完成:', {
              width: tempVideo.videoWidth,
              height: tempVideo.videoHeight
            })
            tempVideo.play()
              .then(() => {
                console.log('临时视频开始播放')
                processFrame()
              })
              .catch(error => console.error('临时视频播放失败:', error))
          }
          
          // 保存清理函数所需的引用
          videoTrack = event.track
          
          // 添加轨道结束事件监听
          event.track.onended = () => {
            console.log('视频轨道结束')
            if (animationFrameId) {
              cancelAnimationFrame(animationFrameId)
            }
            if (canvas && canvas.parentElement) {
              canvas.remove()
            }
            if (tempVideo.srcObject) {
              tempVideo.srcObject = null
            }
          }
        } else {
          // 桌面端现代浏览器使用 MediaStreamTrackProcessor
          console.log('桌面端现代浏览器：使用 MediaStreamTrackProcessor')
          const { track } = event
          const processor = new MediaStreamTrackProcessor({ track })
          
          const generator = new MediaStreamTrackGenerator({ kind: 'video' })
          const transparentStream = new MediaStream([generator])
          videoRef.value.srcObject = transparentStream
          
          // 创建 Canvas 用于处理帧
          const offscreen = new OffscreenCanvas(1, 1)
          const ctx = offscreen.getContext('2d', { willReadFrequently: true })
          
          // 处理视频帧的函数
          const addTransparency = (frame, controller) => {
            const height = frame.codedHeight
            const width = frame.codedWidth
            
            offscreen.height = height
            offscreen.width = width
            
            // 将帧绘制到 Canvas
            ctx.drawImage(frame, 0, 0, width, height)
            
            // 获取像素数据
            const imageData = ctx.getImageData(0, 0, width, height)
            const processedData = addAlpha(imageData)
            
            // 将处理后的数据放回 Canvas
            ctx.putImageData(processedData, 0, 0)
            
            // 创建新的视频帧
            const newFrame = new VideoFrame(offscreen, { timestamp: frame.timestamp })
            controller.enqueue(newFrame)
            frame.close()
          }
          
          // 设置处理管道
          processor.readable
            .pipeThrough(new TransformStream({
              transform: (frame, controller) => addTransparency(frame, controller)
            }))
            .pipeTo(generator.writable)
            .catch(error => {
              console.error('视频处理管道错误:', error)
              // 降级处理：直接显示原始视频
              videoRef.value.srcObject = new MediaStream([track])
            })
        }
        
        videoRef.value.onloadedmetadata = () => {
          console.log('视频元数据加载完成:', {
            width: videoRef.value.videoWidth,
            height: videoRef.value.videoHeight
          })
          videoRef.value.play()
            .then(() => console.log('视频开始播放'))
            .catch(error => console.error('视频播放失败:', error))
        }
        
        videoRef.value.onerror = (error) => {
          console.error('视频错误:', error)
        }
      } else if (event.track.kind === 'audio') {
        console.log('处理音频轨道...', {
          enabled: event.track.enabled,
          muted: event.track.muted,
          readyState: event.track.readyState,
          id: event.track.id
        })
        
        // 如果轨道是静音的，跳过处理
        if (event.track.muted) {
          console.log('跳过静音轨道:', event.track.id)
          return
        }
        
        try {
          // 创建新的音频流
          const audioStream = new MediaStream([event.track])
          console.log('创建音频流:', {
            active: audioStream.active,
            id: audioStream.id
          })
          
          // 确保音频轨道是启用的
          event.track.enabled = true
          
          // 设置音频源
          if (!audioRef.value.srcObject || audioRef.value.muted) {
            audioRef.value.srcObject = audioStream
            
            // 设置音频属性
            audioRef.value.volume = 1.0
            audioRef.value.muted = false
            
            // 添加音频元素事件监听
            audioRef.value.onvolumechange = () => {
              console.log('音量变化:', {
                volume: audioRef.value.volume,
                muted: audioRef.value.muted
              })
            }
            
            audioRef.value.onstalled = () => console.log('音频播放停滞')
            audioRef.value.onsuspend = () => console.log('音频加载暂停')
            audioRef.value.onwaiting = () => console.log('等待音频数据')
            
            audioRef.value.onloadedmetadata = () => {
              console.log('音频元数据加载完成', {
                duration: audioRef.value.duration,
                readyState: audioRef.value.readyState,
                paused: audioRef.value.paused,
                currentTime: audioRef.value.currentTime
              })
              
              // 确保音频上下文是激活的
              const audioContext = new (window.AudioContext || window.webkitAudioContext)()
              if (audioContext.state === 'suspended') {
                audioContext.resume()
              }
              
              // 尝试播放音频
              const playAudio = async () => {
                try {
                  await audioRef.value.play()
                  console.log('音频开始播放', {
                    currentTime: audioRef.value.currentTime,
                    volume: audioRef.value.volume,
                    muted: audioRef.value.muted
                  })
                } catch (error) {
                  console.error('音频播放失败，尝试自动重试:', error)
                  setTimeout(playAudio, 1000)
                }
              }
              
              playAudio()
            }
          } else {
            console.log('已存在活跃的音频流，跳过处理')
          }
        } catch (error) {
          console.error('设置音频轨道时出错:', error)
        }
      }
    }

    // 处理数据通道消息
    const handleDataChannelMessage = (data) => {
      try {
        let message = JSON.parse(data)
        console.log('WebRTCVideo - 收到原始消息:', message)
        
        if (message.type === 'image') {
          if (message.content === 'null') {
            currentBackground.value = defaultImage.value
          } else {
            currentBackground.value = `http://175.27.143.201:8010/${message.content}`
          }
        } else if (message.type === 'system' || message.type === 'user') {
          console.log('WebRTCVideo - 处理消息:', message)
          // 确保消息是一个新对象，避免修改原始消息
          message = {
            ...message,
            timestamp: (() => {
              const now = new Date()
              return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`
            })()
          }
          console.log('WebRTCVideo - 添加时间戳后的消息:', message)
          // 将消息添加到 store
          store.commit('ADD_MESSAGE', message)
        } else {
          console.log('WebRTCVideo - 未知消息类型:', message.type)
        }
      } catch (error) {
        console.error('WebRTCVideo - 处理消息失败:', error)
      }
    }

    const handleMaterialManagerClose = () => {
      showMaterialManager.value = false
    }
    
    const handleBackgroundSelect = (background) => {
      currentBackground.value = background.url
      isVideoBackground.value = background.type === 'video'
      showMaterialManager.value = false
      ElMessage.success('背景已更新')
    }

    onMounted(() => {
      console.log('组件挂载，初始化 WebRTC 事件监听...')
      
      // 初始化时检查并设置现有连接
      const currentPc = store.state.webrtcState.pc
      if (currentPc) {
        console.log('发现现有 WebRTC 连接，设置事件监听...')
        currentPc.ontrack = handleTrack
      }

      // 初始化数据通道
      const currentDc = store.state.webrtcState.dc
      if (currentDc) {
        console.log('发现现有数据通道，设置消息监听...')
        currentDc.addEventListener('message', (evt) => {
          handleDataChannelMessage(evt.data)
        })
      }

      // 监听 WebRTC 状态变化
      watch(() => store.state.webrtcState.pc, (newPc, oldPc) => {
        console.log('WebRTC 连接状态变化:', newPc ? '新连接' : '连接断开')
        
        // 清理旧连接的监听器和资源
        if (oldPc) {
          oldPc.ontrack = null
          oldPc.onconnectionstatechange = null
          oldPc.onsignalingstatechange = null
          oldPc.oniceconnectionstatechange = null
          oldPc.onicegatheringstatechange = null
          oldPc.onnegotiationneeded = null
        }
        
        // 当连接断开时，清除视频源和相关资源
        if (!newPc) {
          console.log('清理断开连接的资源...')
          if (videoRef.value) {
            if (videoRef.value.srcObject) {
              const tracks = videoRef.value.srcObject.getTracks()
              tracks.forEach(track => {
                track.stop()
                track.onended = null
              })
              videoRef.value.srcObject = null
            }
            videoRef.value.style.display = 'none'
          }
          
          // 清理 Canvas
          const mediaContainer = document.querySelector('.media-container')
          if (mediaContainer) {
            const canvas = mediaContainer.querySelector('canvas')
            if (canvas) {
              const ctx = canvas.getContext('2d')
              if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height)
              }
              canvas.remove()
            }
            mediaContainer.style.display = 'none'
          }
          
          // 清理音频
          if (audioRef.value && audioRef.value.srcObject) {
            const tracks = audioRef.value.srcObject.getTracks()
            tracks.forEach(track => {
              track.stop()
              track.onended = null
            })
            audioRef.value.srcObject = null
          }
        }
        
        // 设置新连接的监听器
        if (newPc) {
          console.log('设置新连接的事件监听器...')
          const mediaContainer = document.querySelector('.media-container')
          if (mediaContainer) {
            mediaContainer.style.display = 'block'
          }
          if (videoRef.value) {
            videoRef.value.style.display = 'block'
          }
          
          // 设置 ontrack 处理器
          newPc.ontrack = handleTrack
          
          // 监听连接状态
          newPc.onconnectionstatechange = () => {
            const state = newPc.connectionState
            console.log('连接状态变化:', state)
            isConnected.value = state === 'connected'
            
            // 更新 store 中的连接状态
            store.commit('updateConnectionStatus', state === 'connected')
            
            if (state === 'connected') {
              // 连接建立后检查远程流
              console.log('检查远程流...')
              const receivers = newPc.getReceivers()
              console.log('当前接收器:', receivers.map(receiver => ({
                kind: receiver.track?.kind,
                trackId: receiver.track?.id,
                trackEnabled: receiver.track?.enabled,
                trackMuted: receiver.track?.muted
              })))
              
              // 主动处理现有轨道
              receivers.forEach(receiver => {
                if (receiver.track) {
                  console.log('主动处理轨道:', receiver.track.kind)
                  const event = {
                    track: receiver.track,
                    streams: [new MediaStream([receiver.track])]
                  }
                  handleTrack(event)
                }
              })
            } else if (state === 'disconnected' || state === 'failed' || state === 'closed') {
              // 连接断开或失败时的清理
              console.log('连接断开，执行清理...')
              if (videoRef.value) {
                if (videoRef.value.srcObject) {
                  const tracks = videoRef.value.srcObject.getTracks()
                  tracks.forEach(track => {
                    track.stop()
                    track.onended = null
                  })
                  videoRef.value.srcObject = null
                }
                videoRef.value.style.display = 'none'
              }
              
              // 清理 Canvas
              const mediaContainer = document.querySelector('.media-container')
              if (mediaContainer) {
                const canvas = mediaContainer.querySelector('canvas')
                if (canvas) {
                  const ctx = canvas.getContext('2d')
                  if (ctx) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height)
                  }
                  canvas.remove()
                }
                mediaContainer.style.display = 'none'
              }
              
              // 更新状态
              store.commit('updateConnectionStatus', false)
            }
          }
          
          // 监听信令状态
          newPc.onsignalingstatechange = () => {
            console.log('信令状态变化:', newPc.signalingState)
            if (newPc.signalingState === 'closed') {
              console.log('信令连接关闭，执行清理...')
              // 执行清理操作
              if (videoRef.value) {
                videoRef.value.srcObject = null
                videoRef.value.style.display = 'none'
              }
              const mediaContainer = document.querySelector('.media-container')
              if (mediaContainer) {
                mediaContainer.style.display = 'none'
              }
            }
          }
          
          // 监听 ICE 连接状态
          newPc.oniceconnectionstatechange = () => {
            console.log('ICE 连接状态变化:', newPc.iceConnectionState)
            if (newPc.iceConnectionState === 'disconnected' || 
                newPc.iceConnectionState === 'failed' || 
                newPc.iceConnectionState === 'closed') {
              console.log('ICE 连接断开，执行清理...')
              // 执行清理操作
              if (videoRef.value) {
                videoRef.value.srcObject = null
                videoRef.value.style.display = 'none'
              }
              const mediaContainer = document.querySelector('.media-container')
              if (mediaContainer) {
                mediaContainer.style.display = 'none'
              }
            }
          }
        }
      }, { immediate: true })

      // 初始化背景视频
      if (bgVideoRef.value) {
        bgVideoRef.value.play().catch(error => {
          console.error('背景视频播放失败:', error)
        })
      }
    })

    onUnmounted(() => {
      console.log('组件卸载，清理事件监听...')
      
      const pc = store.state.webrtcState.pc
      if (pc) {
        pc.ontrack = null
      }
      
      if (videoTrack) {
        videoTrack.stop()
      }

      const dc = store.state.webrtcState.dc
      if (dc) {
        dc.removeEventListener('message', handleDataChannelMessage)
      }
      
      // 清理视频和音频元素
      if (videoRef.value) {
        videoRef.value.srcObject = null
        // 清理可能存在的canvas
        const mediaContainer = videoRef.value.parentElement
        if (mediaContainer) {
          const canvas = mediaContainer.querySelector('canvas')
          if (canvas) {
            canvas.remove()
          }
          mediaContainer.style.display = 'none'
        }
      }
      if (audioRef.value) {
        audioRef.value.srcObject = null
      }
    })

    return {
      videoRef,
      audioRef,
      displayedImage,
      isConnected,
      currentBackground,
      defaultImage,
      handleImageError,
      recentMessages,
      backgroundOptions,
      showBackgroundControls,
      toggleBackgroundControls,
      selectBackground,
      bgVideoRef,
      isVideoBackground,
      showMaterialManager,
      handleMaterialManagerClose,
      handleBackgroundSelect
    }
  }
}
</script>

<style lang="scss" scoped>
.video-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  background: transparent;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);

  @media (min-width: 769px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 
      0 4px 24px rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.05);
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
      pointer-events: none;
      z-index: 1;
    }
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
      pointer-events: none;
      z-index: 1;
    }
    
    .image-container, .media-container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      
      img, video, canvas {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }

  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 16px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.2);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      
      @media (min-width: 769px) {
        filter: brightness(0.95) contrast(1.05);
      }
    }

    .background-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  .media-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

    video, canvas {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      background: transparent !important;
      transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      -webkit-transform: translateZ(0);
      transform: translateZ(0);

      @media (min-width: 769px) {
        object-fit: contain;
        filter: drop-shadow(0 8px 24px rgba(0, 0, 0, 0.2));
      }
    }

    video {
      -webkit-playsinline: true;
      playsinline: true;
      -webkit-video-playable-inline: true;

      &:not([srcObject]) {
        display: none;
      }
    }

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }

  .message-bubble {
    padding: 12px 16px;
    border-radius: 16px;
    max-width: 70%;
    animation: fadeIn 0.3s ease;
    font-size: 16px;
    line-height: 1.4;
    word-break: break-word;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    
    &.system {
      align-self: flex-start;
      background: rgba(255, 255, 255, 0.3);
      color: #333;
      border-top-left-radius: 4px;
      margin-left: 12px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    &.user {
      align-self: flex-end;
      background: rgba(0, 114, 255, 0.3);
      color: #fff;
      border-top-right-radius: 4px;
      margin-right: 12px;
      box-shadow: 0 2px 8px rgba(0, 114, 255, 0.2);
      border-color: rgba(0, 114, 255, 0.3);
    }

    .message-time {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);
      margin-top: 4px;
      align-self: flex-end;
    }
  }

  audio {
    display: none; /* 隐藏音频元素但允许播放 */
  }

  &.not-connected {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    
    .placeholder-text {
      font-size: 18px;
      color: var(--text-secondary);
      text-align: center;
      padding: 20px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.controls-toggle {
  position: absolute;
  top: 16px;
  right: 16px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  padding: 8px 16px;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  z-index: 11;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
  
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  i {
    font-size: 12px;
  }
}

.background-controls {
  position: absolute;
  top: 60px;
  right: 16px;
  width: 300px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  padding: 16px;
  z-index: 10;
  border-radius: 12px;
  transition: all 0.3s ease;
  transform-origin: top right;
  animation: slideIn 0.3s ease;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .background-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    
    h4 {
      margin: 0;
      color: #fff;
      font-size: 16px;
    }
  }

  .background-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    max-height: 400px;
    overflow-y: auto;
    padding-right: 8px;
    
    &::-webkit-scrollbar {
      width: 4px;
    }
    
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 2px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 2px;
    }

    .background-option {
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 8px;
      padding: 8px;
      
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
      
      &.active {
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
      }
      
      &.video {
        .preview-video {
          width: 100%;
          height: 80px;
          object-fit: cover;
          border-radius: 4px;
          margin-bottom: 8px;
          background: rgba(0, 0, 0, 0.1);
        }
      }
      
      img, .preview-video {
        width: 100%;
        height: 80px;
        object-fit: cover;
        border-radius: 4px;
        margin-bottom: 8px;
      }
      
      span {
        display: block;
        color: #fff;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style> 