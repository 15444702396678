import axios from 'axios'
import { ElMessage } from 'element-plus'
import config from '@/config'

// 创建用于一般请求的实例
const apiService = axios.create({
  baseURL: '/api',
  timeout: config.api.timeout
})

// 创建用于WebRTC的实例
const webrtcService = axios.create({
  baseURL: '/webrtc',
  timeout: config.api.timeout
})

// 创建用于聊天的实例
const chatService = axios.create({
  // baseURL: process.env.VUE_APP_API_CHAT_URL || '/human',
  baseURL: '/chat',
  timeout: config.api.timeout
})

// 请求拦截器
apiService.interceptors.request.use(
  config => {
    console.log('发送请求:', config.url, config.data)
    return config
  },
  error => {
    console.error('请求错误:', error)
    return Promise.reject(error)
  }
)

// 响应拦截器
apiService.interceptors.response.use(
  response => {
    console.log('响应数据:', response.data)
    return response.data
  },
  error => {
    console.error('响应错误:', error)
    ElMessage.error(error.message || '请求失败')
    return Promise.reject(error)
  }
)

// WebRTC相关接口
export const webrtcAPI = {
  // 发送offer
  async sendOffer(data) {
    console.log('发送请求: /offer', data)
    try {
      const response = await webrtcService.post('/offer', {
        sdp: data.sdp,
        type: data.type,
        username: data.username,
        avatar: data.avatar,
        voice: data.voice,
        system_prompt: data.system_prompt
      })
      console.log('服务器原始响应:', response)
      
      // 确保响应数据包含必要的 SDP 信息
      if (!response.data || !response.data.sdp || !response.data.type) {
        throw new Error('服务器响应缺少必要的 SDP 信息')
      }
      
      return {
        data: {
          sdp: response.data.sdp,
          type: response.data.type,
          sessionid: response.data.sessionid
        }
      }
    } catch (error) {
      console.error('发送 offer 失败:', error)
      console.error('错误响应:', error.response?.data)
      throw error
    }
  },
  
  // 创建预加载器
  createPreloader(avatar) {
    console.log('调用预加载 API:', avatar)
    return webrtcService.post('/create_preloader', {
      avatar_id: avatar.id,
      voice: avatar.voice || 'hutao' // 使用数字人配置的语音，如果没有则使用默认值
    })
  },
  
  // 删除预加载器
  deletePreloader(data) {
    return webrtcService.post('/delete_preloader', {
      avatar_id: data.avatar
    })
  },

  // 开始录音
  startRadio: async (sessionId) => {
    console.log('发送请求: /switch_radio', { sessionId, action: 'start_radio' })
    try {
      const response = await webrtcService.post('/switch_radio', {
        sessionid: sessionId,
        action: 'start_radio'
      })
      return response.data
    } catch (error) {
      console.error('响应错误:', error)
      throw error
    }
  },

  // 停止录音
  stopRadio: async (sessionId) => {
    console.log('发送请求: /switch_radio', { sessionId, action: 'stop_radio' })
    try {
      const response = await webrtcService.post('/switch_radio', {
        sessionid: sessionId,
        action: 'stop_radio'
      })
      return response.data
    } catch (error) {
      console.error('响应错误:', error)
      throw error
    }
  }
}

// 聊天相关接口
export const chatAPI = {
  // 发送消息
  sendMessage(data) {
    return chatService.post('', {
      sessionid: data.sessionId,
      text: data.text
    })
  }
}

// 数字人相关接口
export const avatarAPI = {
  // 获取语音列表
  getVoiceList() {
    const baseURL = process.env.VUE_APP_SERVER_URL || 'http://localhost:3000'
    return axios.get(`${baseURL}/api/voices`)
  },

  // 上传克隆语音
  uploadVoice(formData) {
    const baseURL = process.env.VUE_APP_SERVER_URL || 'http://localhost:3000'
    return axios.post(`${baseURL}/api/voices/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  // 删除语音
  deleteVoice(voiceId) {
    const baseURL = process.env.VUE_APP_SERVER_URL || 'http://localhost:3000'
    return axios.delete(`${baseURL}/api/voices/${voiceId}`)
  },

  // 试听语音
  previewVoice(voiceId, text) {
    const baseURL = process.env.VUE_APP_SERVER_URL || 'http://localhost:3000'
    return axios.post(`${baseURL}/api/voices/preview`, {
      voiceId,
      text
    })
  }
}

export default {
  webrtcAPI,
  chatAPI,
  avatarAPI
} 