import axios from 'axios'
import { ElMessage } from 'element-plus'

// 从环境变量获取后端地址
const baseURL = process.env.VUE_APP_CALLBACK_URL || 'http://localhost:3000'

// 创建 axios 实例
const service = axios.create({
  baseURL: `${baseURL}/api`,
  timeout: 300000, // 5分钟超时
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 对于上传请求特殊处理
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
  },
  error => {
    console.error('Request error:', error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    
    // 如果响应成功
    if (res.code === 200) {
      return res
    }
    
    // 处理特定错误码
    if (res.code === 413) {
      ElMessage.error('文件太大，请选择小于100MB的文件')
    } else {
      ElMessage.error(res.msg || '请求失败')
    }
    return Promise.reject(new Error(res.msg || '请求失败'))
  },
  error => {
    console.error('Response error:', error)
    // 处理网络错误
    if (error.message.includes('Network Error')) {
      ElMessage.error('网络错误，请检查网络连接')
    } else if (error.code === 'ECONNABORTED') {
      ElMessage.error('请求超时，请重试')
    } else {
      ElMessage.error(error.response?.data?.msg || error.message || '请求失败')
    }
    return Promise.reject(error)
  }
)

export default service 