<template>
  <div class="login-page">
    <div class="login-container">
      <h2>登录</h2>
      <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        label-position="top"
        @submit.prevent="handleLogin"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="form.username"
            placeholder="请输入用户名"
            @keyup.enter="handleLogin"
          />
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            placeholder="请输入密码"
            show-password
            @keyup.enter="handleLogin"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            :loading="loading"
            class="submit-btn"
            @click="handleLogin"
          >
            登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'

export default {
  name: 'Login',
  setup() {
    const store = useStore()
    const router = useRouter()
    const formRef = ref(null)
    const loading = ref(false)

    const form = reactive({
      username: '',
      password: ''
    })

    const rules = {
      username: [
        { required: true, message: '请输入用户名', trigger: 'blur' },
        { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
      ],
      password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
        { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
      ]
    }

    const handleLogin = async () => {
      if (!formRef.value) return

      try {
        await formRef.value.validate()
        loading.value = true

        // 这里简化登录逻辑，实际项目中应该调用API
        const user = {
          username: form.username,
          avatar: form.username.charAt(0).toUpperCase()
        }
        
        store.dispatch('login', user)
        ElMessage.success('登录成功')
        router.push('/')
      } catch (error) {
        console.error('登录失败:', error)
      } finally {
        loading.value = false
      }
    }

    return {
      formRef,
      form,
      rules,
      loading,
      handleLogin
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, var(--primary-light), var(--primary-color));
  padding: 20px;

  .login-container {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    background: var(--card-background);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-card);

    h2 {
      text-align: center;
      color: var(--text-primary);
      margin-bottom: 30px;
    }

    .submit-btn {
      width: 100%;
      padding: 12px;
      font-size: 16px;
    }
  }
}

@include mobile {
  .login-page {
    padding: 15px;

    .login-container {
      padding: 30px 20px;
    }
  }
}
</style> 