import { trainingAPI } from '@/api/training'

const state = {
  trainingList: [], // 训练列表
  currentTraining: null, // 当前训练任务
  builtinAvatars: JSON.parse(process.env.VUE_APP_AVATAR_LIST || '[]'), // 内置数字人列表
  dbAvatars: [], // 数据库中的数字人列表
  dbAvatarsUpdated: false // 数据库数字人列表更新状态
}

const mutations = {
  SET_TRAINING_LIST(state, list) {
    // 确保每个训练项都有必要的字段
    state.trainingList = list.map(item => ({
      ...item,
      progress: item.progress || 0,
      status: item.status || 0,
      isConfigured: item.isConfigured || false
    }))
    localStorage.setItem('trainingList', JSON.stringify(state.trainingList))
  },
  
  ADD_TRAINING(state, training) {
    const exists = state.trainingList.find(t => t.digitalId === training.digitalId)
    if (!exists) {
      state.trainingList.push({
        ...training,
        isConfigured: false
      })
      localStorage.setItem('trainingList', JSON.stringify(state.trainingList))
    }
  },
  
  UPDATE_TRAINING(state, { digitalId, updates }) {
    const index = state.trainingList.findIndex(t => t.digitalId === digitalId)
    if (index !== -1) {
      state.trainingList[index] = { 
        ...state.trainingList[index], 
        ...updates,
        updateTime: new Date().toISOString()
      }
      localStorage.setItem('trainingList', JSON.stringify(state.trainingList))
    }
  },

  SET_TRAINING_CONFIGURED(state, digitalId) {
    const index = state.trainingList.findIndex(t => t.digitalId === digitalId)
    if (index !== -1) {
      state.trainingList[index].isConfigured = true
      localStorage.setItem('trainingList', JSON.stringify(state.trainingList))
    }
  },

  SET_CURRENT_TRAINING(state, { digitalId, status, msg, progress, coverImg, avatarName }) {
    const updates = {
      digitalId,
      status,
      msg: msg || '',
      coverImg,
      avatarName
    }

    switch (status) {
      case 0: // 初始状态
        updates.msg = msg || '准备开始上传...'
        updates.progress = 0
        break
        
      case 1: // 上传成功，开始预处理
        updates.msg = msg || '上传成功，开始预处理...'
        updates.progress = 50 // 固定50%
        break
        
      case 2: // 预处理成功，开始训练
        updates.msg = msg || '预处理完成，开始模型训练...'
        updates.progress = 75 // 固定75%
        break
        
      case 3: // 训练完成
        updates.msg = msg || '训练完成'
        updates.progress = 100 // 固定100%
        break
        
      case -1: // 训练失败
        updates.msg = msg || '训练失败'
        updates.progress = 0
        break
    }

    state.currentTraining = updates
  },

  REMOVE_TRAINING(state, digitalId) {
    state.trainingList = state.trainingList.filter(t => t.digitalId !== digitalId)
    if (state.currentTraining?.digitalId === digitalId) {
      state.currentTraining = null
    }
    // 保存到 localStorage
    localStorage.setItem('trainingList', JSON.stringify(state.trainingList))
  },

  SET_DB_AVATARS(state, avatars) {
    state.dbAvatars = avatars.map(avatar => ({
      ...avatar,
      isCustom: true,
      createTime: avatar.createTime || new Date().toISOString()
    }))
    state.dbAvatarsUpdated = true
  },

  INIT_TRAINING_LIST(state) {
    try {
      const savedList = localStorage.getItem('trainingList')
      if (savedList) {
        const list = JSON.parse(savedList)
        state.trainingList = list.map(item => ({
          ...item,
          progress: item.progress || 0,
          status: item.status || 0,
          isConfigured: item.isConfigured || false
        }))
      }
    } catch (error) {
      console.error('初始化训练列表失败:', error)
      state.trainingList = []
    }
  }
}

const actions = {
  /**
   * 初始化训练状态
   */
  async initializeTraining({ commit, dispatch }) {
    commit('INIT_TRAINING_LIST')
    
    try {
      // 获取服务器端的训练状态
      const response = await trainingAPI.getTrainingList()
      if (response.code === 200 && Array.isArray(response.data)) {
        // 合并本地和服务器的训练状态
        const serverList = response.data
        const localList = [...state.trainingList]
        
        // 更新本地训练状态
        const mergedList = serverList.map(serverItem => {
          const localItem = localList.find(item => item.digitalId === serverItem.digitalId)
          return {
            ...serverItem,
            progress: serverItem.progress || (localItem?.progress || 0),
            isConfigured: serverItem.isConfigured || (localItem?.isConfigured || false)
          }
        })
        
        commit('SET_TRAINING_LIST', mergedList)
      }
    } catch (error) {
      console.error('获取训练列表失败:', error)
    }

    // 加载数据库中的数字人列表
    await dispatch('loadDbAvatars')
  },

  /**
   * 开始新的训练任务
   */
  async startTraining({ commit }, { type, file, avatarName, needMatting, onProgress }) {
    try {
      if (!file) {
        throw new Error('未找到上传的文件')
      }

      const response = await trainingAPI.uploadAndTrain(type, file, avatarName, needMatting, onProgress)
      
      if (!response || response.code !== 200) {
        throw new Error(response?.msg || '服务器返回错误')
      }

      const training = {
        digitalId: response.data.digitalId,
        avatarName,
        file, // 保存文件引用以供重试使用
        needMatting, // 保存抠图选项
        type, // 保存类型信息
        status: 0, // 初始状态
        progress: 0,
        createTime: new Date().toISOString(),
        updateTime: new Date().toISOString(),
        msg: '准备开始上传...',
        isConfigured: false
      }
      
      commit('ADD_TRAINING', training)
      commit('SET_CURRENT_TRAINING', training)
      
      return training
    } catch (error) {
      console.error('开始训练失败:', error)
      throw error
    }
  },
  
  /**
   * 更新训练状态
   */
  async updateTrainingStatus({ commit, dispatch, state }, { digitalId, status, coverImg, msg, progress }) {
    console.log('更新训练状态:', { digitalId, status, msg, progress })
    
    const training = state.trainingList.find(t => t.digitalId === digitalId)
    if (!training) return

    const updates = {
      status,
      coverImg,
      msg,
      updateTime: new Date().toISOString()
    }

    // 根据状态设置进度和消息
    switch (status) {
      case 0: // 初始状态
        updates.progress = 0
        updates.msg = msg || '准备开始上传...'
        break
      case 1: // 上传成功，开始预处理
        updates.progress = 50 // 固定50%
        updates.msg = msg || '上传成功，开始预处理...'
        break
      case 2: // 预处理成功，开始训练
        updates.progress = 75 // 固定75%
        updates.msg = msg || '预处理完成，开始模型训练...'
        break
      case 3: // 训练完成
        updates.progress = 100
        updates.msg = msg || '训练完成'
        // 只更新数据库数字人列表，不移除训练记录
        await dispatch('loadDbAvatars')
        break
      case -1: // 训练失败
        updates.progress = 0
        updates.msg = msg || '训练失败'
        // 如果是超时导致的失败，添加重试选项
        if (msg?.includes('超时')) {
          updates.canRetry = true
        }
        break
    }

    commit('UPDATE_TRAINING', { digitalId, updates })
    commit('SET_CURRENT_TRAINING', { ...training, ...updates })
  },

  /**
   * 配置数字人
   */
  async configureAvatar({ commit, dispatch }, { digitalId, config }) {
    try {
      const response = await trainingAPI.configureAvatar(digitalId, config)
      if (response.code === 200) {
        commit('SET_TRAINING_CONFIGURED', digitalId)
        await dispatch('loadDbAvatars')
        commit('REMOVE_TRAINING', digitalId)
        return true
      }
      return false
    } catch (error) {
      console.error('配置数字人失败:', error)
      return false
    }
  },

  /**
   * 加载数据库中的数字人列表
   */
  async loadDbAvatars({ commit }) {
    try {
      const response = await trainingAPI.getDbAvatars()
      if (response.code === 200 && Array.isArray(response.data)) {
        commit('SET_DB_AVATARS', response.data)
      } else {
        throw new Error('获取数字人列表失败')
      }
    } catch (error) {
      console.error('加载数据库数字人失败:', error)
      throw error
    }
  },

  /**
   * 删除数字人
   */
  async deleteAvatar({ commit, dispatch }, { digitalId, isTraining = false }) {
    try {
      const response = await trainingAPI.deleteAvatar(digitalId)
      if (response.code === 200) {
        if (isTraining) {
          // 如果是训练中的数字人，从训练列表中移除
          commit('REMOVE_TRAINING', digitalId)
        } else {
          // 重新加载数据库中的数字人列表
          await dispatch('loadDbAvatars')
        }
        return true
      }
      return false
    } catch (error) {
      console.error('删除数字人失败:', error)
      throw error
    }
  },

  /**
   * 重试训练
   */
  async retryTraining({ commit }, training) {
    try {
      // 先删除旧的训练记录
      await trainingAPI.deleteAvatar(training.digitalId)
      commit('REMOVE_TRAINING', training.digitalId)
      
      // 返回成功状态，让前端重新打开上传对话框
      return {
        success: true,
        avatarName: training.avatarName // 返回原来的名称供参考
      }
    } catch (error) {
      console.error('重试训练失败:', error)
      throw error
    }
  }
}

const getters = {
  allAvatars: state => [...state.builtinAvatars, ...state.dbAvatars], // 合并内置和数据库数字人
  trainingList: state => state.trainingList,
  currentTraining: state => state.currentTraining,
  getTrainingById: state => digitalId => {
    return state.trainingList.find(t => t.digitalId === digitalId)
  },
  getAvatarById: state => id => {
    // 先从内置数字人中查找
    const builtinAvatar = state.builtinAvatars.find(a => a.id === id)
    if (builtinAvatar) return builtinAvatar
    
    // 再从数据库数字人中查找
    return state.dbAvatars.find(a => a.id === id)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
} 