<template>
  <div class="material-manager">
    <div class="material-header">
      <h3>素材管理</h3>
      <div class="material-tabs">
        <span 
          :class="['tab', { active: activeTab === 'image' }]" 
          @click="activeTab = 'image'"
        >图片素材</span>
        <span 
          :class="['tab', { active: activeTab === 'video' }]" 
          @click="activeTab = 'video'"
        >视频素材</span>
      </div>
    </div>

    <div class="upload-section">
      <el-upload
        :action="uploadUrl"
        :before-upload="beforeUpload"
        :on-success="handleUploadSuccess"
        :on-error="handleUploadError"
        :accept="activeTab === 'image' ? 'image/*' : 'video/*'"
        :show-file-list="false"
        :multiple="false"
        :headers="{
          'Accept': 'application/json'
        }"
        name="file"
      >
        <el-button type="primary">
          <i class="el-icon-upload"></i>
          上传{{ activeTab === 'image' ? '图片' : '视频' }}
        </el-button>
      </el-upload>
    </div>

    <div class="materials-list">
      <template v-if="activeTab === 'image'">
        <div 
          v-for="(item, index) in imageList" 
          :key="index"
          class="material-item"
        >
          <div class="material-preview">
            <img :src="item.url" :alt="item.name">
            <div class="material-actions">
              <el-button 
                type="primary" 
                size="small" 
                @click="useAsBackground(item)"
              >使用</el-button>
              <el-button 
                type="danger" 
                size="small" 
                @click="deleteMaterial(item)"
              >删除</el-button>
            </div>
          </div>
          <span class="material-name">{{ item.name }}</span>
        </div>
      </template>

      <template v-else>
        <div 
          v-for="(item, index) in videoList" 
          :key="index"
          class="material-item"
        >
          <div class="material-preview">
            <video 
              :src="item.url" 
              muted 
              loop 
              @mouseover="playVideo"
              @mouseout="pauseVideo"
            ></video>
            <div class="material-actions">
              <el-button 
                type="primary" 
                size="small" 
                @click="useAsBackground(item)"
              >使用</el-button>
              <el-button 
                type="danger" 
                size="small" 
                @click="deleteMaterial(item)"
              >删除</el-button>
            </div>
          </div>
          <span class="material-name">{{ item.name }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'

export default {
  name: 'MaterialManager',
  setup() {
    const store = useStore()
    const activeTab = ref('image')
    const imageList = ref([])
    const videoList = ref([])
    const baseURL = process.env.VUE_APP_SERVER_URL || 'http://localhost:3000'
    const uploadUrl = `${baseURL}/api/materials/upload`

    // 加载素材列表
    const loadMaterials = async () => {
      try {
        const response = await fetch(`${baseURL}/api/materials/list`)
        const data = await response.json()
        if (data.code === 200) {
          imageList.value = data.data.filter(item => item.type === 'image')
          videoList.value = data.data.filter(item => item.type === 'video')
        }
      } catch (error) {
        console.error('加载素材失败:', error)
        ElMessage.error('加载素材失败')
      }
    }

    // 上传前验证
    const beforeUpload = (file) => {
      const isImage = file.type.startsWith('image/')
      const isVideo = file.type.startsWith('video/')
      const isValidType = activeTab.value === 'image' ? isImage : isVideo
      
      if (!isValidType) {
        ElMessage.error(`请上传${activeTab.value === 'image' ? '图片' : '视频'}文件`)
        return false
      }

      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        ElMessage.error('文件大小不能超过 10MB')
        return false
      }

      return true
    }

    // 上传成功处理
    const handleUploadSuccess = (response) => {
      if (response.code === 200) {
        ElMessage.success('上传成功')
        loadMaterials()
        // 如果是视频背景，自动设置为当前背景
        if (activeTab.value === 'video') {
          store.commit('SET_BACKGROUND', response.data.url)
        }
      } else {
        ElMessage.error(response.msg || '上传失败')
      }
    }

    // 上传失败处理
    const handleUploadError = (error) => {
      console.error('上传失败:', error)
      ElMessage.error('上传失败: ' + (error.message || '未知错误'))
    }

    // 使用为背景
    const useAsBackground = (item) => {
      store.commit('SET_BACKGROUND', item.url)
      ElMessage.success('背景已更新')
    }

    // 删除素材
    const deleteMaterial = async (item) => {
      try {
        const response = await fetch(`${baseURL}/api/materials/${item.id}`, {
          method: 'DELETE'
        })
        const data = await response.json()
        if (data.code === 200) {
          ElMessage.success('删除成功')
          loadMaterials()
        } else {
          ElMessage.error(data.msg || '删除失败')
        }
      } catch (error) {
        console.error('删除素材失败:', error)
        ElMessage.error('删除素材失败')
      }
    }

    // 视频预览控制
    const playVideo = (event) => {
      const video = event.target
      video.play()
    }

    const pauseVideo = (event) => {
      const video = event.target
      video.pause()
    }

    onMounted(() => {
      loadMaterials()
    })

    return {
      activeTab,
      imageList,
      videoList,
      uploadUrl,
      beforeUpload,
      handleUploadSuccess,
      handleUploadError,
      useAsBackground,
      deleteMaterial,
      playVideo,
      pauseVideo
    }
  }
}
</script>

<style lang="scss" scoped>
.material-manager {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);

  .material-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      margin: 0;
      font-size: 18px;
      color: #333;
    }

    .material-tabs {
      .tab {
        padding: 8px 16px;
        margin-left: 8px;
        cursor: pointer;
        border-radius: 4px;
        transition: all 0.3s ease;

        &:hover {
          background: #f5f7fa;
        }

        &.active {
          background: #409eff;
          color: #fff;
        }
      }
    }
  }

  .upload-section {
    margin-bottom: 20px;
    padding: 20px;
    background: #f5f7fa;
    border-radius: 4px;
    text-align: center;
  }

  .materials-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    
    .material-item {
      .material-preview {
        position: relative;
        width: 100%;
        height: 150px;
        border-radius: 4px;
        overflow: hidden;
        background: #f5f7fa;

        img, video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .material-actions {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 8px;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          gap: 8px;
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        &:hover .material-actions {
          opacity: 1;
        }
      }

      .material-name {
        display: block;
        margin-top: 8px;
        font-size: 14px;
        color: #666;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style> 