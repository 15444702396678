<template>
  <div class="voice-manager">
    <div class="header">
      <h3>语音管理</h3>
      <el-button type="primary" @click="showUploadDialog = true">
        <el-icon><Plus /></el-icon>
        新建音色
      </el-button>
    </div>

    <div class="voice-list">
      <!-- PC端表格布局 -->
      <el-table 
        :data="voiceList" 
        style="width: 100%"
        class="desktop-table"
        v-show="!isMobile"
      >
        <el-table-column prop="id" label="ID" width="120">
          <template #default="scope">
            <el-tag size="small">{{ scope.row.id }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" width="180" />
        <el-table-column prop="description" label="描述" show-overflow-tooltip />
        <el-table-column prop="createTime" label="创建时间" width="180">
          <template #default="scope">
            {{ formatDate(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" fixed="right">
          <template #default="scope">
            <el-button-group>
              <el-button 
                type="primary" 
                :icon="VideoPlay"
                size="small"
                @click="handlePreview(scope.row)"
                title="试听"
              >
                试听
              </el-button>
              <el-button 
                type="danger" 
                :icon="Delete"
                size="small"
                @click="handleDelete(scope.row)"
                title="删除"
              >
                删除
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <!-- 移动端卡片布局 -->
      <div class="mobile-cards" v-show="isMobile">
        <el-card 
          v-for="voice in voiceList" 
          :key="voice.id" 
          class="voice-card"
          shadow="hover"
        >
          <div class="card-content">
            <div class="main-info">
              <div class="title-row">
                <h4>{{ voice.name }}</h4>
                <div class="card-actions">
                  <el-button
                    circle
                    type="primary"
                    :icon="VideoPlay"
                    @click="handlePreview(voice)"
                  />
                  <el-button
                    circle
                    type="danger"
                    :icon="Delete"
                    @click="handleDelete(voice)"
                  />
                </div>
              </div>
              <el-tag size="small" class="voice-id" type="info" effect="plain">{{ voice.id }}</el-tag>
              <p class="description">{{ voice.description }}</p>
            </div>
            <div class="create-time">
              <el-icon><Clock /></el-icon>
              {{ formatDate(voice.createTime) }}
            </div>
          </div>
        </el-card>
      </div>

      <div class="empty-block" v-if="!voiceList.length">
        <el-empty description="暂无语音数据">
          <el-button type="primary" @click="showUploadDialog = true">
            新建音色
          </el-button>
        </el-empty>
      </div>
    </div>

    <!-- 上传克隆对话框 -->
    <el-dialog
      v-model="showUploadDialog"
      title="新建音色"
      :width="isMobile ? '90%' : '500px'"
      :top="isMobile ? '10vh' : '15vh'"
      :close-on-click-modal="false"
      class="voice-dialog"
    >
      <el-form 
        ref="formRef"
        :model="uploadForm" 
        :rules="rules" 
        label-width="80px"
      >
        <el-form-item label="语音名称" prop="name">
          <el-input 
            v-model="uploadForm.name" 
            placeholder="请输入语音名称"
            maxlength="20"
            show-word-limit
            clearable
          />
        </el-form-item>
        <el-form-item label="语音描述" prop="description">
          <el-input
            v-model="uploadForm.description"
            type="textarea"
            :rows="3"
            placeholder="请输入语音描述"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="语音文件" prop="file">
          <div class="voice-input-methods">
            <div class="method-buttons">
              <el-button 
                type="primary" 
                :class="{ active: inputMethod === 'upload' }"
                @click="inputMethod = 'upload'"
              >
                上传音频
              </el-button>
              <el-button 
                type="primary" 
                :class="{ active: inputMethod === 'record' }"
                @click="inputMethod = 'record'"
              >
                录制音频
              </el-button>
            </div>

            <!-- 上传音频区域 -->
            <div v-show="inputMethod === 'upload'" class="upload-area">
              <el-upload
                class="voice-uploader"
                :auto-upload="false"
                :show-file-list="true"
                :limit="1"
                accept=".wav,.mp3"
                :on-change="handleFileChange"
                :on-exceed="handleExceed"
                :on-remove="handleRemove"
              >
                <template #trigger>
                  <el-button type="primary">选择文件</el-button>
                </template>
                <template #tip>
                  <div class="el-upload__tip">
                    支持 wav、mp3 格式音频文件，上传后可以剪切音频片段
                  </div>
                </template>
              </el-upload>
            </div>

            <!-- 录音区域 -->
            <div v-show="inputMethod === 'record'" class="record-area">
              <div class="record-controls">
                <el-button
                  :type="isRecording ? 'danger' : 'primary'"
                  :icon="isRecording ? Delete : Microphone"
                  @click="handleRecordClick"
                  :loading="isRequestingPermission"
                >
                  {{ isRecording ? '停止录音' : '开始录音' }}
                </el-button>
                <span v-if="isRecording" class="recording-time">
                  录音时长: {{ formatDuration(recordingDuration) }}
                </span>
              </div>
              <div class="record-status" v-if="recordingStatus">
                {{ recordingStatus }}
              </div>
            </div>
          </div>
        </el-form-item>

        <!-- 音频剪切器 -->
        <div v-if="audioBuffer" class="audio-trimmer">
          <div class="waveform-container" ref="waveformContainer">
            <canvas ref="waveformCanvas" class="waveform"></canvas>
            <div class="selection-overlay">
              <div 
                class="selection-handle left"
                :style="{ left: startHandlePosition + 'px' }"
                @mousedown="startDragging('start', $event)"
                @touchstart.prevent="startTouchDragging('start', $event)"
              ></div>
              <div 
                class="selection-region"
                :style="{ 
                  left: startHandlePosition + 'px',
                  width: (endHandlePosition - startHandlePosition) + 'px'
                }"
                @mousedown="startDragging('region', $event)"
                @touchstart.prevent="startTouchDragging('region', $event)"
              >
                <div 
                  v-if="isPlaying" 
                  class="playback-indicator"
                  :style="{ left: playbackProgress + '%' }"
                ></div>
              </div>
              <div 
                class="selection-handle right"
                :style="{ left: endHandlePosition + 'px' }"
                @mousedown="startDragging('end', $event)"
                @touchstart.prevent="startTouchDragging('end', $event)"
              ></div>
            </div>
          </div>
          <div class="audio-controls">
            <el-button 
              type="primary" 
              :icon="VideoPlay"
              @click="playSelection"
              :disabled="isPlaying"
            >
              试听片段
            </el-button>
            <span class="time-info">
              已选择: {{ formatTime(selectionStart) }} - {{ formatTime(selectionEnd) }}
              ({{ formatDuration(selectionEnd - selectionStart) }})
            </span>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelUpload">取消</el-button>
          <el-button
            type="primary"
            :loading="uploading"
            @click="handleUpload"
          >
            {{ uploading ? '上传中...' : '开始上传' }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 试听对话框 -->
    <el-dialog
      v-model="showPreviewDialog"
      title="语音试听"
      width="400px"
      :close-on-click-modal="false"
    >
      <div class="preview-content">
        <div class="voice-info">
          <h4>{{ previewVoice?.name }}</h4>
          <p>{{ previewVoice?.description }}</p>
        </div>
        <div class="preview-text">
          <el-input
            v-model="previewText"
            type="textarea"
            :rows="3"
            placeholder="输入要试听的文本"
          />
          <div class="audio-progress" v-if="isPlaying">
            <el-progress 
              :percentage="audioProgress" 
              :format="formatProgress"
              :stroke-width="4"
              status="success"
            />
          </div>
          <el-button 
            type="primary" 
            :disabled="!previewText.trim()"
            :loading="previewing"
            @click="handlePreviewPlay"
          >
            {{ isPlaying ? '播放中...' : '试听' }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus, Delete, VideoPlay, Clock, Microphone } from '@element-plus/icons-vue'
import { avatarAPI } from '@/api'
import emitter from '@/utils/eventBus'

export default {
  name: 'VoiceManager',
  components: {
    Plus,
    Delete,
    VideoPlay,
    Clock,
    Microphone
  },
  setup() {
    const voiceList = ref([])
    const showUploadDialog = ref(false)
    const showPreviewDialog = ref(false)
    const uploading = ref(false)
    const previewing = ref(false)
    const formRef = ref(null)
    const uploadForm = ref({
      name: '',
      description: '',
      file: null
    })
    const previewVoice = ref(null)
    const previewText = ref('')
    const audioContext = ref(null)
    const audioSource = ref(null)
    const isPlaying = ref(false)
    const audioBuffer = ref(null)
    const waveformCanvas = ref(null)
    const waveformContainer = ref(null)
    const startHandlePosition = ref(0)
    const endHandlePosition = ref(0)
    const selectionStart = ref(0)
    const selectionEnd = ref(5) // 默认选择5秒
    const isDragging = ref(false)
    const dragTarget = ref(null)
    const dragStartX = ref(0)
    const dragStartPosition = ref(0)
    const playbackProgress = ref(0)
    const playbackAnimationFrame = ref(null)
    const audioProgress = ref(0)
    const audioStartTime = ref(0)
    const audioDuration = ref(0)
    const progressTimer = ref(null)
    const isMobile = ref(window.innerWidth <= 768)

    // 录音相关状态
    const inputMethod = ref('upload')
    const isRecording = ref(false)
    const isRequestingPermission = ref(false)
    const recordingStatus = ref('')
    const mediaRecorder = ref(null)
    const recordedChunks = ref([])
    const recordingStartTime = ref(null)
    const recordingDuration = ref(0)
    const recordingTimer = ref(null)

    const rules = {
      name: [
        { required: true, message: '请输入语音名称', trigger: 'blur' },
        { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
      ],
      description: [
        { required: true, message: '请输入语音描述', trigger: 'blur' },
        { min: 2, max: 200, message: '长度在 2 到 200 个字符', trigger: 'blur' }
      ],
      file: [
        { required: true, message: '请选择语音文件', trigger: 'change' }
      ]
    }

    // 加载语音列表
    const loadVoiceList = async () => {
      try {
        const response = await avatarAPI.getVoiceList()
        console.log('获取语音列表响应:', response)
        
        if (response?.data?.code === 0 && Array.isArray(response.data.data)) {
          voiceList.value = response.data.data.map(voice => ({
            ...voice,
            isEnabled: voice.isEnabled !== false,
            createTime: voice.createTime || new Date().toISOString()
          }))
          console.log('处理后的语音列表:', voiceList.value)
        } else {
          console.error('响应数据格式错误:', response)
          throw new Error('获取语音列表失败：响应数据格式错误')
        }
      } catch (error) {
        console.error('加载语音列表失败:', error)
        ElMessage.error(error.message || '获取语音列表失败')
      }
    }

    // 绘制波形图
    const drawWaveform = (buffer) => {
      const canvas = waveformCanvas.value
      const ctx = canvas.getContext('2d')
      const width = canvas.width
      const height = canvas.height
      const data = buffer.getChannelData(0)
      const step = Math.ceil(data.length / width)
      const amp = height / 2

      ctx.clearRect(0, 0, width, height)
      ctx.beginPath()
      ctx.moveTo(0, amp)

      for (let i = 0; i < width; i++) {
        let min = 1.0
        let max = -1.0
        for (let j = 0; j < step; j++) {
          const datum = data[(i * step) + j]
          if (datum < min) min = datum
          if (datum > max) max = datum
        }
        ctx.lineTo(i, (1 + min) * amp)
        ctx.lineTo(i, (1 + max) * amp)
      }

      ctx.strokeStyle = '#409EFF'
      ctx.stroke()
    }

    // 开始拖动
    const startDragging = (target, event) => {
      isDragging.value = true
      dragTarget.value = target
      dragStartX.value = event.clientX

      if (target === 'region') {
        // 计算鼠标点击位置相对于选择区域左边界的偏移量
        const regionRect = event.currentTarget.getBoundingClientRect()
        const clickOffset = event.clientX - regionRect.left
        dragStartPosition.value = startHandlePosition.value
        dragStartX.value = event.clientX - clickOffset // 调整起始位置，考虑点击偏移
      } else {
        dragStartPosition.value = target === 'start' ? startHandlePosition.value : endHandlePosition.value
      }

      document.addEventListener('mousemove', handleDrag)
      document.addEventListener('mouseup', stopDragging)
    }

    // 处理拖动
    const handleDrag = (event) => {
      if (!isDragging.value) return

      const delta = event.clientX - dragStartX.value
      const containerWidth = waveformContainer.value.offsetWidth
      const selectionWidth = endHandlePosition.value - startHandlePosition.value

      if (dragTarget.value === 'start') {
        startHandlePosition.value = Math.max(0, Math.min(endHandlePosition.value - 10, dragStartPosition.value + delta))
        selectionStart.value = (startHandlePosition.value / containerWidth) * audioBuffer.value.duration
      } else if (dragTarget.value === 'end') {
        endHandlePosition.value = Math.max(startHandlePosition.value + 10, Math.min(containerWidth, dragStartPosition.value + delta))
        selectionEnd.value = (endHandlePosition.value / containerWidth) * audioBuffer.value.duration
      } else if (dragTarget.value === 'region') {
        // 计算新的起始位置，确保不超出容器边界
        let newStart = Math.max(0, Math.min(containerWidth - selectionWidth, dragStartPosition.value + delta))
        let newEnd = newStart + selectionWidth

        // 更新位置
        startHandlePosition.value = newStart
        endHandlePosition.value = newEnd
        
        // 更新选择时间
        selectionStart.value = (newStart / containerWidth) * audioBuffer.value.duration
        selectionEnd.value = (newEnd / containerWidth) * audioBuffer.value.duration
      }
    }

    // 停止拖动
    const stopDragging = () => {
      isDragging.value = false
      document.removeEventListener('mousemove', handleDrag)
      document.removeEventListener('mouseup', stopDragging)
    }

    // 更新播放进度
    const updatePlaybackProgress = (startTime, duration) => {
      const updateProgress = () => {
        const currentTime = (Date.now() - startTime) / 1000
        const progress = (currentTime / duration) * 100
        
        if (currentTime <= duration) {
          playbackProgress.value = progress
          playbackAnimationFrame.value = requestAnimationFrame(updateProgress)
        } else {
          playbackProgress.value = 0
          cancelAnimationFrame(playbackAnimationFrame.value)
        }
      }
      
      playbackAnimationFrame.value = requestAnimationFrame(updateProgress)
    }

    // 修改播放选中片段函数
    const playSelection = async () => {
      if (!audioBuffer.value || isPlaying.value) return

      initAudioContext()
      const source = audioContext.value.createBufferSource()
      source.buffer = audioBuffer.value
      source.connect(audioContext.value.destination)
      
      const duration = selectionEnd.value - selectionStart.value
      const startTime = Date.now()
      
      isPlaying.value = true
      playbackProgress.value = 0
      
      source.start(0, selectionStart.value, duration)
      updatePlaybackProgress(startTime, duration)
      
      source.onended = () => {
        isPlaying.value = false
        playbackProgress.value = 0
        cancelAnimationFrame(playbackAnimationFrame.value)
      }
    }

    // 格式化时间
    const formatTime = (seconds) => {
      const mins = Math.floor(seconds / 60)
      const secs = Math.floor(seconds % 60)
      return `${mins}:${secs.toString().padStart(2, '0')}`
    }

    // 格式化持续时间
    const formatDuration = (seconds) => {
      return `${seconds.toFixed(1)}秒`
    }

    // 处理文件选择
    const handleFileChange = async (file, isRecordedFile = false) => {
      const isAudio = file.raw.type.startsWith('audio/')
      const isLt100M = file.size / 1024 / 1024 < 100

      // 只对上传的文件进行类型和大小检查，录音文件跳过
      if (!isRecordedFile) {
        if (!isAudio) {
          ElMessage.error('只能上传音频文件!')
          return false
        }
        if (!isLt100M) {
          ElMessage.error('音频文件大小不能超过 100MB!')
          return false
        }
      }

      uploadForm.value.file = file.raw

      // 加载音频并显示波形
      try {
        const arrayBuffer = await file.raw.arrayBuffer()
        audioContext.value = new (window.AudioContext || window.webkitAudioContext)()
        audioBuffer.value = await audioContext.value.decodeAudioData(arrayBuffer)

        // 等待下一个渲染周期，确保DOM元素已挂载
        await nextTick()
        
        // 检查DOM元素是否存在
        if (!waveformContainer.value || !waveformCanvas.value) {
          throw new Error('波形图容器未准备好')
        }

        // 设置画布尺寸
        const canvas = waveformCanvas.value
        const containerWidth = waveformContainer.value.offsetWidth || 400 // 提供默认宽度
        canvas.width = containerWidth
        canvas.height = 100

        // 绘制波形
        drawWaveform(audioBuffer.value)

        // 初始化选择区域
        startHandlePosition.value = 0
        endHandlePosition.value = Math.min(containerWidth, (5 / audioBuffer.value.duration) * containerWidth)
        selectionStart.value = 0
        selectionEnd.value = Math.min(5, audioBuffer.value.duration)
      } catch (error) {
        console.error('加载音频失败:', error)
        ElMessage.error('加载音频失败：' + (error.message || '未知错误'))
        // 清理资源
        handleRemove()
      }
    }

    // 处理文件移除
    const handleRemove = () => {
      uploadForm.value.file = null
      audioBuffer.value = null
      if (audioContext.value) {
        audioContext.value.close()
        audioContext.value = null
      }
    }

    // 处理超出文件数限制
    const handleExceed = () => {
      ElMessage.warning('只能上传一个音频文件')
    }

    // 取消上传
    const cancelUpload = () => {
      uploadForm.value = {
        name: '',
        description: '',
        file: null
      }
      cleanupRecording()
      showUploadDialog.value = false
    }

    // 处理上传
    const handleUpload = async () => {
      if (!formRef.value) return

      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      if (!uploadForm.value.file || !audioBuffer.value) {
        ElMessage.warning('请先选择音频文件')
        return
      }

      uploading.value = true
      try {
        // 创建新的 AudioContext 来处理音频剪切
        const ctx = new (window.AudioContext || window.webkitAudioContext)()
        
        // 计算选中部分的采样点
        const startSample = Math.floor(selectionStart.value * audioBuffer.value.sampleRate)
        const endSample = Math.floor(selectionEnd.value * audioBuffer.value.sampleRate)
        
        // 创建临时缓冲区存储选中的音频片段
        const tempBuffer = ctx.createBuffer(
          1,  // 单声道
          endSample - startSample,
          audioBuffer.value.sampleRate
        )
        
        // 复制选中部分的音频数据
        const inputData = audioBuffer.value.getChannelData(0)
        const tempData = tempBuffer.getChannelData(0)
        for (let i = 0; i < tempData.length; i++) {
          tempData[i] = inputData[startSample + i]
        }
        
        // 将音频片段转换为WAV文件
        const wavBlob = await audioBufferToWav(tempBuffer)
        const trimmedFile = new File([wavBlob], 'trimmed_audio.wav', { type: 'audio/wav' })

        // 创建表单数据
        const formData = new FormData()
        formData.append('file', trimmedFile)
        formData.append('name', uploadForm.value.name.trim())
        formData.append('description', uploadForm.value.description.trim())

        const response = await avatarAPI.uploadVoice(formData)
        
        if (response.data?.code === 0) {
          ElMessage.success('上传成功')
          showUploadDialog.value = false
          uploadForm.value = {
            name: '',
            description: '',
            file: null
          }
          audioBuffer.value = null
          await loadVoiceList()
          // 通知其他组件刷新语音列表
          emitter.emit('voice-list-updated')
        } else {
          throw new Error(response.data?.msg || '上传失败')
        }
      } catch (error) {
        console.error('上传失败:', error)
        ElMessage.error(error.message || '上传失败')
      } finally {
        uploading.value = false
      }
    }

    // 将 AudioBuffer 转换为 WAV 文件
    const audioBufferToWav = (buffer) => {
      const numberOfChannels = 1
      const sampleRate = buffer.sampleRate
      const format = 1 // PCM
      const bitDepth = 16 // 16位整数格式
      const bytesPerSample = bitDepth / 8
      const blockAlign = numberOfChannels * bytesPerSample
      const byteRate = sampleRate * blockAlign
      const dataSize = buffer.length * blockAlign
      const headerSize = 44
      const totalSize = headerSize + dataSize
      const arrayBuffer = new ArrayBuffer(totalSize)
      const view = new DataView(arrayBuffer)

      // WAV 文件头
      writeString(view, 0, 'RIFF')
      view.setUint32(4, totalSize - 8, true)
      writeString(view, 8, 'WAVE')
      writeString(view, 12, 'fmt ')
      view.setUint32(16, 16, true)
      view.setUint16(20, format, true)
      view.setUint16(22, numberOfChannels, true)
      view.setUint32(24, sampleRate, true)
      view.setUint32(28, byteRate, true)
      view.setUint16(32, blockAlign, true)
      view.setUint16(34, bitDepth, true)
      writeString(view, 36, 'data')
      view.setUint32(40, dataSize, true)

      // 写入音频数据 - 将浮点数转换为16位整数
      const data = buffer.getChannelData(0)
      let offset = 44
      for (let i = 0; i < data.length; i++) {
        const sample = Math.max(-1, Math.min(1, data[i]))
        view.setInt16(offset, sample * 32767, true)
        offset += 2
      }

      return new Blob([arrayBuffer], { type: 'audio/wav' })
    }

    // 辅助函数：写入字符串到 DataView
    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i))
      }
    }

    // 处理试听
    const handlePreview = (voice) => {
      previewVoice.value = voice
      previewText.value = ''
      showPreviewDialog.value = true
    }

    // 初始化音频上下文
    const initAudioContext = () => {
      if (!audioContext.value) {
        audioContext.value = new (window.AudioContext || window.webkitAudioContext)()
      }
    }

    // 停止当前播放
    const stopCurrentAudio = () => {
      if (audioSource.value) {
        try {
          audioSource.value.stop()
          audioSource.value.disconnect()
        } catch (error) {
          console.error('停止音频失败:', error)
        }
        audioSource.value = null
      }
      isPlaying.value = false
      audioProgress.value = 0
      if (progressTimer.value) {
        cancelAnimationFrame(progressTimer.value)
        progressTimer.value = null
      }
    }

    // 格式化进度
    const formatProgress = (percentage) => {
      if (!audioDuration.value) return '0:00'
      const currentTime = (percentage / 100) * audioDuration.value
      return formatTime(currentTime)
    }

    // 更新音频进度
    const updateAudioProgress = () => {
      if (!isPlaying.value || !audioStartTime.value || !audioDuration.value) return
      
      const currentTime = (Date.now() - audioStartTime.value) / 1000
      if (currentTime >= audioDuration.value) {
        audioProgress.value = 100
        isPlaying.value = false
        cancelAnimationFrame(progressTimer.value)
        return
      }
      
      audioProgress.value = (currentTime / audioDuration.value) * 100
      progressTimer.value = requestAnimationFrame(updateAudioProgress)
    }

    // 处理试听播放
    const handlePreviewPlay = async () => {
      if (!previewText.value.trim()) return
      
      previewing.value = true
      try {
        initAudioContext()
        
        // 停止当前播放的音频
        stopCurrentAudio()

        const response = await fetch(`${process.env.VUE_APP_SOCKET_URL || 'http://localhost:3000'}/api/voices/preview`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            voiceId: previewVoice.value.id,
            text: previewText.value.trim()
          })
        })

        if (!response.ok) {
          throw new Error('试听请求失败')
        }

        await processAudioStream(response)
      } catch (error) {
        console.error('试听失败:', error)
        ElMessage.error(error.message || '试听失败')
      } finally {
        previewing.value = false
      }
    }

    // 处理音频流
    const processAudioStream = async (response) => {
      const reader = response.body.getReader()
      const sampleRate = 22050
      let audioBufferQueue = []
      let leftover = new Uint8Array(0)
      let totalDuration = 0

      const processBuffer = () => {
        if (isPlaying.value || !audioBufferQueue.length) return

        const tmpBufQueue = audioBufferQueue
        audioBufferQueue = []
        const totalLength = tmpBufQueue.reduce((acc, chunk) => acc + chunk.length, 0)
        const audioBuffer = audioContext.value.createBuffer(1, totalLength, sampleRate)
        const combinedArray = new Float32Array(totalLength)

        let offset = 0
        while (tmpBufQueue.length) {
          const chunk = tmpBufQueue.shift()
          combinedArray.set(chunk, offset)
          offset += chunk.length
        }

        audioBuffer.copyToChannel(combinedArray, 0)

        if (audioSource.value) {
          stopCurrentAudio()
        }

        audioSource.value = audioContext.value.createBufferSource()
        audioSource.value.buffer = audioBuffer
        audioSource.value.connect(audioContext.value.destination)
        
        // 计算音频时长
        audioDuration.value = audioBuffer.duration
        audioStartTime.value = Date.now()
        audioProgress.value = 0
        
        audioSource.value.onended = () => {
          isPlaying.value = false
          audioProgress.value = 100
          if (audioBufferQueue.length > 0) {
            processBuffer()
          }
          if (progressTimer.value) {
            cancelAnimationFrame(progressTimer.value)
          }
        }
        
        audioSource.value.start()
        isPlaying.value = true
        updateAudioProgress()
      }

      try {
        while (true) {
          const { done, value } = await reader.read()
          if (done) break

          // 合并剩余数据和新数据
          const combinedValue = new Uint8Array(leftover.length + value.length)
          combinedValue.set(leftover)
          combinedValue.set(value, leftover.length)

          const byteLength = combinedValue.byteLength
          const remainder = byteLength % 4
          const validLength = byteLength - remainder

          // 分离有效数据和剩余数据
          const validData = combinedValue.slice(0, validLength)
          leftover = combinedValue.slice(validLength)

          const float32Array = new Float32Array(validData.buffer)
          audioBufferQueue.push(float32Array)

          processBuffer()
        }
      } catch (error) {
        console.error('处理音频流失败:', error)
        ElMessage.error('音频播放失败')
      }
    }

    // 处理删除
    const handleDelete = async (voice) => {
      try {
        await ElMessageBox.confirm(
          `确定要删除语音 "${voice.name}" 吗？删除后无法恢复。`,
          '删除确认',
          {
            confirmButtonText: '确定删除',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false,
            closeOnPressEscape: false
          }
        )
        
        const response = await avatarAPI.deleteVoice(voice.id)
        if (response.data?.code === 0) {
          ElMessage.success('删除成功')
          await loadVoiceList()
          // 通知其他组件刷新语音列表
          emitter.emit('voice-list-updated')
        } else {
          throw new Error(response.data?.msg || '删除失败')
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除失败:', error)
          ElMessage.error(error.message || '删除失败')
        }
      }
    }

    // 格式化日期
    const formatDate = (date) => {
      if (!date) return ''
      return new Date(date).toLocaleString()
    }

    // 监听窗口大小变化
    const handleResize = () => {
      isMobile.value = window.innerWidth <= 768
    }

    // 开始触摸拖动
    const startTouchDragging = (target, event) => {
      isDragging.value = true
      dragTarget.value = target
      const touch = event.touches[0]
      dragStartX.value = touch.clientX

      if (target === 'region') {
        // 计算触摸位置相对于选择区域左边界的偏移量
        const regionRect = event.currentTarget.getBoundingClientRect()
        const touchOffset = touch.clientX - regionRect.left
        dragStartPosition.value = startHandlePosition.value
        dragStartX.value = touch.clientX - touchOffset
      } else {
        dragStartPosition.value = target === 'start' ? startHandlePosition.value : endHandlePosition.value
      }

      document.addEventListener('touchmove', handleTouchDrag, { passive: false })
      document.addEventListener('touchend', stopTouchDragging)
      document.addEventListener('touchcancel', stopTouchDragging)
    }

    // 处理触摸拖动
    const handleTouchDrag = (event) => {
      event.preventDefault() // 防止页面滚动
      if (!isDragging.value) return

      const touch = event.touches[0]
      const delta = touch.clientX - dragStartX.value
      const containerWidth = waveformContainer.value.offsetWidth
      const selectionWidth = endHandlePosition.value - startHandlePosition.value

      if (dragTarget.value === 'start') {
        startHandlePosition.value = Math.max(0, Math.min(endHandlePosition.value - 10, dragStartPosition.value + delta))
        selectionStart.value = (startHandlePosition.value / containerWidth) * audioBuffer.value.duration
      } else if (dragTarget.value === 'end') {
        endHandlePosition.value = Math.max(startHandlePosition.value + 10, Math.min(containerWidth, dragStartPosition.value + delta))
        selectionEnd.value = (endHandlePosition.value / containerWidth) * audioBuffer.value.duration
      } else if (dragTarget.value === 'region') {
        let newStart = Math.max(0, Math.min(containerWidth - selectionWidth, dragStartPosition.value + delta))
        let newEnd = newStart + selectionWidth

        startHandlePosition.value = newStart
        endHandlePosition.value = newEnd
        
        selectionStart.value = (newStart / containerWidth) * audioBuffer.value.duration
        selectionEnd.value = (newEnd / containerWidth) * audioBuffer.value.duration
      }
    }

    // 停止触摸拖动
    const stopTouchDragging = () => {
      isDragging.value = false
      document.removeEventListener('touchmove', handleTouchDrag)
      document.removeEventListener('touchend', stopTouchDragging)
      document.removeEventListener('touchcancel', stopTouchDragging)
    }

    // 开始/停止录音
    const handleRecordClick = async () => {
      if (isRecording.value) {
        stopRecording()
      } else {
        await startRecording()
      }
    }

    // 开始录音
    const startRecording = async () => {
      try {
        isRequestingPermission.value = true
        recordingStatus.value = '正在请求麦克风权限...'
        
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        mediaRecorder.value = new MediaRecorder(stream, {
          mimeType: 'audio/webm'
        })
        
        recordedChunks.value = []
        
        mediaRecorder.value.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunks.value.push(event.data)
          }
        }
        
        mediaRecorder.value.onstop = async () => {
          const blob = new Blob(recordedChunks.value, { type: 'audio/webm' })
          const audioFile = new File([blob], 'recorded_audio.webm', { type: 'audio/webm' })
          
          // 处理录音文件，传入 isRecordedFile = true
          await handleFileChange({ raw: audioFile }, true)
          
          // 停止所有轨道
          stream.getTracks().forEach(track => track.stop())
          
          recordingStatus.value = '录音已完成，可以试听和调整'
        }
        
        mediaRecorder.value.start(1000) // 每秒收集一次数据
        isRecording.value = true
        recordingStartTime.value = Date.now()
        recordingStatus.value = '正在录音...'
        
        // 更新录音时长
        recordingTimer.value = setInterval(() => {
          recordingDuration.value = (Date.now() - recordingStartTime.value) / 1000
        }, 100)
        
      } catch (error) {
        console.error('录音失败:', error)
        recordingStatus.value = '录音失败: ' + error.message
        ElMessage.error('无法访问麦克风')
      } finally {
        isRequestingPermission.value = false
      }
    }

    // 停止录音
    const stopRecording = () => {
      if (mediaRecorder.value && mediaRecorder.value.state !== 'inactive') {
        mediaRecorder.value.stop()
        isRecording.value = false
        clearInterval(recordingTimer.value)
        recordingTimer.value = null
      }
    }

    // 清理录音资源
    const cleanupRecording = () => {
      if (mediaRecorder.value) {
        if (mediaRecorder.value.state !== 'inactive') {
          mediaRecorder.value.stop()
        }
        mediaRecorder.value = null
      }
      if (recordingTimer.value) {
        clearInterval(recordingTimer.value)
        recordingTimer.value = null
      }
      isRecording.value = false
      recordingStatus.value = ''
      recordingDuration.value = 0
      recordedChunks.value = []
    }

    onMounted(() => {
      loadVoiceList()
      window.addEventListener('resize', handleResize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
      stopCurrentAudio()
      if (audioContext.value) {
        audioContext.value.close()
        audioContext.value = null
      }
      if (progressTimer.value) {
        cancelAnimationFrame(progressTimer.value)
      }
      cleanupRecording()
    })

    return {
      voiceList,
      showUploadDialog,
      showPreviewDialog,
      uploading,
      previewing,
      formRef,
      uploadForm,
      previewVoice,
      previewText,
      audioContext,
      audioSource,
      isPlaying,
      rules,
      handleFileChange,
      handleRemove,
      handleExceed,
      cancelUpload,
      handleUpload,
      handlePreview,
      handlePreviewPlay,
      handleDelete,
      formatDate,
      Delete,
      VideoPlay,
      audioBuffer,
      waveformCanvas,
      waveformContainer,
      startHandlePosition,
      endHandlePosition,
      selectionStart,
      selectionEnd,
      playSelection,
      formatTime,
      formatDuration,
      startDragging,
      playbackProgress,
      audioProgress,
      formatProgress,
      isMobile,
      startTouchDragging,
      handleTouchDrag,
      stopTouchDragging,
      inputMethod,
      isRecording,
      isRequestingPermission,
      recordingStatus,
      recordingDuration,
      handleRecordClick,
      Microphone
    }
  }
}
</script>

<style lang="scss" scoped>
.voice-manager {
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
      gap: 10px;
      
      .el-button {
        width: 100%;
      }
    }

    h3 {
      margin: 0;
      font-size: 18px;
      color: var(--el-text-color-primary);
    }
  }

  .voice-list {
    background: var(--el-bg-color);
    border-radius: 8px;
    padding: 20px;
    box-shadow: var(--el-box-shadow-light);

    @media screen and (max-width: 768px) {
      padding: 10px;
      background: transparent;
      box-shadow: none;
    }

    .mobile-cards {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .voice-card {
        background: var(--el-bg-color);
        border: 1px solid var(--el-border-color-lighter);

        :deep(.el-card__body) {
          padding: 12px;
        }

        .card-content {
          .main-info {
            margin-bottom: 8px;

            .title-row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 8px;
              gap: 8px;

              h4 {
                margin: 0;
                font-size: 16px;
                color: var(--el-text-color-primary);
                font-weight: 500;
              }

              .card-actions {
                display: flex;
                gap: 8px;
                flex-shrink: 0;

                .el-button {
                  padding: 8px;
                  
                  :deep(.el-icon) {
                    font-size: 18px;
                  }
                }
              }
            }

            .voice-id {
              display: inline-block;
              margin-bottom: 8px;
              font-size: 12px;
              background: var(--el-color-primary-light-9);
              color: var(--el-color-primary);
              border: none;
            }

            .description {
              margin: 0;
              font-size: 14px;
              color: var(--el-text-color-regular);
              line-height: 1.4;
            }
          }

          .create-time {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 12px;
            color: var(--el-text-color-secondary);
            padding-top: 8px;
            border-top: 1px solid var(--el-border-color-lighter);

            .el-icon {
              font-size: 14px;
            }
          }
        }
      }
    }

    .empty-block {
      padding: 40px 0;

      @media screen and (max-width: 768px) {
        padding: 20px 0;
        
        .el-button {
          width: 100%;
        }
      }
    }
  }

  .voice-uploader {
    :deep(.el-upload) {
      width: 100%;
    }

    :deep(.el-upload-list) {
      width: 100%;
    }
  }

  .el-upload__tip {
    color: var(--el-text-color-secondary);
    font-size: 12px;
    margin-top: 8px;
  }

  .preview-content {
    .voice-info {
      margin-bottom: 20px;
      
      h4 {
        margin: 0 0 8px;
        color: var(--el-text-color-primary);
      }
      
      p {
        margin: 0;
        color: var(--el-text-color-secondary);
        font-size: 14px;
      }
    }

    .preview-text {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .audio-progress {
        margin: 10px 0;
      }

      .el-button {
        align-self: flex-end;

        @media screen and (max-width: 768px) {
          align-self: stretch;
          width: 100%;
        }
      }
    }
  }

  .audio-trimmer {
    margin-top: 20px;
    padding: 15px;
    background: var(--el-fill-color-light);
    border-radius: 8px;

    @media screen and (max-width: 768px) {
      padding: 10px;
    }

    .waveform-container {
      position: relative;
      width: 100%;
      height: 100px;
      background: var(--el-bg-color);
      border-radius: 4px;
      overflow: hidden;

      .waveform {
        width: 100%;
        height: 100%;
      }

      .selection-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;

        .selection-handle {
          position: absolute;
          top: 0;
          width: 4px;
          height: 100%;
          background: var(--el-color-primary);
          cursor: ew-resize;
          pointer-events: auto;
          touch-action: none; // 防止触摸时的默认行为

          @media screen and (max-width: 768px) {
            width: 12px; // 增加手柄宽度，更容易触摸
          }

          &.left {
            border-radius: 4px 0 0 4px;
          }

          &.right {
            border-radius: 0 4px 4px 0;
          }

          &:hover {
            background: var(--el-color-primary-light-3);
          }
        }

        .selection-region {
          position: absolute;
          top: 0;
          height: 100%;
          background: rgba(64, 158, 255, 0.1);
          border-top: 2px solid var(--el-color-primary);
          border-bottom: 2px solid var(--el-color-primary);
          pointer-events: auto;
          cursor: move;
          touch-action: none; // 防止触摸时的默认行为
          
          @media screen and (max-width: 768px) {
            border-top-width: 3px;    // 增加边框宽度，更容易看到和触摸
            border-bottom-width: 3px;
          }

          .playback-indicator {
            position: absolute;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: var(--el-color-danger);
            transition: left 0.1s linear;
            pointer-events: none;
          }
        }
      }
    }

    .audio-controls {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 15px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
        gap: 10px;

        .el-button {
          width: 100%;
        }

        .time-info {
          text-align: center;
        }
      }

      .time-info {
        color: var(--el-text-color-secondary);
        font-size: 14px;
      }
    }
  }

  .voice-input-methods {
    .method-buttons {
      display: flex;
      gap: 10px;
      margin-bottom: 15px;

      .el-button {
        flex: 1;
        
        &.active {
          background-color: var(--el-color-primary);
          color: white;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    .record-area {
      background: var(--el-fill-color-light);
      border-radius: 8px;
      padding: 15px;

      .record-controls {
        display: flex;
        align-items: center;
        gap: 15px;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: stretch;
        }

        .el-button {
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        .recording-time {
          color: var(--el-color-danger);
          font-size: 14px;
        }
      }

      .record-status {
        margin-top: 10px;
        color: var(--el-text-color-secondary);
        font-size: 14px;
      }
    }
  }
}

.voice-dialog {
  @media screen and (max-width: 768px) {
    :deep(.el-dialog) {
      border-radius: 8px;
      overflow: hidden;
      margin: 0 auto;
    }

    :deep(.el-dialog__header) {
      padding: 16px;
      margin: 0;
      border-bottom: 1px solid var(--el-border-color-light);
      position: sticky;
      top: 0;
      background: var(--el-bg-color);
      z-index: 1;
    }

    :deep(.el-dialog__body) {
      padding: 16px;
      max-height: 60vh;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    :deep(.el-dialog__footer) {
      padding: 16px;
      border-top: 1px solid var(--el-border-color-light);
      position: sticky;
      bottom: 0;
      background: var(--el-bg-color);
      z-index: 1;
    }

    .dialog-footer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .el-button {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style> 