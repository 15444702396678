<template>
  <div class="avatar-list">
    <div class="avatar-grid">
      <div 
        v-for="avatar in avatars" 
        :key="avatar.id"
        class="avatar-item"
        :class="{ 'is-selected': selectedId === avatar.id }"
      >
        <div class="avatar-card" @click="handleSelect(avatar)">
          <div class="avatar-cover">
            <img :src="avatar.coverImg" :alt="avatar.name">
          </div>
          <div class="avatar-info">
            <h3 class="avatar-name">{{ avatar.name }}</h3>
            <p class="avatar-desc">{{ avatar.description }}</p>
          </div>
          <div class="avatar-actions">
            <el-button 
              type="primary" 
              link
              @click.stop="handleEdit(avatar)"
            >
              <el-icon><Edit /></el-icon>
              编辑
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { Edit } from '@element-plus/icons-vue'

export default {
  name: 'AvatarList',
  components: {
    Edit
  },
  props: {
    avatars: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue', 'edit'],
  setup(props, { emit }) {
    const selectedId = ref(props.modelValue)

    watch(() => props.modelValue, (val) => {
      selectedId.value = val
    })

    const handleSelect = (avatar) => {
      selectedId.value = avatar.id
      emit('update:modelValue', avatar.id)
    }

    const handleEdit = (avatar) => {
      emit('edit', avatar)
    }

    return {
      selectedId,
      handleSelect,
      handleEdit
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-list {
  .avatar-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
    padding: 20px;
  }

  .avatar-item {
    .avatar-card {
      position: relative;
      background: var(--el-bg-color);
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: var(--el-box-shadow-light);

      &:hover {
        transform: translateY(-2px);
        box-shadow: var(--el-box-shadow);

        .avatar-actions {
          opacity: 1;
        }
      }
    }

    &.is-selected .avatar-card {
      border: 2px solid var(--el-color-primary);
    }

    .avatar-cover {
      width: 100%;
      height: 160px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .avatar-info {
      padding: 12px;

      .avatar-name {
        margin: 0;
        font-size: 16px;
        color: var(--el-text-color-primary);
      }

      .avatar-desc {
        margin: 8px 0 0;
        font-size: 14px;
        color: var(--el-text-color-secondary);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .avatar-actions {
      position: absolute;
      top: 8px;
      right: 8px;
      opacity: 0;
      transition: opacity 0.3s ease;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 4px;
      padding: 4px;
    }
  }
}
</style> 