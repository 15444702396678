<template>
  <div class="control-tabs">
    <div class="tab-header">
      <div 
        v-for="tab in tabs" 
        :key="tab.name"
        class="tab-item"
        :class="{ active: activeTab === tab.name }"
        @click="handleTabClick(tab.name)"
      >
        <el-icon><component :is="tab.icon" /></el-icon>
        <span class="tab-label">{{ tab.label }}</span>
      </div>
    </div>

    <div class="tab-content">
      <div v-show="activeTab === 'avatar'" class="tab-pane">
        <div class="avatar-selector-wrapper" :class="{ 'disabled': isConnected }">
          <AvatarSelector @preload-complete="$emit('preload-complete')" />
          <div class="disabled-mask" v-if="isConnected">
            <el-alert
              title="请先结束当前对话"
              type="warning"
              :closable="false"
              show-icon
              center
            />
          </div>
        </div>
      </div>
      <div v-show="activeTab === 'voice'" class="tab-pane">
        <VoiceManager />
      </div>
      <div v-show="activeTab === 'prompt'" class="tab-pane">
        <PromptManager />
      </div>
      <div v-show="activeTab === 'knowledge'" class="tab-pane">
        <KnowledgeManager />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { User, Microphone, ChatDotRound, Files } from '@element-plus/icons-vue'
import AvatarSelector from './AvatarSelector.vue'
import VoiceManager from './VoiceManager.vue'
import PromptManager from './PromptManager.vue'
import KnowledgeManager from './KnowledgeManager.vue'

export default {
  name: 'ControlTabs',
  components: {
    AvatarSelector,
    VoiceManager,
    PromptManager,
    KnowledgeManager,
    User,
    Microphone,
    ChatDotRound,
    Files
  },
  props: {
    isConnected: {
      type: Boolean,
      default: false
    }
  },
  emits: ['preload-complete'],
  setup() {
    const activeTab = ref('avatar')
    
    const tabs = [
      { name: 'avatar', label: '数字人选择', icon: 'User' },
      { name: 'voice', label: '语音管理', icon: 'Microphone' },
      { name: 'prompt', label: '大模型设置', icon: 'ChatDotRound' },
      { name: 'knowledge', label: '知识库', icon: 'Files' }
    ]

    const handleTabClick = (tabName) => {
      activeTab.value = tabName
    }

    return {
      activeTab,
      tabs,
      handleTabClick
    }
  }
}
</script>

<style lang="scss" scoped>
.control-tabs {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;

  .tab-header {
    display: flex;
    background: var(--el-bg-color);
    border-top: 1px solid var(--el-border-color-light);
    padding: 0 12px;
    
    .tab-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 8px;
      cursor: pointer;
      transition: all 0.3s ease;
      color: var(--el-text-color-secondary);
      gap: 8px;
      
      @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 8px 4px;
        font-size: 12px;
        
        .el-icon {
          font-size: 24px;
          margin-bottom: 2px;
        }
      }

      &:hover {
        color: var(--el-color-primary);
        background: var(--el-fill-color-light);
      }

      &.active {
        color: var(--el-color-primary);
        border-top: 2px solid var(--el-color-primary);
        margin-top: -1px;
      }

      .el-icon {
        font-size: 20px;
      }

      .tab-label {
        white-space: nowrap;
      }
    }
  }

  .tab-content {
    flex: 1;
    overflow: auto;
    padding: 20px;
    background: var(--el-bg-color-page);

    .tab-pane {
      height: 100%;
    }
  }
}

.avatar-selector-wrapper {
  position: relative;
  height: 100%;

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .disabled-mask {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }
}
</style> 