import { webrtcAPI } from '@/api'

export class WebRTCManager {
  constructor(config = {}) {
    this.pc = null
    this.dc = null
    this.config = {
      iceServers: [
        {
          urls: [
            'stun:stun.l.google.com:19302',
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302'
          ]
        }
      ],
      iceCandidatePoolSize: 10,
      ...config
    }
    this.onMessage = null
    this.onConnectionStateChange = null
    this.candidates = []
  }

  initialize() {
    try {
      this.pc = new RTCPeerConnection(this.config)
      this.dc = this.pc.createDataChannel('chat')
      
      this.setupEventListeners()
      return true
    } catch (error) {
      console.error('WebRTC 初始化失败:', error)
      return false
    }
  }

  setupEventListeners() {
    if (!this.pc || !this.dc) return

    this.pc.onicecandidate = event => {
      if (event.candidate) {
        console.log('New ICE candidate:', event.candidate)
        this.candidates.push(event.candidate)
      }
    }

    this.pc.oniceconnectionstatechange = () => {
      console.log('ICE Connection state:', this.pc.iceConnectionState)
      if (this.onConnectionStateChange) {
        this.onConnectionStateChange(this.pc.iceConnectionState)
      }
    }

    this.pc.onconnectionstatechange = () => {
      console.log('Connection state:', this.pc.connectionState)
      if (this.onConnectionStateChange) {
        this.onConnectionStateChange(this.pc.connectionState)
      }
    }

    this.dc.onopen = () => {
      console.log('Data channel open')
    }

    this.dc.onclose = () => {
      console.log('Data channel closed')
    }

    this.dc.onmessage = event => {
      console.log('Received message:', event.data)
      if (this.onMessage) {
        try {
          const data = JSON.parse(event.data)
          this.onMessage(data)
        } catch (error) {
          console.error('Failed to parse message:', error)
        }
      }
    }
  }

  async createOffer() {
    if (!this.pc) throw new Error('WebRTC not initialized')

    try {
      const offer = await this.pc.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
      })
      await this.pc.setLocalDescription(offer)

      // 等待一段时间收集 ICE 候选者
      await new Promise((resolve) => {
        const checkState = () => {
          if (this.pc.iceGatheringState === 'complete') {
            resolve()
          } else {
            setTimeout(checkState, 100)
          }
        }
        setTimeout(checkState, 100)
      })

      return {
        sdp: this.pc.localDescription.sdp,
        type: this.pc.localDescription.type,
        candidates: this.candidates
      }
    } catch (error) {
      console.error('创建 offer 失败:', error)
      throw error
    }
  }

  async handleAnswer(answer) {
    try {
      const rtcAnswer = new RTCSessionDescription(answer)
      await this.pc.setRemoteDescription(rtcAnswer)

      // 添加远程的 ICE 候选者
      if (answer.candidates) {
        for (const candidate of answer.candidates) {
          await this.pc.addIceCandidate(new RTCIceCandidate(candidate))
        }
      }
    } catch (error) {
      console.error('处理 answer 失败:', error)
      throw error
    }
  }

  sendMessage(message) {
    if (this.dc && this.dc.readyState === 'open') {
      this.dc.send(JSON.stringify(message))
      return true
    }
    return false
  }

  destroy() {
    if (this.dc) {
      this.dc.close()
    }
    if (this.pc) {
      this.pc.close()
    }
    this.dc = null
    this.pc = null
    this.candidates = []
  }

  // 添加透明背景处理
  static async processVideoFrame(frame, controller) {
    const canvas = new OffscreenCanvas(frame.codedWidth, frame.codedHeight)
    const ctx = canvas.getContext('2d', { willReadFrequently: true })
    
    ctx.drawImage(frame, 0, 0)
    const imageData = ctx.getImageData(0, 0, frame.codedWidth, frame.codedHeight)
    const data = imageData.data
    
    const gFloor = 100
    const rbCeiling = 100
    
    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 1] >= gFloor && data[i] <= rbCeiling && data[i + 2] <= rbCeiling) {
        data[i + 3] = 0
      }
    }
    
    ctx.putImageData(imageData, 0, 0)
    const newFrame = new VideoFrame(canvas, { timestamp: frame.timestamp })
    controller.enqueue(newFrame)
    frame.close()
  }

  // 设置音频可视化
  static setupAudioVisualization(stream, onLevelsUpdate) {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)()
    const analyser = audioContext.createAnalyser()
    analyser.fftSize = 64
    
    const source = audioContext.createMediaStreamSource(stream)
    source.connect(analyser)
    
    const dataArray = new Uint8Array(analyser.frequencyBinCount)
    
    const updateLevels = () => {
      analyser.getByteFrequencyData(dataArray)
      const levels = Array.from(dataArray).map(value => (value / 255) * 100)
      if (onLevelsUpdate) {
        onLevelsUpdate(levels)
      }
      requestAnimationFrame(updateLevels)
    }
    
    updateLevels()

    return {
      destroy: () => {
        audioContext.close()
      }
    }
  }
} 