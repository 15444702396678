<template>
  <div class="home" :class="{ 'connected': isConnected }">
    <el-header class="header" v-show="!isConnected">
      <div class="user-info">
        <div class="user-avatar">
          {{ currentUser?.avatar }}
        </div>
        <span class="username">{{ currentUser?.username }}</span>
      </div>
      <el-button type="danger" @click="handleLogout">退出登录</el-button>
    </el-header>

    <el-main class="main-content" :class="{ 'no-header': isConnected }">
      <div class="content-container">
        <div class="video-section">
          <WebRTCVideo>
            <template #default>
              <div class="message-bubbles" :class="{
                'position-hidden': cardPosition === 'hidden',
                'position-half': cardPosition === 'half',
                'position-full': cardPosition === 'full',
                'chat-visible': showChat
              }">
                <div 
                  v-for="message in recentMessages" 
                  :key="message.id"
                  class="message-bubble"
                  :class="message.type"
                >
                  <div class="message-content">{{ message.content }}</div>
                  <div class="message-time">{{ message.timestamp }}</div>
                </div>
              </div>
            </template>
          </WebRTCVideo>
          <div class="floating-buttons" v-if="isConnected">
            <div class="floating-record-button">
              <el-button
                :type="isRecording ? 'danger' : 'success'"
                class="record-button"
                @click="handleRecordToggle"
              >
                <template #icon>
                  <el-icon>
                    <Microphone v-if="!isRecording" />
                    <VideoPause v-else />
                  </el-icon>
                </template>
              </el-button>
            </div>
            <div class="floating-hangup-button" v-if="!isMobile">
              <el-button
                type="danger"
                class="hangup-button"
                @click="handleStart"
              >
                <template #icon>
                  <el-icon><CircleClose /></el-icon>
                </template>
              </el-button>
            </div>
          </div>
          <div class="message-input-card" 
            :class="[cardPosition]"
            :style="{ transform: getTransformForPosition(cardPosition) }"
            ref="messageCard"
          >
            <div class="handle" @click="toggleCardPosition"></div>
            <div class="card-content">
              <div class="start-section" v-if="!isConnected || cardPosition !== 'full'">
                <el-button
                  :type="isConnected ? 'danger' : 'primary'"
                  :disabled="!canStart"
                  :loading="connecting"
                  @click="handleStart"
                  class="start-button"
                >
                  {{ isConnected ? '结束对话' : '开始对话' }}
                </el-button>
              </div>
              
              <div class="input-area" v-if="isConnected && cardPosition === 'full'">
                <el-input
                  v-model="inputMessage"
                  type="textarea"
                  placeholder="输入您想说的话..."
                  resize="none"
                  @keyup.enter.native="handleSend"
                />
                <el-button 
                  type="primary" 
                  :disabled="!canSend" 
                  @click="handleSend"
                  class="send-button"
                >
                  发送
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-section" :class="{ visible: showChat }">
          <div class="chat-header">
            <h3>聊天记录</h3>
            <div class="header-actions">
              <el-button 
                v-if="isConnected && !isMobile"
                type="danger" 
                circle
                @click="handleStart"
              >
                <el-icon><CircleClose /></el-icon>
              </el-button>
              <el-button 
                type="primary" 
                :icon="Setting"
                circle
                @click="showControls = true"
              />
            </div>
          </div>
          <ChatInterface />
          <!-- PC端发送消息组件 -->
          <div class="chat-input" v-if="isConnected && !isMobile">
            <el-input
              v-model="inputMessage"
              type="textarea"
              :rows="3"
              placeholder="输入您想说的话..."
              resize="none"
              @keyup.enter.native="handleSend"
            />
            <el-button 
              type="primary" 
              :disabled="!canSend" 
              @click="handleSend"
            >
              发送
            </el-button>
          </div>
        </div>
      </div>

      <el-drawer
        v-model="showControls"
        title="控制面板"
        direction="rtl"
        size="80%"
        :with-header="false"
      >
        <div class="controls-container">
          <ControlTabs 
            :is-connected="isConnected"
            @preload-complete="handlePreloadComplete"
          />
          
          <div class="control-actions">
            <el-button
              type="primary"
              :disabled="!canStart"
              :loading="connecting"
              @click="handleStart"
            >
              {{ isConnected ? '结束对话' : '开始对话' }}
            </el-button>
          </div>
        </div>
      </el-drawer>

      <!-- PC端控制按钮 -->
      <div class="pc-controls" v-if="!isMobile">
        <div class="control-button avatar-button" @click="showControls = true">
          <el-tooltip content="选择数字人" placement="left">
            <el-button type="primary" circle>
              <el-icon><User /></el-icon>
            </el-button>
          </el-tooltip>
        </div>
        <div class="control-button chat-button">
          <el-tooltip content="聊天记录" placement="left">
            <el-button 
              type="primary" 
              circle
              :class="{ 'opened': showChat, 'closed': !showChat }"
              @click="toggleChat"
            >
              <el-icon><ChatDotRound /></el-icon>
            </el-button>
          </el-tooltip>
        </div>
      </div>

      <!-- 移动端控制按钮 -->
      <div class="mobile-controls">
        <div class="control-button avatar-button" @click="showControls = true">
          <el-icon><User /></el-icon>
        </div>
        <div 
          class="control-button chat-button" 
          :class="{ 'opened': showChat, 'closed': !showChat }"
          @click="toggleChat"
        >
          <el-icon><ChatDotRound /></el-icon>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import WebRTCVideo from '@/components/WebRTCVideo.vue'
import ChatInterface from '@/components/ChatInterface.vue'
import ControlTabs from '@/components/ControlTabs.vue'
import WebRTCManager from '@/utils/WebRTCManager'
import { webrtcAPI, chatAPI } from '@/api'
import config from '@/config'
import { Microphone, VideoPause, ChatDotRound, User, Setting, CircleClose } from '@element-plus/icons-vue'

export default {
  name: 'Home',
  components: {
    WebRTCVideo,
    ChatInterface,
    ControlTabs,
    Microphone,
    VideoPause,
    ChatDotRound,
    User,
    Setting,
    CircleClose
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const webrtc = ref(null)
    const showControls = ref(false)
    const showChat = ref(false)
    const connecting = ref(false)
    const inputMessage = ref('')
    const showMessageInput = ref(false)
    const recentMessages = ref([])
    let messageId = 0
    let startY = 0
    let currentY = 0
    const cardHeight = ref(0)
    const cardPosition = ref('hidden') // 'hidden' | 'half' | 'full'
    const messageCard = ref(null)
    const isRecording = ref(false)
    const cardStateStack = ref([])

    const currentUser = computed(() => store.getters.currentUser)
    const selectedAvatar = computed(() => {
      const selectedId = store.state.selectedAvatar
      if (!selectedId) return null
      return store.getters['training/getAvatarById'](selectedId)
    })
    const isConnected = computed(() => store.state.isConnected)
    const canStart = computed(() => selectedAvatar.value && !connecting.value && currentUser.value)
    const isMobile = computed(() => window.innerWidth <= 768)

    const canSend = computed(() => isConnected.value && inputMessage.value.trim())

    const addMessage = (content, type = 'system', timestamp) => {
      console.log('Home - addMessage:', { content, type, messageId, timestamp })
      messageId++
      const newMessage = {
        id: messageId,
        content,
        type,
        timestamp
      }
      console.log('Home - 新消息对象:', newMessage)
      recentMessages.value = [...recentMessages.value, newMessage].slice(-4)
      console.log('Home - 当前消息列表:', recentMessages.value)
    }

    // 监听 store 中的消息变化
    watch(() => store.state.messages, (messages) => {
      console.log('Home - Messages updated:', messages)
      if (messages && messages.length > 0) {
        const lastMessage = messages[messages.length - 1]
        console.log('Home - Last message:', lastMessage)
        if (lastMessage.type === 'system' || lastMessage.type === 'user') {
          console.log('Home - 处理消息:', lastMessage)
          // 使用消息中的时间戳，如果没有则生成新的
          const timestamp = lastMessage.timestamp || (() => {
            const now = new Date()
            return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`
          })()
          addMessage(lastMessage.content, lastMessage.type, timestamp)
        } else {
          console.log('Home - 忽略未知消息类型:', lastMessage.type)
        }
      }
    }, { deep: true })

    const initializeWebRTC = async () => {
      if (!selectedAvatar.value) {
        ElMessage.warning('请先选择数字人形象')
        return null
      }

      try {
        // 创建新的 WebRTC 管理器实例
        webrtc.value = new WebRTCManager(config.webrtc)
        await webrtc.value.initialize()
        
        // 设置连接状态变化的回调
        webrtc.value.onConnectionStateChange = (state) => {
          console.log('WebRTC connection state:', state)
          store.commit('SET_CONNECTION_STATUS', state === 'connected')
        }

        // 设置消息处理回调
        webrtc.value.onMessage = (data) => {
          try {
            const message = JSON.parse(data)
            store.commit('ADD_MESSAGE', message)
          } catch (error) {
            console.error('解析消息失败:', error)
          }
        }

        // 进行连接协商
        if (!selectedAvatar.value || !selectedAvatar.value.id) {
          throw new Error('无效的数字人参数')
        }

        console.log('开始协商，使用数字人:', selectedAvatar.value)
        const response = await webrtc.value.negotiate(
          store.state.user?.username || 'guest',
          selectedAvatar.value
        )
        
        // 保存会话ID
        if (response && response.sessionid) {
          console.log('保存会话ID:', response.sessionid)
          store.commit('SET_SESSION_ID', response.sessionid)
          
          // 更新 store 中的 WebRTC 状态
          store.dispatch('updateWebRTCState', {
            pc: webrtc.value.pc,
            dc: webrtc.value.dc,
            sessionId: response.sessionid
          })
        }

        ElMessage.success('连接成功')
        return webrtc.value
      } catch (error) {
        console.error('WebRTC 初始化失败:', error)
        ElMessage.error(error.message || 'WebRTC 初始化失败')
        throw error
      }
    }

    const handleStart = async () => {
      if (!currentUser.value) {
        ElMessage.warning('请先登录')
        router.push('/login')
        return
      }

      if (!selectedAvatar.value) {
        ElMessage.warning('请先选择数字人形象')
        showControls.value = true
        return
      }

      if (isConnected.value) {
        try {
          await ElMessageBox.confirm(
            '确定要结束当前对话吗？',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          )
        } catch (e) {
          return
        }
        
        // 关闭连接
        if (webrtc.value) {
          webrtc.value.destroy()
          webrtc.value = null
        }
        store.commit('SET_CONNECTION_STATUS', false)
        // 清除气泡消息，但不清除聊天记录
        recentMessages.value = []
        store.dispatch('updateWebRTCState', { pc: null, dc: null, sessionId: null })
        ElMessage.success('对话已结束')
        return
      }

      connecting.value = true
      try {
        await initializeWebRTC()
      } catch (error) {
        console.error('连接失败:', error)
        ElMessage.error('连接失败，请检查网络后重试')
        if (webrtc.value) {
          webrtc.value.destroy()
          webrtc.value = null
        }
        store.commit('SET_CONNECTION_STATUS', false)
        recentMessages.value = []
      } finally {
        connecting.value = false
      }
    }

    const handleLogout = () => {
      if (isConnected.value) {
        webrtc.value?.destroy()
      }
      store.dispatch('logout')
      router.push('/login')
    }

    const handlePreloadComplete = (success) => {
      if (success && isMobile.value) {
        showControls.value = false
      }
    }

    const toggleChat = () => {
      console.log('切换聊天界面状态:', {
        当前状态: showChat.value,
        新状态: !showChat.value,
        卡片位置: cardPosition.value,
        是否连接: isConnected.value
      })
      
      if (!showChat.value) {
        // 打开聊天界面时，保存当前状态
        const currentState = {
          position: cardPosition.value,
          transform: messageCard.value?.style.transform,
          inputAreaState: messageCard.value?.querySelector('.input-area')?.style.cssText || '',
          isFullState: cardPosition.value === 'full'
        }
        cardStateStack.value.push(currentState)
        console.log('保存状态:', currentState)
        
        // 隐藏卡片
        cardPosition.value = 'hidden'
        if (messageCard.value) {
          messageCard.value.style.transition = 'all 0.3s ease'
          messageCard.value.style.transform = 'translateY(100%)'
          messageCard.value.style.opacity = '0'
          setTimeout(() => {
            if (showChat.value) {
              messageCard.value.style.display = 'none'
            }
          }, 300)
        }
      } else {
        // 关闭聊天界面时，恢复之前的状态
        const previousState = cardStateStack.value.pop()
        console.log('恢复状态:', previousState)
        
        if (previousState) {
          cardPosition.value = previousState.position
          if (messageCard.value) {
            messageCard.value.style.display = ''
            messageCard.value.offsetHeight // 强制重排
            messageCard.value.style.transition = 'all 0.3s ease'
            messageCard.value.style.opacity = '1'
            messageCard.value.style.transform = previousState.transform
            
            // 如果之前是full状态，恢复输入区域
            if (previousState.isFullState) {
              const inputArea = messageCard.value.querySelector('.input-area')
              if (inputArea) {
                inputArea.style.cssText = previousState.inputAreaState || ''
                inputArea.style.maxHeight = '100%'
                inputArea.style.opacity = '1'
                inputArea.style.overflow = 'visible'
              }
            }
          }
        } else {
          // 如果没有保存的状态，默认到half位置
          cardPosition.value = 'half'
        }
      }
      
      showChat.value = !showChat.value
      showControls.value = false
    }

    const handleSend = async () => {
      if (!canSend.value || !inputMessage.value.trim()) return
      
      const message = inputMessage.value.trim()
      inputMessage.value = ''
      
      // 添加用户消息到store #废弃# 服务端会自动转发到客户端
      // store.commit('ADD_MESSAGE', {
      //   content: message,
      //   type: 'user',
      //   timestamp: new Date().toLocaleTimeString()
      // })

      try {
        const sessionId = webrtc.value?.sessionId || store.state.webrtcState.sessionId
        if (!sessionId) {
          console.error('未找到会话ID')
          ElMessage.warning('请先建立连接')
          return
        }

        await chatAPI.sendMessage({
          sessionId: sessionId,
          text: message
        })
      } catch (error) {
        console.error('发送消息失败:', error)
        ElMessage.error('发送失败：' + (error.message || '未知错误'))
      }
    }

    const toggleCardPosition = () => {
      if (!selectedAvatar.value) return
      
      if (cardPosition.value === 'hidden') {
        cardPosition.value = 'half'
      } else if (cardPosition.value === 'half' && isConnected.value) {
        cardPosition.value = 'full'
      } else {
        cardPosition.value = 'hidden'
      }
    }

    const getTransformForPosition = (position) => {
      switch (position) {
        case 'hidden':
          return 'translateY(calc(100% - 30px))'
        case 'half':
          return 'translateY(calc(100% - 100px))'
        case 'full':
          return 'translateY(0)'
        default:
          return 'translateY(calc(100% - 30px))'
      }
    }

    const handleTouchStart = (e) => {
      if (!selectedAvatar.value) return
      
      startY = e.touches[0].clientY
      const card = e.currentTarget
      card.style.transition = 'none'
    }

    const handleTouchMove = (e) => {
      if (!selectedAvatar.value) return
      
      const deltaY = startY - e.touches[0].clientY
      const card = e.currentTarget
      let baseTransform = 0
      const cardRect = card.getBoundingClientRect()
      const windowHeight = window.innerHeight
      
      switch (cardPosition.value) {
        case 'hidden':
          baseTransform = windowHeight - 30
          break
        case 'half':
          baseTransform = windowHeight - 100
          break
        case 'full':
          if (!isConnected.value) {
            baseTransform = windowHeight - 100
          } else {
            baseTransform = 0
          }
          break
      }
      
      const newY = Math.max(
        isConnected.value ? 0 : windowHeight - 100,
        Math.min(windowHeight - 30, baseTransform - deltaY)
      )
      card.style.transform = `translateY(${newY}px)`
    }

    const handleTouchEnd = (e) => {
      if (!selectedAvatar.value) return
      
      const deltaY = startY - e.changedTouches[0].clientY
      const card = e.currentTarget
      card.style.transition = 'transform 0.3s ease'
      
      if (Math.abs(deltaY) > 50) {
        if (deltaY > 0) {
          if (cardPosition.value === 'hidden') {
            cardPosition.value = 'half'
          } else if (cardPosition.value === 'half' && isConnected.value) {
            cardPosition.value = 'full'
          }
        } else {
          if (cardPosition.value === 'full') {
            cardPosition.value = 'half'
          } else if (cardPosition.value === 'half') {
            cardPosition.value = 'hidden'
          }
        }
      }
      
      card.style.transform = getTransformForPosition(cardPosition.value)
    }

    // 监听数字人选择状态变化
    watch(() => selectedAvatar.value, (newValue) => {
      console.log('Avatar selected:', newValue)
      if (!newValue) {
        cardPosition.value = 'hidden'
      } else {
        // 选择数字人后自动显示到 half 位置
        cardPosition.value = 'half'
        // 在移动端自动关闭选择界面
        if (isMobile.value) {
          showControls.value = false
        }
      }
    })

    // 修改卡片位置变化的监听
    watch(() => cardPosition.value, (newVal, oldVal) => {
      console.log('卡片位置变化:', {
        新位置: newVal,
        旧位置: oldVal,
        聊天界面显示: showChat.value,
        是否连接: isConnected.value
      })
      
      if (newVal === 'full' && messageCard.value) {
        const inputArea = messageCard.value.querySelector('.input-area')
        if (inputArea) {
          // 保存完整的样式状态
          const fullState = {
            maxHeight: '100%',
            opacity: '1',
            overflow: 'visible',
            transition: 'all 0.3s ease'
          }
          Object.assign(inputArea.style, fullState)
        }
      }
    })

    // 监听连接状态变化
    watch(() => isConnected.value, (newVal) => {
      console.log('连接状态变化:', {
        新状态: newVal,
        头部显示: !newVal,
        主内容类名: newVal ? 'no-header' : '',
        聊天区域高度: document.querySelector('.chat-section')?.offsetHeight
      })
      
      // 当连接成功时，将卡片设置为隐藏状态
      if (newVal) {
        cardPosition.value = 'hidden'
      }
    })

    // 添加调试代码
    watch(() => cardPosition.value, (newVal) => {
      console.log('Card position changed:', newVal)
    })

    // 监听聊天界面显示状态变化
    watch(() => showChat.value, (newVal) => {
      console.log('聊天界面显示状态变化:', {
        显示: newVal,
        头部显示: !isConnected.value,
        聊天区域位置: document.querySelector('.chat-section')?.getBoundingClientRect()
      })
    })

    const handleRecordToggle = async () => {
      if (isRecording.value) {
        // 停止收音
        try {
          const sessionId = webrtc.value?.sessionId || store.state.webrtcState.sessionId
          if (!sessionId) {
            console.error('未找到会话ID:', {
              webrtcSessionId: webrtc.value?.sessionId,
              storeSessionId: store.state.webrtcState.sessionId
            })
            ElMessage.warning('请先建立连接')
            return
          }
          
          await webrtcAPI.stopRadio(sessionId)
          isRecording.value = false
          ElMessage.success('已停止收音')
        } catch (error) {
          ElMessage.error(error.message || '停止收音失败')
          console.error('停止收音失败:', error)
        }
      } else {
        // 开始收音
        try {
          const sessionId = webrtc.value?.sessionId || store.state.webrtcState.sessionId
          if (!sessionId) {
            console.error('未找到会话ID:', {
              webrtcSessionId: webrtc.value?.sessionId,
              storeSessionId: store.state.webrtcState.sessionId
            })
            ElMessage.warning('请先建立连接')
            return
          }
          
          await webrtcAPI.startRadio(sessionId)
          isRecording.value = true
          ElMessage.success('开始收音')
        } catch (error) {
          ElMessage.error(error.message || '开始收音失败')
          console.error('开始收音失败:', error)
        }
      }
    }

    onMounted(() => {
      const userStr = localStorage.getItem('user')
      if (userStr) {
        const user = JSON.parse(userStr)
        store.commit('SET_USER', user)
      } else {
        router.push('/login')
      }
      const card = document.querySelector('.message-input-card')
      if (card) {
        cardHeight.value = card.offsetHeight - 100 // 减去露出的高度
      }
      
      // 使用被动事件监听器
      if (messageCard.value) {
        messageCard.value.addEventListener('touchstart', handleTouchStart, { passive: true })
        messageCard.value.addEventListener('touchmove', handleTouchMove, { passive: false })
        messageCard.value.addEventListener('touchend', handleTouchEnd, { passive: true })
      }
    })

    onUnmounted(() => {
      if (webrtc.value) {
        webrtc.value.destroy()
        webrtc.value = null
      }
      
      // 清理事件监听器
      if (messageCard.value) {
        messageCard.value.removeEventListener('touchstart', handleTouchStart)
        messageCard.value.removeEventListener('touchmove', handleTouchMove)
        messageCard.value.removeEventListener('touchend', handleTouchEnd)
      }
    })

    return {
      currentUser,
      showControls,
      showChat,
      connecting,
      inputMessage,
      showMessageInput,
      recentMessages,
      messageCard,
      cardPosition,
      selectedAvatar,
      isRecording,
      handleStart,
      handleLogout,
      handlePreloadComplete,
      toggleChat,
      canSend,
      handleSend,
      toggleCardPosition,
      getTransformForPosition,
      isConnected,
      canStart,
      isMobile,
      handleRecordToggle,
      webrtc,
      Setting,
      CircleClose
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  
  // 添加连接状态的样式控制
  &.connected {
    @media (max-width: 768px) {
      height: 100vh;
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
    }
  }

  .header {
    background: var(--card-background);
    padding: 0 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    z-index: 10;

    .user-info {
      display: flex;
      align-items: center;
      gap: 10px;

      .user-avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: linear-gradient(135deg, var(--primary-light), var(--primary-color));
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
      }

      .username {
        color: var(--text-primary);
        font-weight: 500;
      }
    }
  }

  .main-content {
    flex: 1;
    padding: 20px;
    display: flex;
    position: relative;
    overflow: hidden;
    transition: padding-top 0.3s ease;

    &.no-header {
      padding-top: 0;
      height: 100vh;
    }

    .content-container {
      width: 100%;
      height: 100%;
      display: grid;
      gap: 20px;
      max-width: 1600px;
      margin: 0 auto;

      @media (min-width: 769px) {
        grid-template-columns: minmax(0, 1fr) 350px;
        
        .video-section {
          position: relative;
          height: calc(100vh - 140px);
          min-height: 400px;
          border-radius: 24px;
          overflow: hidden;
          background: linear-gradient(145deg, #1a1a1a, #2a2a2a);
          box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 24px;

          :deep(.video-container) {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border-radius: 20px;
            overflow: hidden;
            backdrop-filter: blur(20px);
            background: rgba(255, 255, 255, 0.02);
            box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.05);

            .message-bubbles {
              position: absolute;
              z-index: 95;
              pointer-events: none;
            }
          }
        }

        .chat-section {
          position: relative;
          transform: none;
          width: 350px;
          height: calc(100vh - 140px);
          background: var(--card-background);
          border-radius: var(--border-radius-lg);
          box-shadow: var(--shadow-card);
          display: flex;
          flex-direction: column;
          padding: 0;

          .chat-header {
            padding: 15px 20px;
            border-bottom: 1px solid var(--el-border-color-light);
            background: var(--card-background);
            border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
            z-index: 1;
          }

          .chat-input {
            padding: 15px;
            border-top: 1px solid var(--el-border-color-light);
            background: var(--card-background);
            border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);

            .el-textarea {
              margin-bottom: 10px;

              :deep(.el-textarea__inner) {
                resize: none;
                border-radius: 8px;
                
                &:focus {
                  box-shadow: 0 0 0 2px var(--el-color-primary-light-8);
                }
              }
            }

            .el-button {
              width: 100%;
              height: 40px;
              font-size: 16px;
            }
          }

          &.visible {
            transform: none;
          }
        }

        .message-input-card {
          display: none;
        }
      }

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }

    .video-section {
      position: relative;
      height: calc(100vh - 140px);
      min-height: 400px;

      @media (max-width: 768px) {
        height: 100vh;
        
        :deep(.video-container) {
          .image-container, .media-container {
            img, video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .floating-buttons {
        position: fixed;
        bottom: 45px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 95;
        pointer-events: auto;
        display: flex;
        gap: 20px;
        align-items: center;

        .floating-record-button, .floating-hangup-button {
          .record-button, .hangup-button {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
            border: none;
            backdrop-filter: blur(8px);

            .el-icon {
              font-size: 24px;
              transition: all 0.3s ease;
            }
          }
        }

        .floating-record-button {
          .record-button {
            &.el-button--success {
              background: linear-gradient(145deg, #4CAF50, #66BB6A);
              &:hover {
                transform: scale(1.05);
                background: linear-gradient(145deg, #66BB6A, #81C784);
                box-shadow: 0 6px 20px rgba(76, 175, 80, 0.3);
                .el-icon {
                  transform: scale(1.1);
                }
              }
              &:active {
                transform: scale(0.95);
              }
            }

            &.el-button--danger {
              background: linear-gradient(145deg, #E53935, #EF5350);
              &:hover {
                transform: scale(1.05);
                background: linear-gradient(145deg, #EF5350, #E57373);
                box-shadow: 0 6px 20px rgba(229, 57, 53, 0.3);
                .el-icon {
                  transform: scale(1.1);
                }
              }
              &:active {
                transform: scale(0.95);
              }
            }
          }
        }

        .floating-hangup-button {
          .hangup-button {
            background: linear-gradient(145deg, #E53935, #EF5350);
            &:hover {
              transform: scale(1.05);
              background: linear-gradient(145deg, #EF5350, #E57373);
              box-shadow: 0 6px 20px rgba(229, 57, 53, 0.3);
              .el-icon {
                transform: scale(1.1);
              }
            }
            &:active {
              transform: scale(0.95);
            }
          }
        }

        @media (max-width: 768px) {
          bottom: 45px;
          
          .record-button {
            width: 50px;
            height: 50px;
            
            .el-icon {
              font-size: 20px;
            }
          }
        }
      }
    }

    .message-bubbles {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 100px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      transition: all 0.3s ease;
      pointer-events: none;
      z-index: 90;
      width: 100%;
      max-width: 800px;
      padding: 0 20px;

      @media (min-width: 769px) {
        position: absolute;
        padding: 0 40px;
        bottom: 120px;
        max-width: 100%;
        left: 0;
        right: 0;
        transform: none;
        margin: 0 auto;
        width: auto;
      }

      &.position-hidden {
        bottom: 80px;
      }
      
      &.position-half {
        bottom: 150px;
      }
      
      &.position-full {
        bottom: 400px;
      }

      &.chat-visible {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }

      .message-bubble {
        padding: 12px 16px;
        border-radius: 12px;
        max-width: 70%;
        font-size: 15px;
        line-height: 1.5;
        word-break: break-word;
        backdrop-filter: blur(8px);
        border: 1px solid rgba(255, 255, 255, 0.2);
        animation: fadeIn 0.3s ease;
        
        @media (min-width: 769px) {
          max-width: 50%;
          backdrop-filter: blur(12px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          margin: 0 20px;
        }
        
        &.system {
          align-self: flex-start;
          background: rgba(255, 255, 255, 0.15);
          color: #fff;
          border-top-left-radius: 4px;
          margin-left: 12px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

          @media (min-width: 769px) {
            background: rgba(255, 255, 255, 0.25);
            margin-right: auto;
            margin-left: 20px;
            border-color: rgba(255, 255, 255, 0.3);
          }
        }

        &.user {
          align-self: flex-end;
          background: rgba(0, 114, 255, 0.3);
          color: #fff;
          border-top-right-radius: 4px;
          margin-right: 12px;
          box-shadow: 0 2px 8px rgba(0, 114, 255, 0.2);
          border-color: rgba(0, 114, 255, 0.3);

          @media (min-width: 769px) {
            background: rgba(0, 114, 255, 0.4);
            margin-left: auto;
            margin-right: 20px;
            border-color: rgba(0, 114, 255, 0.4);
          }
        }
      }
    }

    .message-input-card {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(8px);
      border-radius: 20px 20px 0 0;
      padding: 20px;
      transition: all 0.3s ease;
      z-index: 100;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
      touch-action: pan-y;
      min-height: 200px;
      transform-origin: bottom;
      opacity: 1;
      pointer-events: auto;

      &.hidden {
        transform: translateY(calc(100% - 30px));
      }
      
      &.half {
        transform: translateY(calc(100% - 100px));
      }
      
      &.full {
        transform: translateY(0);
      }

      .handle {
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 4px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 2px;
        cursor: pointer;
        z-index: 1;
      }

      .card-content {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        height: calc(100% - 40px);
        opacity: 1;
        transition: opacity 0.3s ease;

        .start-section {
          display: flex;
          justify-content: center;
          padding: 10px 0;
          min-height: 50px;

          .start-button {
            width: 200px;
            height: 50px;
            font-size: 18px;
          }
        }

        .input-area {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 10px;
          height: 100%;
          max-height: 0;
          overflow: hidden;
          opacity: 0;
          transition: all 0.3s ease;

          .el-textarea {
            flex: 1;
            
            :deep(.el-textarea__inner) {
              height: 100%;
              min-height: 120px;
              resize: none;
              font-size: 16px;
              line-height: 1.6;
              padding: 12px;
              background: rgba(255, 255, 255, 0.8);
              border: 1px solid rgba(255, 255, 255, 0.2);
              border-radius: 12px;
              
              &:focus {
                background: rgba(255, 255, 255, 0.95);
                border-color: rgba(0, 114, 255, 0.3);
                box-shadow: 0 0 0 3px rgba(0, 114, 255, 0.1);
              }
            }
          }

          .send-button {
            height: 45px;
            align-self: flex-end;
            width: 120px;
            margin-top: 10px;
            background: linear-gradient(145deg, #0072FF, #00C6FF);
            border: none;
            color: #fff;
            font-weight: 600;
            
            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 4px 12px rgba(0, 114, 255, 0.3);
            }
            
            &:active {
              transform: translateY(0);
            }
          }
        }
      }

      &.full {
        .input-area {
          max-height: 100%;
          opacity: 1;
          overflow: visible;

          .el-textarea {
            :deep(.el-textarea__inner) {
              min-height: 100px;
            }
          }
        }
      }
    }

    .controls-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      background: var(--el-bg-color-page);

      @media screen and (max-width: 768px) {
        .control-actions {
          display: none;  // 在移动端隐藏底部按钮
        }
      }

      .control-actions {
        padding: 16px;
        background: var(--el-bg-color);
        border-top: 1px solid var(--el-border-color-light);
        text-align: center;
        margin-bottom: env(safe-area-inset-bottom);

        .el-button {
          min-width: 120px;
          height: 44px;
          font-size: 16px;
          
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }

    .pc-controls {
      position: fixed;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      gap: 16px;
      z-index: 100;

      @media (max-width: 768px) {
        display: none;
      }

      .control-button {
        .el-button {
          width: 48px;
          height: 48px;
          font-size: 20px;
          box-shadow: var(--shadow-card);
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.05);
            box-shadow: var(--shadow-button);
          }

          &.opened {
            background-color: var(--el-color-primary);
            border-color: var(--el-color-primary);
            color: white;
          }
        }
      }
    }

    .mobile-controls {
      position: fixed;
      right: 20px;
      bottom: 100px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      z-index: 100;

      .control-button {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

        .el-icon {
          font-size: 24px;
          color: #fff;
        }

        &:hover {
          transform: scale(1.05);
        }

        &:active {
          transform: scale(0.95);
        }

        &.avatar-button {
          background: linear-gradient(145deg, #3B82F6, #60A5FA);
          
          &:hover {
            background: linear-gradient(145deg, #60A5FA, #93C5FD);
            box-shadow: 0 6px 16px rgba(59, 130, 246, 0.3);
          }
        }

        &.chat-button {
          &.closed {
            background: linear-gradient(145deg, #10B981, #34D399);
            
            &:hover {
              background: linear-gradient(145deg, #34D399, #6EE7B7);
              box-shadow: 0 6px 16px rgba(16, 185, 129, 0.3);
            }
          }

          &.opened {
            background: linear-gradient(145deg, #F59E0B, #FBBF24);
            
            &:hover {
              background: linear-gradient(145deg, #FBBF24, #FCD34D);
              box-shadow: 0 6px 16px rgba(245, 158, 11, 0.3);
            }
          }
        }
      }

      @media (min-width: 769px) {
        bottom: 50%;
        transform: translateY(50%);
      }
    }
  }

  .footer {
    display: none;
  }
}

@include mobile {
  .home {
    .header {
      padding: 0 15px;
    }

    .main-content {
      padding: 0;

      .content-container {
        grid-template-columns: 1fr;
        gap: 0;
      }

      .video-section {
        height: 100vh;

        .message-input-card {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 15px;
          z-index: 95;
        }
      }

      .chat-section {
        position: fixed;
        top: 60px;
        right: 0;
        width: 100%;
        height: calc(100vh - 60px);
        transform: translateX(100%);
        transition: transform 0.3s ease;
        z-index: 90;
        background: var(--card-background);

        &.visible {
          transform: translateX(0);
        }
      }

      .mobile-controls {
        position: fixed;
        right: 20px;
        bottom: 100px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        z-index: 100;
      }
    }

    .footer {
      display: none;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid var(--el-border-color-light);

  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--text-primary);
  }

  .header-actions {
    display: flex;
    gap: 10px;
  }
}

.el-drawer {
  :deep(.el-drawer__body) {
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}
</style> 