<template>
  <div class="prompt-selector">
    <el-form-item label="提示词模板" prop="promptTemplate">
      <el-select 
        v-model="selectedTemplateId" 
        placeholder="请选择提示词模板"
        style="width: 100%"
        @change="handleTemplateChange"
      >
        <el-option
          v-for="template in promptTemplates"
          :key="template.id"
          :label="template.name"
          :value="template.id"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="系统提示词" prop="systemPrompt">
      <el-input
        :value="modelValue"
        @input="handleInput"
        type="textarea"
        :rows="4"
        placeholder="请输入或从模板选择系统提示词"
      />
      <div class="prompt-tips">
        <p>支持使用变量：</p>
        <p>{name} - 数字人名称</p>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'

export default {
  name: 'PromptSelector',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    avatarName: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedTemplateId = ref(null)
    const promptTemplates = ref([])

    // 加载提示词模板
    const loadTemplates = async () => {
      try {
        const response = await axios.get('/api/prompt-templates')
        console.log('加载模板响应:', response.data)
        if (response.data.code === 0) {
          promptTemplates.value = response.data.data
        }
      } catch (error) {
        console.error('加载提示词模板失败:', error)
      }
    }

    // 处理模板选择
    const handleTemplateChange = (templateId) => {
      console.log('选择模板ID:', templateId)
      const template = promptTemplates.value.find(t => t.id === templateId)
      if (template) {
        let prompt = template.content
        if (props.avatarName) {
          prompt = prompt.replace(/{name}/g, props.avatarName)
        }
        emit('update:modelValue', prompt)
      }
    }

    // 处理输入
    const handleInput = (value) => {
      emit('update:modelValue', value)
      // 当手动输入时，清除选中的模板
      selectedTemplateId.value = null
    }

    onMounted(() => {
      loadTemplates()
    })

    return {
      selectedTemplateId,
      promptTemplates,
      handleTemplateChange,
      handleInput
    }
  }
}
</script>

<style lang="scss" scoped>
.prompt-selector {
  .prompt-tips {
    margin-top: 8px;
    font-size: 12px;
    color: var(--el-text-color-secondary);
    
    p {
      margin: 4px 0;
    }
  }
}
</style> 