// 获取环境变量值
function getEnvValue(key, defaultValue) {
  // 在 webpack 环境中使用 process.env
  if (process.env && process.env[key] !== undefined) {
    console.log(`从 process.env 获取 ${key}:`, process.env[key])
    return process.env[key]
  }

  console.log(`使用默认值 ${key}:`, defaultValue)
  return defaultValue
}

// 获取并解析 JSON 环境变量
function getEnvJSON(key, defaultValue) {
  const value = getEnvValue(key, JSON.stringify(defaultValue))
  try {
    return JSON.parse(value)
  } catch (error) {
    console.error(`解析环境变量 ${key} 失败:`, error)
    return defaultValue
  }
}

// 默认数字人列表
const defaultAvatarList = [
  {
    id: 'wav2lip_avatar1',
    name: 'Y妹',
    description: '256模型',
    voice: 'hutao',
    isDefault: true
  },
  {
    id: 'wav2lip_avatar384',
    name: '小gao', 
    description: '384高清模型',
    voice: 'zh_voice_2',
    isDefault: false
  },
  {
    id: 'wav2lip_avatar2',
    name: '小M',
    description: '稳重成熟的男孩',
    voice: 'zh_voice_3',
    isDefault: false
  }
]

// 从环境变量获取数字人列表
const avatarList = getEnvJSON('VUE_APP_AVATAR_LIST', defaultAvatarList)

// 导出配置
export default {
  api: {
    baseURL: getEnvValue('VUE_APP_API_BASE_URL', 'http://175.27.143.201:8010'),
    chatURL: getEnvValue('VUE_APP_API_CHAT_URL', 'http://100.70.154.11:8877'),
    timeout: parseInt(getEnvValue('VUE_APP_API_TIMEOUT', '15000'))
  },
  webrtc: {
    iceServers: [
      {
        urls: 'stun:stun.l.google.com:19302'
      },
      {
        urls: 'stun:stun1.l.google.com:19302'
      },
      {
        urls: 'stun:stun2.l.google.com:19302'
      }
    ],
    iceCandidatePoolSize: 10
  },
  avatar: {
    list: avatarList,
    getDefault() {
      return this.list.find(avatar => avatar.isDefault) || this.list[0]
    },
    getVoice(avatarId) {
      const avatar = this.list.find(item => item.id === avatarId)
      return avatar ? avatar.voice : 'hutao'
    }
  }
} 