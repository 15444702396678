<template>
  <div class="avatar-selector">
    <div class="header">
      <div class="title-section">
        <h3>选择数字人形象</h3>
        <el-tooltip content="设置" placement="top">
          <el-button
            class="setting-btn"
            type="default"
            size="small"
            :icon="Setting"
            @click="showSettings = true"
          >
            <span class="btn-text">设置</span>
          </el-button>
        </el-tooltip>
      </div>
      <div class="search-section">
        <div class="filter-row">
          <el-input
            v-model="searchText"
            placeholder="搜索数字人"
            :prefix-icon="Search"
            clearable
            class="search-input"
          />
          <el-select 
            v-model="filterStatus" 
            placeholder="配置状态"
            clearable
            class="filter-select"
          >
            <el-option label="全部" value="" />
            <el-option label="未配置" value="unconfigured" />
            <el-option label="已配置" value="configured" />
            <el-option label="训练中" value="training" />
          </el-select>
          <el-select 
            v-model="filterType" 
            placeholder="数字人类型"
            clearable
            class="filter-select"
          >
            <el-option 
              label="全部" 
              value="" 
            />
            <el-option 
              label="图片数字人" 
              value="image"
            >
              <el-icon class="option-icon image"><Picture /></el-icon>
              <span>图片数字人</span>
            </el-option>
            <el-option 
              label="视频数字人" 
              value="video"
            >
              <el-icon class="option-icon video"><VideoCamera /></el-icon>
              <span>视频数字人</span>
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    
    <div class="avatar-grid">
      <!-- 训练按钮放在最前面 -->
      <div
        class="avatar-item training-item"
        @click="showTraining = true"
      >
        <div class="avatar-letter training-icon">
          <el-icon><Plus /></el-icon>
        </div>
        <div class="avatar-info">
          <span class="avatar-name">训练新数字人</span>
          <span class="avatar-desc">上传图片或视频开始训练</span>
        </div>
      </div>

      <!-- 训练中的数字人 -->
      <div
        v-for="training in trainingList"
        :key="training.digitalId"
        class="avatar-item training-progress-item"
        :class="{
          'training-failed': training.status === -1,
          'training-completed': training.status === 3 && !training.isConfigured
        }"
        @click="handleTrainingClick(training)"
      >
        <div class="avatar-letter" :class="{ 'has-image': training.coverImg }">
          <template v-if="training.coverImg">
            <img :src="training.coverImg" :alt="training.avatarName">
          </template>
          <template v-else>
            {{ training?.avatarName?.[0] || '?' }}
          </template>
        </div>
        <div class="avatar-info">
          <span class="avatar-name">{{ training?.avatarName || '未命名' }}</span>
          <span class="avatar-desc">{{ getTrainingStatusText(training?.status) }}</span>
          <div class="training-progress">
            <el-progress
              :percentage="getTrainingProgress(training).percentage"
              :status="getTrainingProgress(training).status"
              :stroke-width="4"
            />
          </div>
          <div v-if="training.status === 3 && !training.isConfigured" class="config-tip">
            <el-icon><Setting /></el-icon>
            <span>点击配置</span>
          </div>
        </div>
        <div class="avatar-type-badge" :class="training.type">
          <el-icon :size="16">
            <component :is="training.type === 'image' ? 'Picture' : 'VideoCamera'" />
          </el-icon>
        </div>
        <div class="avatar-preview" v-if="training.coverImg">
          <img :src="training.coverImg" :alt="training.avatarName">
        </div>
      </div>

      <!-- 已有的数字人列表 -->
      <div
        v-for="avatar in filteredAvatars"
        :key="avatar.id"
        class="avatar-item"
        :class="{ 'selected': selectedAvatar && selectedAvatar.id === avatar.id }"
        @click="handleAvatarClick(avatar)"
      >
        <div class="avatar-letter" :class="{ 'has-image': avatar.coverImg }">
          <template v-if="avatar.coverImg">
            <img :src="avatar.coverImg" :alt="avatar.name">
          </template>
          <template v-else>
            {{ avatar?.name?.[0] || '?' }}
          </template>
        </div>
        <div class="avatar-info">
          <span class="avatar-name">{{ avatar?.name || '未命名' }}</span>
          <span class="avatar-desc">{{ avatar?.description || '暂无描述' }}</span>
        </div>
        <div class="avatar-type-badge" :class="avatar.type">
          <el-icon :size="16">
            <component :is="avatar.type === 'image' ? 'Picture' : 'VideoCamera'" />
          </el-icon>
        </div>
        <div class="avatar-status" v-if="selectedAvatar === avatar.id">
          <el-icon class="status-icon"><Check /></el-icon>
        </div>
        <div class="avatar-actions" v-if="avatar.isCustom">
          <el-button
            link
            size="small"
            @click.stop="handleEditAvatar(avatar)"
          >
            <el-icon><Edit /></el-icon>
          </el-button>
        </div>
      </div>
    </div>

    <div class="preload-status" v-if="preloading">
      <el-progress
        :percentage="preloadProgress"
        :status="preloadStatus"
        :stroke-width="8"
      />
      <span class="status-text">{{ preloadMessage }}</span>
    </div>

    <el-dialog
      v-model="showTraining"
      title="训练新数字人"
      width="90%"
      max-width="800px"
      destroy-on-close
    >
      <TrainingUpload 
        :default-name="defaultTrainingName"
        @success="handleTrainingSuccess" 
      />
    </el-dialog>

    <el-dialog
      v-model="showSettings"
      title="系统设置"
      width="90%"
      max-width="600px"
      destroy-on-close
    >
      <div class="settings-content">
        <el-form label-position="top">
          <el-form-item label="默认数字人">
            <el-select v-model="defaultAvatarId" placeholder="选择默认数字人">
              <el-option
                v-for="avatar in avatars"
                :key="avatar.id"
                :label="avatar.name"
                :value="avatar.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="音频设置">
            <el-switch
              v-model="settings.enableAudio"
              active-text="启用音频"
              inactive-text="禁用音频"
            />
          </el-form-item>

          <el-form-item label="视频质量">
            <el-radio-group v-model="settings.videoQuality">
              <el-radio :value="'high'">高清</el-radio>
              <el-radio :value="'medium'">标清</el-radio>
              <el-radio :value="'low'">流畅</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="STUN 服务器">
            <div class="stun-settings">
              <el-switch
                v-model="settings.enableStun"
                active-text="启用 STUN"
                inactive-text="禁用 STUN"
                class="stun-switch"
              />
              <el-collapse v-if="settings.enableStun">
                <el-collapse-item title="STUN 服务器列表">
                  <div class="stun-servers">
                    <div v-for="(server, index) in settings.stunServers" :key="index" class="stun-server-item">
                      <el-input
                        v-model="settings.stunServers[index]"
                        placeholder="STUN 服务器地址"
                        size="default"
                      >
                        <template #append>
                          <el-button
                            type="danger"
                            :icon="Delete"
                            @click="removeStunServer(index)"
                          />
                        </template>
                      </el-input>
                    </div>
                    <el-button
                      type="primary"
                      :icon="Plus"
                      @click="addStunServer"
                      class="add-server-btn"
                    >
                      添加服务器
                    </el-button>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showSettings = false">取消</el-button>
          <el-button type="primary" @click="saveSettings">
            保存设置
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="showProgress" title="训练进度" width="90%" max-width="500px">
      <div class="progress-content" v-if="currentTraining">
        <div class="progress-status">
          <el-steps :active="currentStep" finish-status="success">
            <el-step title="准备上传" />
            <el-step title="文件预处理" />
            <el-step title="模型训练" />
            <el-step title="完成配置" />
          </el-steps>
        </div>
        <el-progress
          :percentage="getTrainingProgress(currentTraining).percentage"
          :status="getTrainingProgress(currentTraining).status"
          :stroke-width="10"
        />
        <div class="progress-info">
          <div class="progress-text">{{ currentTraining?.msg || getTrainingStatusText(currentTraining?.status) }}</div>
        </div>
        <div class="preview-image" v-if="currentTraining.coverImg">
          <img :src="currentTraining.coverImg" :alt="currentTraining.avatarName">
        </div>
        <div class="progress-actions" v-if="currentTraining.status === -1">
          <el-button type="primary" @click="handleRetryTraining">重新训练</el-button>
          <el-button type="danger" @click="handleDeleteTraining">删除</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 添加配置对话框 -->
    <AvatarConfigDialog
      v-model="showConfigDialog"
      :avatar="currentConfigAvatar"
      @submit="handleConfigSubmit"
      @delete="handleDeleteUnconfigured"
    />

    <!-- 添加编辑对话框 -->
    <AvatarEditDialog
      v-model="showEditDialog"
      :avatar="currentEditAvatar"
      @submit="handleEditSubmit"
      @delete="handleDeleteAvatar"
    />

    <!-- 在非移动端显示底部按钮 -->
    <div class="footer" v-if="!isMobile && false">
      <el-button
        type="primary"
        :disabled="!canStart"
        :loading="loading"
        @click="handleStart"
      >
        开始对话
      </el-button>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus, Setting, Search, Check, Delete, Edit, Picture, VideoCamera } from '@element-plus/icons-vue'
import { webrtcAPI, avatarAPI } from '@/api'
import config from '@/config'
import TrainingUpload from './TrainingUpload.vue'
import PromptSelector from './PromptSelector.vue'
import VoiceSelector from './VoiceSelector.vue'
import socketClient from '@/utils/socket'
import emitter from '@/utils/eventBus'
import axios from 'axios'
import AvatarForm from './AvatarForm.vue'
import AvatarList from './AvatarList.vue'
import AvatarConfigDialog from './AvatarConfigDialog.vue'
import AvatarEditDialog from './AvatarEditDialog.vue'

export default {
  name: 'AvatarSelector',
  components: {
    TrainingUpload,
    PromptSelector,
    VoiceSelector,
    Plus,
    Setting,
    Search,
    Check,
    Delete,
    Edit,
    Picture,
    VideoCamera,
    AvatarForm,
    AvatarList,
    AvatarConfigDialog,
    AvatarEditDialog
  },
  emits: ['preload-complete', 'select', 'retry-training'],
  setup(props, { emit }) {
    const store = useStore()
    const loading = ref(false)
    const preloading = ref(false)
    const preloadProgress = ref(0)
    const preloadStatus = ref('')
    const preloadMessage = ref('')
    const showTraining = ref(false)
    const showProgress = ref(false)
    const currentTraining = ref(null)
    const searchText = ref('')
    const showSettings = ref(false)
    const defaultAvatarId = ref('')
    const settings = ref({
      enableAudio: true,
      videoQuality: 'high',
      enableStun: true,
      stunServers: [
        'stun:stun.l.google.com:19302',
        'stun:stun1.l.google.com:19302',
        'stun:stun2.l.google.com:19302'
      ]
    })
    const defaultTrainingName = ref('')
    const defaultTemplates = ref([])
    const customTemplates = ref([])
    const selectedTemplate = ref(null)
    const selectedAvatarId = ref(props.modelValue)
    const showConfigDialog = ref(false)
    const showEditDialog = ref(false)
    const currentConfigAvatar = ref(null)
    const currentEditAvatar = ref(null)
    const isMobile = computed(() => window.innerWidth <= 768)
    const canStart = computed(() => !!selectedAvatarId.value)
    const filterStatus = ref('')
    const filterType = ref('')

    // 从 store 获取内置数字人列表
    const avatars = computed(() => {
      const allAvatars = store.getters['training/allAvatars']
      return Array.isArray(allAvatars) ? allAvatars : []
    })

    const selectedAvatar = computed(() => {
      const selectedId = store.state.selectedAvatar
      if (!selectedId) return null
      return store.getters['training/getAvatarById'](selectedId)
    })

    // 过滤后的数字人列表
    const filteredAvatars = computed(() => {
      let filtered = avatars.value

      // 根据配置状态筛选
      if (filterStatus.value) {
        filtered = filtered.filter(avatar => {
          if (filterStatus.value === 'configured') {
            return avatar.isConfigured
          } else if (filterStatus.value === 'unconfigured') {
            return !avatar.isConfigured
          } else if (filterStatus.value === 'training') {
            return trainingList.value.some(training => training.digitalId === avatar.id)
          }
          return true
        })
      }

      // 根据类型筛选
      if (filterType.value) {
        filtered = filtered.filter(avatar => avatar.type === filterType.value)
      }

      // 根据搜索文本筛选
      if (searchText.value) {
        const keyword = searchText.value.toLowerCase()
        filtered = filtered.filter(avatar => 
          avatar?.name?.toLowerCase().includes(keyword) || 
          avatar?.description?.toLowerCase().includes(keyword)
        )
      }

      return filtered
    })

    // 获取训练列表
    const trainingList = computed(() => {
      let list = store.state.training.trainingList
      if (!Array.isArray(list)) return []
      
      // 如果选择了类型筛选，也过滤训练中的数字人
      if (filterType.value) {
        list = list.filter(training => training.type === filterType.value)
      }
      
      return list
    })

    // 获取训练进度
    const getTrainingProgress = (training) => {
      if (!training) return { percentage: 0, status: '' }
      
      let percentage = 0
      let status = ''
      
      switch (training.status) {
        case 0: // 初始状态
          percentage = 0
          break
        case 1: // 上传成功，开始预处理
          percentage = 50 // 固定50%
          break
        case 2: // 预处理成功，开始训练
          percentage = 75 // 固定75%
          break
        case 3: // 训练完成
          percentage = 100
          status = 'success'
          break
        case -1: // 训练失败
          percentage = 0
          status = 'exception'
          break
      }
      
      return { percentage, status }
    }

    // 获取训练状态文本
    const getTrainingStatusText = (status) => {
      if (typeof status === 'undefined') return '未知状态'
      switch (status) {
        case 0:
          return '准备开始上传...'
        case 1:
          return '上传成功，开始预处理...'
        case 2:
          return '预处理完成，开始模型训练...'
        case 3:
          return '训练完成'
        case -1:
          return '训练失败'
        default:
          return '未知状态'
      }
    }

    // 获取所有头像列表
    const allAvatars = computed(() => {
      const avatars = store.getters['training/allAvatars']
      return Array.isArray(avatars) ? avatars : []
    })

    // 处理训练状态更新
    const handleTrainingStatus = (status) => {
      if (!status || !status.digitalId) return
      console.log('收到训练状态更新:', status)
      
      // 更新 store 中的训练状态
      store.dispatch('training/updateTrainingStatus', status)
      
      // 如果当前正在查看这个训练的进度，也更新当前查看的训练状态
      if (currentTraining.value?.digitalId === status.digitalId) {
        currentTraining.value = { ...currentTraining.value, ...status }
      }
      
      // 如果训练完成，只显示提示消息
      if (status.status === 3) {
        ElMessage.success('训练完成')
      } else if (status.status === -1) {
        ElMessage.error(status.msg || '训练失败')
      }
    }

    // 点击训练中的数字人
    const handleTrainingClick = (training) => {
      if (!training) return
      
      // 如果训练完成且未配置，显示配置对话框
      if (training.status === 3 && !training.isConfigured) {
        showConfigDialog.value = true
        currentConfigAvatar.value = {
          digitalId: training.digitalId,
          name: training.avatarName || '',
          coverImg: training.coverImg
        }
        // 初始化配置表单
        configForm.value = {
          name: training.avatarName || '',
          description: '',
          voice: '', // 不设置默认值，让用户必须选择
          systemPrompt: ''
        }
        return
      }
      
      // 其他状态显示进度对话框
      currentTraining.value = training
      showProgress.value = true
    }

    // 初始化数据
    const initializeData = async () => {
      try {
        await store.dispatch('training/initializeTraining')
      } catch (error) {
        console.error('初始化数据失败:', error)
        ElMessage.error('初始化数据失败，请刷新页面重试')
      }
    }

    // 修改 setupSocketListeners 方法
    const setupSocketListeners = () => {
      // 获取所有训练中的任务
      const trainings = store.state.training.trainingList
      
      // 为每个未完成的训练任务订阅状态更新
      trainings.forEach(training => {
        if (training.status !== 3 && training.status !== -1) {
          console.log('初始订阅训练状态:', training.digitalId)
          socketClient.subscribeToTraining(training.digitalId, handleTrainingStatus)
        }
      })
    }

    // 修改训练列表监听
    const subscribedTrainings = new Set()

    watch(() => store.state.training.trainingList, (newList, oldList) => {
      // 找出新增的训练任务
      newList.forEach(training => {
        if (!subscribedTrainings.has(training.digitalId) && 
            training.status !== 3 && 
            training.status !== -1) {
          console.log('新增训练状态订阅:', training.digitalId)
          subscribedTrainings.add(training.digitalId)
          socketClient.subscribeToTraining(training.digitalId, handleTrainingStatus)
        }
      })

      // 清理已完成或已移除的训练订阅
      oldList?.forEach(oldTraining => {
        const stillExists = newList.some(t => t.digitalId === oldTraining.digitalId)
        const isCompleted = newList.find(t => 
          t.digitalId === oldTraining.digitalId && 
          (t.status === 3 || t.status === -1)
        )
        
        if ((!stillExists || isCompleted) && subscribedTrainings.has(oldTraining.digitalId)) {
          console.log('清理训练状态订阅:', oldTraining.digitalId)
          subscribedTrainings.delete(oldTraining.digitalId)
          socketClient.unsubscribeFromTraining(oldTraining.digitalId)
        }
      })
    }, { deep: true })

    // 加载提示词模板
    const loadTemplates = async () => {
      try {
        const response = await axios.get('/api/prompt-templates')
        if (response.data.code === 0) {
          // 将所有模板数据赋值给 promptTemplates
          promptTemplates.value = response.data.data
          // 分离默认模板和自定义模板
          defaultTemplates.value = response.data.data.filter(t => t.isDefault)
          customTemplates.value = response.data.data.filter(t => !t.isDefault)
        }
      } catch (error) {
        console.error('加载提示词模板失败:', error)
        ElMessage.error('加载提示词模板失败')
      }
    }

    onMounted(async () => {
      try {
        // 连接 Socket.IO
        socketClient.connect()
        
        // 初始化数据
        await initializeData()
        
        // 设置默认数字人形象
        const defaultAvatar = avatars.value.find(avatar => avatar?.isDefault)
        if (defaultAvatar) {
          store.commit('SET_SELECTED_AVATAR', defaultAvatar.id)
          handlePreload()
        }
        
        loadSettings()
        setupSocketListeners()

        // 加载模板
        loadTemplates()
      } catch (error) {
        console.error('初始化失败:', error)
        ElMessage.error('初始化失败，请刷新页面重试')
      }
    })

    onUnmounted(() => {
      // 只清理订阅，不断开连接
      subscribedTrainings.forEach(digitalId => {
        socketClient.unsubscribeFromTraining(digitalId)
      })
      subscribedTrainings.clear()
    })

    const handleAvatarClick = async (avatar) => {
      // 检查是否是训练中的数字人
      const training = store.getters['training/getTrainingById'](avatar.id)
      if (training) {
        if (training.status === 3 && !training.isConfigured) {
          // 训练完成但未配置，显示配置对话框
          showConfigDialog.value = true
          currentConfigAvatar.value = {
            digitalId: avatar.id,
            name: training.avatarName || '',
            coverImg: training.coverImg
          }
          return
        } else if (training.status !== 3) {
          // 其他训练状态不允许选择
          return
        }
      }

      // 如果是同一个数字人，取消选择
      const currentSelectedId = store.state.selectedAvatar
      if (currentSelectedId === avatar.id) {
        store.commit('SET_SELECTED_AVATAR', null)
      } else {
        // 开始预加载
        preloading.value = true
        preloadProgress.value = 0
        preloadStatus.value = ''
        preloadMessage.value = '正在加载模型...'

        try {
          // 调用预加载 API，传递完整的数字人对象
          await webrtcAPI.createPreloader(avatar)
          
          // 更新选中的数字人
          store.commit('SET_SELECTED_AVATAR', avatar.id)
          
          // 更新预加载状态
          preloadProgress.value = 100
          preloadStatus.value = 'success'
          preloadMessage.value = '加载完成'
          emit('preload-complete', true)
        } catch (error) {
          console.error('预加载失败:', error)
          preloadProgress.value = 100
          preloadStatus.value = 'exception'
          preloadMessage.value = '加载失败'
          ElMessage.error('模型加载失败：' + error.message)
          emit('preload-complete', false)
        } finally {
          setTimeout(() => {
            preloading.value = false
          }, 1000)
        }
      }
    }

    // 添加配置对话框相关数据
    const configForm = ref({
      name: '',
      description: '',
      voice: '',
      systemPrompt: ''
    })

    // 处理配置提交
    const handleConfigSubmit = async (formData) => {
      if (!formData.name || !formData.description || !formData.voice) {
        ElMessage.warning('请填写完整的配置信息')
        return
      }

      try {
        const success = await store.dispatch('training/configureAvatar', {
          digitalId: currentConfigAvatar.value.digitalId,
          config: formData
        })
        
        if (success) {
          ElMessage.success('配置成功')
          showConfigDialog.value = false
          // 重置表单
          configForm.value = {
            name: '',
            description: '',
            voice: '',
            systemPrompt: ''
          }
          currentConfigAvatar.value = null
        }
      } catch (error) {
        console.error('配置失败:', error)
        ElMessage.error('配置失败: ' + error.message)
      }
    }

    const handlePreload = async () => {
      // Implementation of handlePreload function
    }

    const handleTrainingSuccess = () => {
      showTraining.value = false
      ElMessage.success('开始训练')
    }

    const loadSettings = () => {
      const savedSettings = localStorage.getItem('avatar-settings')
      if (savedSettings) {
        const parsed = JSON.parse(savedSettings)
        settings.value = { ...settings.value, ...parsed }
        defaultAvatarId.value = parsed.defaultAvatarId
      }
    }

    const saveSettings = () => {
      const settingsToSave = {
        ...settings.value,
        defaultAvatarId: defaultAvatarId.value
      }
      localStorage.setItem('avatar-settings', JSON.stringify(settingsToSave))
      
      // 如果设置了默认数字人，更新状态
      if (defaultAvatarId.value) {
        store.commit('SET_SELECTED_AVATAR', defaultAvatarId.value)
      }
      
      ElMessage.success('设置已保存')
      showSettings.value = false
    }

    const addStunServer = () => {
      settings.value.stunServers.push('')
    }

    const removeStunServer = (index) => {
      settings.value.stunServers.splice(index, 1)
    }

    const currentStep = computed(() => {
      if (!currentTraining.value) return 0
      
      switch (currentTraining.value.status) {
        case 0: // 初始状态
          return 1
        case 1: // 上传成功，开始预处理
          return 2
        case 2: // 预处理成功，开始训练
          return 3
        case 3: // 训练完成
          return 4
        case -1: // 训练失败
          return 1
        default:
          return 0
      }
    })

    // 添加编辑对话框相关数据
    const editFormRef = ref(null)
    const editForm = ref({
      name: '',
      description: '',
      voice: '',
      systemPrompt: ''
    })

    const promptTemplates = ref([])

    const configRules = {
      name: [
        { required: true, message: '请输入数字人名称', trigger: 'blur' },
        { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
      ],
      description: [
        { required: true, message: '请输入数字人描述', trigger: 'blur' },
        { min: 2, max: 200, message: '长度在 2 到 200 个字符', trigger: 'blur' }
      ],
      voice: [
        { required: true, message: '请选择语音', trigger: 'change' }
      ],
      systemPrompt: [
        { required: true, message: '请输入系统提示词', trigger: 'blur' },
        { min: 10, max: 2000, message: '长度在 10 到 2000 个字符', trigger: 'blur' }
      ]
    }

    const handleEditAvatar = (avatar) => {
      currentEditAvatar.value = avatar
      // 初始化编辑表单，使用当前数字人的语音设置
      editForm.value = {
        name: avatar.name || '',
        description: avatar.description || '',
        voice: avatar.voice || '',
        systemPrompt: avatar.systemPrompt || ''
      }
      // 重置模板选择
      selectedTemplate.value = null
      showEditDialog.value = true
    }

    const handleEditSubmit = async (formData) => {
      if (!formData.name || !formData.description || !formData.voice) {
        ElMessage.warning('请填写完整的编辑信息')
        return
      }

      try {
        const success = await store.dispatch('training/configureAvatar', {
          digitalId: currentEditAvatar.value.id,
          config: formData
        })
        
        if (success) {
          ElMessage.success('更新成功')
          showEditDialog.value = false
          currentEditAvatar.value = null
        }
      } catch (error) {
        console.error('更新失败:', error)
        ElMessage.error('更新失败: ' + error.message)
      }
    }

    // 处理训练失败的重试
    const handleRetryTraining = async () => {
      try {
        const result = await store.dispatch('training/retryTraining', currentTraining.value)
        if (result.success) {
          // 关闭进度对话框
          showProgress.value = false
          // 设置默认名称
          defaultTrainingName.value = result.avatarName || ''
          // 打开上传对话框
          showTraining.value = true
          ElMessage.success('请重新选择视频文件开始训练')
        }
      } catch (error) {
        console.error('重试训练失败:', error)
        ElMessage.error('重试训练失败：' + error.message)
      }
    }

    // 处理训练中数字人的删除
    const handleDeleteTraining = async () => {
      try {
        await ElMessageBox.confirm('确定要删除这个训练任务吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        
        const success = await store.dispatch('training/deleteAvatar', {
          digitalId: currentTraining.value.digitalId,
          isTraining: true
        })
        
        if (success) {
          ElMessage.success('删除成功')
          showProgress.value = false
        }
      } catch (error) {
        if (error !== 'cancel') {
          ElMessage.error('删除失败：' + error.message)
        }
      }
    }

    // 处理删除
    const handleDeleteAvatar = async () => {
      try {
        await ElMessageBox.confirm('确定要删除这个数字人吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        
        const success = await store.dispatch('training/deleteAvatar', {
          digitalId: currentEditAvatar.value.id
        })
        
        if (success) {
          ElMessage.success('删除成功')
          showEditDialog.value = false
          currentEditAvatar.value = null
          
          // 如果删除的是当前选中的数字人，清除选择
          if (selectedAvatarId.value === currentEditAvatar.value.id) {
            selectedAvatarId.value = ''
            emit('update:modelValue', '')
          }
        }
      } catch (error) {
        console.error('删除失败:', error)
        ElMessage.error('删除失败')
      }
    }

    // 处理模板选择变化
    const handleTemplateChange = (template) => {
      if (!template) return
      
      try {
        // 获取当前正在使用的表单（配置表单或编辑表单）
        const currentForm = showConfigDialog.value ? configForm : editForm
        
        // 获取模板内容并替换变量
        let prompt = template.content || ''
        if (currentForm.value?.name) {
          prompt = prompt.replace(/{name}/g, currentForm.value.name)
        }
        
        // 更新对应表单的系统提示词
        currentForm.value.systemPrompt = prompt
      } catch (error) {
        console.error('处理模板变化失败:', error)
      }
    }

    // 监听名称变化，更新提示词中的变量
    watch(() => configForm.value.name, (newName) => {
      try {
        if (selectedTemplate.value && newName) {
          handleTemplateChange(selectedTemplate.value)
        }
      } catch (error) {
        console.error('监听配置表单名称变化失败:', error)
      }
    })

    // 监听编辑表单名称变化
    watch(() => editForm.value.name, (newName) => {
      try {
        if (selectedTemplate.value && newName) {
          handleTemplateChange(selectedTemplate.value)
        }
      } catch (error) {
        console.error('监听编辑表单名称变化失败:', error)
      }
    })

    const handleStart = async () => {
      if (!selectedAvatarId.value) {
        ElMessage.warning('请先选择一个数字人')
        return
      }

      loading.value = true
      try {
        const avatar = store.getters['training/getAvatarById'](selectedAvatarId.value)
        if (!avatar) {
          throw new Error('未找到选中的数字人')
        }
        emit('preload-complete')
      } catch (error) {
        console.error('预加载失败:', error)
        ElMessage.error('预加载失败: ' + error.message)
      } finally {
        loading.value = false
      }
    }

    // 处理未配置数字人的删除
    const handleDeleteUnconfigured = async () => {
      try {
        const success = await store.dispatch('training/deleteAvatar', {
          digitalId: currentConfigAvatar.value.digitalId,
          isTraining: true
        })
        
        if (success) {
          ElMessage.success('删除成功')
          showConfigDialog.value = false
          currentConfigAvatar.value = null
        }
      } catch (error) {
        console.error('删除失败:', error)
        ElMessage.error('删除失败: ' + error.message)
      }
    }

    return {
      avatars,
      filteredAvatars,
      selectedAvatar,
      preloading,
      preloadProgress,
      preloadStatus,
      preloadMessage,
      showTraining,
      showProgress,
      currentTraining,
      searchText,
      showSettings,
      defaultAvatarId,
      settings,
      defaultTrainingName,
      defaultTemplates,
      handleAvatarClick,
      handlePreload,
      handleTrainingSuccess,
      saveSettings,
      addStunServer,
      removeStunServer,
      trainingList,
      getTrainingProgress,
      getTrainingStatusText,
      handleTrainingStatus,
      handleTrainingClick,
      Search,
      Setting,
      Plus,
      Check,
      Delete,
      currentStep,
      showConfigDialog,
      currentConfigAvatar,
      configForm,
      handleConfigSubmit,
      showEditDialog,
      currentEditAvatar,
      editFormRef,
      editForm,
      configRules,
      handleEditAvatar,
      handleEditSubmit,
      handleRetryTraining,
      handleDeleteTraining,
      handleDeleteAvatar,
      selectedTemplate,
      promptTemplates,
      handleTemplateChange,
      isMobile,
      loading,
      canStart,
      handleStart,
      filterStatus,
      filterType,
      handleDeleteUnconfigured
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-selector {
  padding: 20px;

  .header {
    margin-bottom: 24px;

    .title-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      h3 {
        margin: 0;
        color: var(--text-primary);
        font-size: 20px;
        font-weight: 600;
      }

      .setting-btn {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 8px 16px;
        border-radius: var(--border-radius-md);
        background: var(--card-background);
        border: 1px solid var(--el-border-color);
        transition: all 0.3s ease;
        
        .el-icon {
          font-size: 16px;
          color: var(--text-secondary);
        }

        .btn-text {
          font-size: 14px;
          color: var(--text-secondary);
        }
        
        &:hover {
          background: var(--primary-color);
          border-color: var(--primary-color);
          
          .el-icon,
          .btn-text {
            color: white;
          }
        }
      }
    }

    .search-section {
      .filter-row {
        display: flex;
        gap: 12px;
        margin-bottom: 16px;

        .search-input {
          flex: 1;
        }

        .filter-select {
          width: 120px;

          :deep(.option-icon) {
            margin-right: 8px;
            font-size: 16px;
            vertical-align: middle;

            &.image {
              color: #1890ff;
            }

            &.video {
              color: #52c41a;
            }
          }
        }

        :deep(.el-input__wrapper) {
          border-radius: var(--border-radius-md);
          box-shadow: var(--shadow-card);
          background: var(--card-background);
        }
      }
    }
  }

  .avatar-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 24px;
    margin-bottom: 24px;
  }

  .avatar-item {
    position: relative;
    border-radius: var(--border-radius-md);
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
    background: var(--card-background);
    box-shadow: var(--shadow-card);
    padding: 24px;
    text-align: center;
    border: 2px solid transparent;

    &:hover {
      transform: translateY(-5px);
      box-shadow: var(--shadow-button);
    }

    &.selected {
      border-color: var(--primary-color);
      background: rgba(0, 114, 255, 0.05);
      box-shadow: 0 8px 24px rgba(0, 114, 255, 0.15);

      .avatar-letter {
        transform: scale(1.05);
        box-shadow: 0 8px 20px rgba(0, 114, 255, 0.25);

        &.has-image {
          border-width: 3px;
          border-color: var(--primary-color);
          
          img {
            transform: scale(1.05);
          }
        }
      }

      .avatar-info {
        .avatar-name {
          color: var(--primary-color);
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--border-radius-md);
        border: 2px solid var(--primary-color);
        box-shadow: 0 0 0 4px rgba(0, 114, 255, 0.1);
        pointer-events: none;
      }
    }

    &.training-item {
      border: 2px dashed var(--primary-color);
      background: rgba(0, 114, 255, 0.05);

      .training-icon {
        background: var(--primary-color);
        font-size: 28px;
      }

      &:hover {
        background: rgba(0, 114, 255, 0.1);
      }
    }

    &.training-progress-item {
      background: rgba(0, 114, 255, 0.05);
      border: 1px solid var(--primary-color);

      .avatar-letter {
        &.has-image {
          background: none;
          border: 2px solid var(--primary-color);
          padding: 4px;
          overflow: hidden;
          
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: transform 0.3s ease;
            background-color: #fff;
          }

          &:hover img {
            transform: scale(1.05);
          }
        }
      }

      .training-progress {
        margin-top: 8px;
      }

      &.training-completed {
        background: rgba(103, 194, 58, 0.05);
        border-color: var(--el-color-success);

        .avatar-letter {
          background: var(--el-color-success);

          &.has-image {
            background: none;
            border-color: var(--el-color-success);
          }
        }

        .config-tip {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          margin-top: 8px;
          color: var(--el-color-success);
          font-size: 14px;

          .el-icon {
            font-size: 16px;
          }
        }
      }

      &.training-failed {
        background: rgba(255, 65, 65, 0.05);
        border-color: var(--accent-color);

        .avatar-letter {
          background: var(--accent-color);

          &.has-image {
            background: none;
            border-color: var(--accent-color);
          }
        }
      }
    }

    .avatar-letter {
      width: 88px;
      height: 88px;
      margin: 0 auto 16px;
      border-radius: 50%;
      background: linear-gradient(135deg, var(--primary-light), var(--primary-color));
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-weight: bold;
      box-shadow: var(--shadow-button);
      transition: transform 0.3s ease;
      overflow: hidden;

      &.has-image {
        background: none;
        border: 2px solid var(--primary-color);
        padding: 4px;
        overflow: hidden;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transition: transform 0.3s ease;
          background-color: #fff;
        }

        &:hover img {
          transform: scale(1.05);
        }
      }
    }

    .avatar-info {
      .avatar-name {
        display: block;
        font-weight: 600;
        color: var(--text-primary);
        margin-bottom: 6px;
        font-size: 16px;
      }

      .avatar-desc {
        display: block;
        font-size: 13px;
        color: var(--text-secondary);
        line-height: 1.4;
      }
    }

    .avatar-status {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      
      .status-icon {
        color: white;
        font-size: 16px;
      }
    }

    .avatar-actions {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      
      .el-button {
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        color: white;
        font-size: 16px;
      }
    }

    .avatar-preview {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0.1;
      overflow: hidden;
      border-radius: var(--border-radius-md);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .avatar-type-badge {
      position: absolute;
      left: 16px;
      bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 8px;
      background: var(--el-fill-color-light);
      z-index: 2;
      transition: all 0.3s ease;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);

      &.image {
        color: #1890ff;
      }

      &.video {
        color: #52c41a;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .preload-status {
    margin-top: 24px;
    padding: 20px;
    border-radius: var(--border-radius-md);
    background: var(--card-background);
    box-shadow: var(--shadow-card);

    .status-text {
      display: block;
      margin-top: 12px;
      text-align: center;
      color: var(--text-secondary);
      font-size: 14px;
    }
  }

  .footer {
    padding: 20px;
    border-top: 1px solid var(--el-border-color-light);
    text-align: center;
    background: var(--el-bg-color);

    .el-button {
      min-width: 200px;
      height: 44px;
      font-size: 16px;
    }
  }
}

.settings-content {
  padding: 20px 0;

  :deep(.el-form-item) {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .el-form-item__label {
      font-weight: 500;
      color: var(--text-primary);
      padding-bottom: 8px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-radio-group {
    display: flex;
    gap: 16px;
  }

  .stun-settings {
    .stun-switch {
      margin-bottom: 16px;
    }

    .stun-servers {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .stun-server-item {
      display: flex;
      gap: 8px;
    }

    .add-server-btn {
      margin-top: 8px;
      width: fit-content;
    }

    :deep(.el-collapse) {
      border: none;
      
      .el-collapse-item__header {
        font-size: 14px;
        color: var(--text-secondary);
        border: none;
        padding: 8px 0;
      }

      .el-collapse-item__content {
        padding: 16px;
        background: var(--card-background);
        border-radius: var(--border-radius-md);
        margin-top: 8px;
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding-top: 20px;
}

.progress-content {
  padding: 20px;

  .progress-status {
    margin-bottom: 24px;
  }

  .progress-info {
    margin-top: 16px;
    text-align: center;
  }

  .preview-image {
    margin-top: 20px;
    text-align: center;
    background-color: #fff;
    padding: 4px;
    border-radius: 8px;

    img {
      max-width: 100%;
      max-height: 300px;
      border-radius: 8px;
      object-fit: contain;
    }
  }

  .progress-actions {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
  }
}

.config-dialog-content {
  padding: 24px;
  background: var(--el-fill-color-light);
  border-radius: var(--border-radius-md);

  .preview-image {
    margin-bottom: 24px;
    text-align: center;
    
    img {
      max-width: 200px;
      max-height: 200px;
      border-radius: 8px;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
      object-fit: contain;
      padding: 4px;
      background: white;
    }
  }

  :deep(.el-form) {
    max-width: 400px;
    margin: 0 auto;

    .el-form-item {
      margin-bottom: 20px;
      
      .el-form-item__label {
        font-size: 14px;
        color: var(--text-primary);
        padding-bottom: 8px;
        font-weight: 500;
      }

      .el-input__wrapper,
      .el-textarea__inner {
        background-color: var(--el-fill-color-blank);
        border: 1px solid var(--el-border-color);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
        transition: all 0.3s ease;

        &:hover, &:focus {
          border-color: var(--primary-color);
          box-shadow: 0 2px 8px rgba(0, 114, 255, 0.1);
        }
      }
    }
  }
}

.dialog-footer {
  padding: 20px 0 0;
  text-align: right;
  border-top: 1px solid var(--el-border-color-light);
}

.prompt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  
  span {
    font-size: 14px;
    color: #606266;
  }
}

.template-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .el-icon-info {
    color: #909399;
    margin-left: 8px;
    font-size: 14px;
    cursor: help;
  }
}

.template-description {
  margin-left: 8px;
  color: var(--el-text-color-secondary);
  font-size: 12px;
}

.prompt-tips {
  margin-top: 8px;
  padding: 8px;
  background: var(--el-fill-color-light);
  border-radius: 4px;
  font-size: 12px;
  color: var(--el-text-color-secondary);

  p {
    margin: 4px 0;
  }
}

:deep(.el-select-dropdown__item) {
  display: flex;
  align-items: center;
  padding: 8px 12px;
}

@include mobile {
  .avatar-selector {
    padding: 16px;

    .header {
      margin-bottom: 20px;

      .title-section {
        margin-bottom: 12px;

        h3 {
          font-size: 18px;
        }

        .setting-btn {
          padding: 6px 12px;
          
          .btn-text {
            font-size: 13px;
          }
        }
      }
    }
  }

  .settings-content {
    padding: 16px 0;

    :deep(.el-form-item) {
      margin-bottom: 20px;
    }

    .el-radio-group {
      flex-direction: column;
      gap: 12px;
    }

    .stun-settings {
      .stun-servers {
        gap: 8px;
      }

      .add-server-btn {
        width: 100%;
      }
    }
  }

  .config-dialog-content {
    padding: 16px;

    .preview-image img {
      max-width: 150px;
      max-height: 150px;
    }

    :deep(.el-form) {
      .el-form-item {
        margin-bottom: 16px;
      }
    }
  }

  .dialog-footer {
    padding-top: 16px;
    gap: 8px;
  }

  .progress-content {
    .progress-actions {
      flex-direction: column;
      gap: 12px;
      
      .el-button {
        width: 100%;
      }
    }
  }
}
</style> 