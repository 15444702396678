import { createStore } from 'vuex'
import training from './modules/training'

export default createStore({
  modules: {
    training
  },
  state: {
    user: null,
    selectedAvatar: null,
    messages: [],
    webrtcState: {
      pc: null,
      dc: null,
      sessionId: null
    },
    userMessages: [],
    backgroundImage: null,
    isConnected: false,
    currentBackground: '/assets/images/image.png',
    backgroundHistory: [],
  },
  
  getters: {
    currentUser: state => state.user,
    getSelectedAvatar: state => state.selectedAvatar,
    getMessages: state => state.messages,
    getConnectionStatus: state => state.isConnected
  },
  
  mutations: {
    SET_USER(state, user) {
      state.user = user
      if (user) {
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        localStorage.removeItem('user')
      }
    },
    
    SET_SELECTED_AVATAR(state, avatarId) {
      state.selectedAvatar = avatarId
    },
    
    ADD_MESSAGE(state, message) {
      console.log('Store - ADD_MESSAGE:', message)
      if (message.type === 'system' || message.type === 'user') {
        console.log('Store - 添加消息到列表:', message)
        state.messages.push(message)
        console.log('Store - 当前消息列表:', state.messages)
      } else {
        console.log('Store - 忽略未知消息类型:', message)
      }
    },
    
    CLEAR_MESSAGES(state) {
      console.log('Store - 清空消息列表')
      state.messages = []
    },
    
    SET_WEBRTC_STATE(state, { pc, dc, sessionId }) {
      console.log('Store - 更新 WebRTC 状态:', { pc: !!pc, dc: !!dc, sessionId })
      state.webrtcState = { pc, dc, sessionId }
    },
    
    SET_CONNECTION_STATUS(state, status) {
      state.isConnected = status
    },
    
    SET_SESSION_ID(state, sessionId) {
      console.log('设置会话ID:', sessionId)
      state.webrtcState.sessionId = sessionId
    },
    
    updateConnectionStatus(state, status) {
      state.isConnected = status
    },
    
    SET_BACKGROUND(state, backgroundUrl) {
      state.backgroundHistory.push(state.currentBackground)
      if (state.backgroundHistory.length > 10) {
        state.backgroundHistory.shift()
      }
      state.currentBackground = backgroundUrl
    },
    
    RESTORE_PREVIOUS_BACKGROUND(state) {
      if (state.backgroundHistory.length > 0) {
        const previousBackground = state.backgroundHistory.pop()
        state.currentBackground = previousBackground
      }
    }
  },
  
  actions: {
    login({ commit }, user) {
      commit('SET_USER', user)
    },
    
    logout({ commit }) {
      commit('SET_USER', null)
      commit('SET_SELECTED_AVATAR', null)
      commit('CLEAR_MESSAGES')
      commit('SET_CONNECTION_STATUS', false)
      commit('SET_WEBRTC_STATE', { pc: null, dc: null, sessionId: null })
    },
    
    updateWebRTCState({ commit }, state) {
      commit('SET_WEBRTC_STATE', state)
    }
  }
}) 