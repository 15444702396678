<template>
  <div class="prompt-manager">
    <div class="header">
      <h3>大模型设置</h3>
    </div>

    <!-- 大模型设置 -->
    <div class="section">
      <ModelManager />
    </div>

    <el-divider />

    <div class="template-header">
      <h3>提示词模板</h3>
      <el-button type="primary" @click="showAddDialog = true">
        <el-icon><Plus /></el-icon>
        <span class="button-text">新建模板</span>
      </el-button>
    </div>

    <!-- 移动端列表视图 -->
    <div class="template-list-mobile" v-if="isMobile">
      <div v-for="template in templates" :key="template.id" class="template-card">
        <div class="template-header">
          <h4>{{ template.name }}</h4>
          <el-tag :type="getCategoryType(template.category)" size="small">
            {{ getCategoryName(template.category) }}
          </el-tag>
        </div>
        <p class="template-desc">{{ template.description }}</p>
        <div class="template-actions">
          <el-button 
            type="primary" 
            link
            @click="handleEdit(template)"
            :disabled="template.isDefault"
          >
            编辑
          </el-button>
          <el-button 
            type="danger" 
            link
            @click="handleDelete(template)"
            :disabled="template.isDefault"
          >
            删除
          </el-button>
        </div>
      </div>
    </div>

    <!-- 桌面端表格视图 -->
    <div class="template-list" v-else>
      <el-table :data="templates" style="width: 100%">
        <el-table-column prop="name" label="模板名称" min-width="120" />
        <el-table-column prop="description" label="描述" min-width="180" />
        <el-table-column prop="category" label="分类" width="120">
          <template #default="{ row }">
            <el-tag :type="getCategoryType(row.category)">{{ getCategoryName(row.category) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" fixed="right">
          <template #default="{ row }">
            <el-button 
              link 
              type="primary" 
              @click="handleEdit(row)"
              :disabled="row.isDefault"
            >
              编辑
            </el-button>
            <el-button 
              link 
              type="danger" 
              @click="handleDelete(row)"
              :disabled="row.isDefault"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 新建/编辑对话框 -->
    <el-dialog
      v-model="showAddDialog"
      :title="isEdit ? '编辑模板' : '新建模板'"
      :width="isMobile ? '90%' : '600px'"
      :top="isMobile ? '10vh' : '15vh'"
      class="prompt-dialog"
    >
      <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        label-position="top"
        :class="{ 'mobile-form': isMobile }"
      >
        <el-form-item label="模板名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入模板名称" />
        </el-form-item>
        
        <el-form-item label="描述" prop="description">
          <el-input
            v-model="form.description"
            type="textarea"
            :rows="2"
            placeholder="请输入模板描述"
          />
        </el-form-item>

        <el-form-item label="分类" prop="category">
          <el-select v-model="form.category" placeholder="请选择分类" style="width: 100%">
            <el-option label="通用" value="general" />
            <el-option label="客服" value="customer_service" />
            <el-option label="教育" value="education" />
            <el-option label="销售" value="sales" />
          </el-select>
        </el-form-item>
        
        <el-form-item label="提示词内容" prop="content">
          <el-input
            v-model="form.content"
            type="textarea"
            :rows="6"
            placeholder="请输入提示词内容"
          />
          <div class="prompt-tips">
            <p>支持使用变量：</p>
            <p>{name} - 数字人名称</p>
          </div>
        </el-form-item>
      </el-form>
      
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showAddDialog = false" class="mobile-full-width">取消</el-button>
          <el-button type="primary" @click="handleSubmit" class="mobile-full-width">确认</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from 'axios'
import ModelManager from './ModelManager.vue'

export default {
  name: 'PromptManager',
  components: {
    Plus,
    ModelManager
  },
  setup() {
    const templates = ref([])
    const showAddDialog = ref(false)
    const isEdit = ref(false)
    const currentTemplate = ref(null)
    const formRef = ref(null)
    
    const form = ref({
      name: '',
      description: '',
      category: 'general',
      content: ''
    })

    const rules = {
      name: [
        { required: true, message: '请输入模板名称', trigger: 'blur' },
        { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
      ],
      description: [
        { required: true, message: '请输入模板描述', trigger: 'blur' },
        { max: 200, message: '不能超过 200 个字符', trigger: 'blur' }
      ],
      category: [
        { required: true, message: '请选择分类', trigger: 'change' }
      ],
      content: [
        { required: true, message: '请输入提示词内容', trigger: 'blur' },
        { min: 10, max: 2000, message: '长度在 10 到 2000 个字符', trigger: 'blur' }
      ]
    }

    // 加载模板列表
    const loadTemplates = async () => {
      try {
        const response = await axios.get('/api/prompt-templates')
        if (response.data.code === 0) {
          templates.value = response.data.data
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        console.error('加载模板列表失败:', error)
        ElMessage.error('加载模板列表失败')
      }
    }

    // 获取分类显示名称
    const getCategoryName = (category) => {
      const map = {
        general: '通用',
        customer_service: '客服',
        education: '教育',
        sales: '销售'
      }
      return map[category] || category
    }

    // 获取分类标签类型
    const getCategoryType = (category) => {
      const map = {
        general: 'info',
        customer_service: 'success',
        education: 'warning',
        sales: 'danger'
      }
      return map[category] || 'info'
    }

    // 处理编辑
    const handleEdit = (template) => {
      isEdit.value = true
      currentTemplate.value = template
      form.value = {
        name: template.name,
        description: template.description,
        category: template.category,
        content: template.content
      }
      showAddDialog.value = true
    }

    // 处理删除
    const handleDelete = async (template) => {
      try {
        await ElMessageBox.confirm('确定要删除这个模板吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        
        const response = await axios.delete(`/api/prompt-templates/${template.id}`)
        if (response.data.code === 0) {
          ElMessage.success('删除成功')
          loadTemplates()
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除模板失败:', error)
          ElMessage.error('删除模板失败')
        }
      }
    }

    // 处理提交
    const handleSubmit = async () => {
      if (!formRef.value) return
      
      try {
        await formRef.value.validate()
        
        const url = isEdit.value 
          ? `/api/prompt-templates/${currentTemplate.value.id}`
          : '/api/prompt-templates'
        
        const response = await axios({
          method: isEdit.value ? 'put' : 'post',
          url,
          data: form.value
        })
        
        if (response.data.code === 0) {
          ElMessage.success(isEdit.value ? '更新成功' : '创建成功')
          showAddDialog.value = false
          loadTemplates()
          // 重置表单
          form.value = {
            name: '',
            description: '',
            category: 'general',
            content: ''
          }
          isEdit.value = false
          currentTemplate.value = null
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        console.error(isEdit.value ? '更新模板失败:' : '创建模板失败:', error)
        ElMessage.error(isEdit.value ? '更新模板失败' : '创建模板失败')
      }
    }

    // 添加移动端检测
    const isMobile = computed(() => {
      return window.innerWidth <= 768
    })

    onMounted(() => {
      loadTemplates()
    })

    return {
      templates,
      showAddDialog,
      isEdit,
      form,
      formRef,
      rules,
      getCategoryName,
      getCategoryType,
      handleEdit,
      handleDelete,
      handleSubmit,
      isMobile
    }
  }
}
</script>

<style lang="scss" scoped>
.prompt-manager {
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 12px;

    @media screen and (max-width: 768px) {
      h3 {
        font-size: 16px;
      }
    }
  }

  .template-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
    gap: 12px;

    h3 {
      margin: 0;
      font-size: 16px;
      color: var(--el-text-color-primary);
    }

    @media screen and (max-width: 768px) {
      .button-text {
        display: none;
      }
    }
  }

  .section {
    margin-bottom: 20px;
  }

  .template-list-mobile {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .template-card {
      background: var(--el-bg-color);
      border-radius: 8px;
      padding: 16px;
      box-shadow: var(--el-box-shadow-light);

      .template-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        h4 {
          margin: 0;
          font-size: 16px;
          color: var(--el-text-color-primary);
        }
      }

      .template-desc {
        margin: 8px 0;
        font-size: 14px;
        color: var(--el-text-color-secondary);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .template-actions {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 12px;
        padding-top: 12px;
        border-top: 1px solid var(--el-border-color-lighter);
      }
    }
  }

  :deep(.el-divider) {
    margin: 24px 0;
    border-top: 1px solid var(--el-border-color-light);
  }
}

.prompt-dialog {
  :deep(.el-dialog__body) {
    padding-top: 10px;
  }

  .mobile-form {
    .el-form-item {
      margin-bottom: 16px;
    }

    .el-input__wrapper,
    .el-textarea__inner {
      font-size: 16px; // 增大移动端输入框字体
    }
  }
}

.dialog-footer {
  padding-top: 20px;
  text-align: right;
  display: flex;
  gap: 12px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    
    .mobile-full-width {
      width: 100%;
      margin: 0;
    }
  }
}

// 移动端全屏对话框样式优化
@media screen and (max-width: 768px) {
  :deep(.el-dialog__header) {
    margin: 0;
    padding: 16px;
    border-bottom: 1px solid var(--el-border-color-light);
    position: sticky;
    top: 0;
    background: var(--el-bg-color);
    z-index: 1;
  }

  :deep(.el-dialog__body) {
    padding: 16px;
    max-height: 60vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  :deep(.el-dialog__footer) {
    padding: 16px;
    border-top: 1px solid var(--el-border-color-light);
    position: sticky;
    bottom: 0;
    background: var(--el-bg-color);
    z-index: 1;
  }

  .prompt-tips {
    font-size: 14px;
    padding: 12px;
    background: var(--el-fill-color-light);
    border-radius: 4px;
    margin-top: 12px;
  }

  :deep(.el-dialog) {
    border-radius: 8px;
    overflow: hidden;
  }
}
</style> 