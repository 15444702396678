<template>
  <el-dialog
    v-model="visible"
    title="配置数字人"
    :width="isMobile ? '100%' : '900px'"
    :close-on-click-modal="false"
    @close="handleClose"
    class="avatar-config-dialog"
    :top="isMobile ? '0' : '15vh'"
    :fullscreen="isMobile"
  >
    <div class="config-dialog-content">
      <div class="preview-section">
        <div class="preview-image" v-if="avatar?.coverImg">
          <img :src="avatar.coverImg" alt="数字人预览">
        </div>
        <div class="preview-info">
          <h3>预览信息</h3>
          <p class="preview-item">
            <span class="label">创建时间：</span>
            <span class="value">{{ formatDate(avatar?.createTime) }}</span>
          </p>
          <p class="preview-item">
            <span class="label">当前状态：</span>
            <span class="value">
              <el-tag size="small" type="warning">待配置</el-tag>
            </span>
          </p>
          <div class="preview-tips">
            <el-alert
              title="首次配置提示"
              type="info"
              :closable="false"
              show-icon
            >
              <p>请完善数字人的基本信息，包括名称、描述、语音和系统提示词。配置完成后即可开始对话。</p>
            </el-alert>
          </div>
        </div>
      </div>
      
      <div class="form-section">
        <AvatarForm
          v-model="configForm"
          ref="configFormRef"
        />
      </div>
    </div>
    
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="danger" @click="handleDelete">删除</el-button>
        <el-button type="primary" @click="handleSubmit">确认</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import AvatarForm from './AvatarForm.vue'

export default {
  name: 'AvatarConfigDialog',
  components: {
    AvatarForm
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    avatar: {
      type: Object,
      default: null
    }
  },
  emits: ['update:modelValue', 'submit', 'delete'],
  setup(props, { emit }) {
    const visible = ref(false)
    const configFormRef = ref(null)
    const configForm = ref({
      name: '',
      description: '',
      voice: '',
      systemPrompt: ''
    })

    // 添加移动端检测
    const isMobile = computed(() => {
      return window.innerWidth <= 768
    })

    // 监听对话框显示状态
    watch(() => props.modelValue, (val) => {
      visible.value = val
      if (val && props.avatar) {
        // 当对话框打开且有数据时，初始化表单
        configForm.value = {
          name: props.avatar.name || '',
          description: props.avatar.description || '',
          voice: props.avatar.voice || '',
          systemPrompt: props.avatar.systemPrompt || ''
        }
      }
    })

    watch(() => visible.value, (val) => {
      emit('update:modelValue', val)
    })

    const handleClose = () => {
      visible.value = false
      // 重置表单数据
      configForm.value = {
        name: '',
        description: '',
        voice: '',
        systemPrompt: ''
      }
    }

    const handleSubmit = async () => {
      if (!configFormRef.value) return
      
      const valid = await configFormRef.value.validate()
      if (!valid) {
        ElMessage.warning('请填写完整的配置信息')
        return
      }

      emit('submit', configForm.value)
    }

    const handleDelete = async () => {
      try {
        await ElMessageBox.confirm('确定要删除这个数字人吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        emit('delete')
      } catch {
        // 用户取消删除
      }
    }

    // 格式化日期
    const formatDate = (date) => {
      if (!date) return '-'
      const d = new Date(date)
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
    }

    return {
      visible,
      configForm,
      configFormRef,
      handleClose,
      handleSubmit,
      handleDelete,
      formatDate,
      isMobile
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-config-dialog {
  :deep(.el-dialog__body) {
    padding: 0;
  }

  :deep(.el-dialog) {
    @media screen and (max-width: 768px) {
      margin: 0 !important;
      
      .el-dialog__header {
        margin: 0;
        padding: 16px;
        border-bottom: 1px solid var(--el-border-color-light);
        position: sticky;
        top: 0;
        background: var(--el-bg-color);
        z-index: 1;
      }

      .el-dialog__body {
        height: calc(100vh - 120px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }

      .el-dialog__footer {
        position: sticky;
        bottom: 0;
        background: var(--el-bg-color);
        z-index: 1;
        border-top: 1px solid var(--el-border-color-light);
      }
    }
  }
}

.config-dialog-content {
  display: flex;
  min-height: 500px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    min-height: auto;
  }

  .preview-section {
    width: 300px;
    background: var(--el-fill-color-light);
    border-right: 1px solid var(--el-border-color-light);
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid var(--el-border-color-light);
    }

    .preview-image {
      width: 100%;
      height: 300px;
      overflow: hidden;
      background: #f5f7fa;
      border-bottom: 1px solid var(--el-border-color-light);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      
      @media screen and (max-width: 768px) {
        height: 160px;
      }
      
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 8px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
      }
    }

    .preview-info {
      padding: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        padding: 12px;
      }

      h3 {
        margin: 0 0 16px;
        font-size: 16px;
        color: var(--el-text-color-primary);

        @media screen and (max-width: 768px) {
          font-size: 14px;
          margin: 0 0 12px;
        }
      }

      .preview-item {
        margin: 12px 0;
        font-size: 14px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 768px) {
          margin: 8px 0;
          font-size: 13px;
        }

        .label {
          color: var(--el-text-color-secondary);
          margin-right: 8px;
        }

        .value {
          color: var(--el-text-color-primary);
        }
      }

      .preview-tips {
        margin-top: auto;
        padding-top: 20px;

        @media screen and (max-width: 768px) {
          padding-top: 12px;
        }

        :deep(.el-alert__description) {
          margin: 8px 0 0;
          font-size: 12px;
          color: var(--el-text-color-secondary);
        }
      }
    }
  }

  .form-section {
    flex: 1;
    padding: 20px;
    background: var(--el-bg-color);

    @media screen and (max-width: 768px) {
      padding: 12px;
    }
  }
}

.dialog-footer {
  padding: 20px;
  text-align: right;
  background: var(--el-bg-color);
  border-top: 1px solid var(--el-border-color-light);

  @media screen and (max-width: 768px) {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .el-button {
      width: 100%;
      margin-left: 0 !important;
      height: 40px;
      font-size: 14px;
    }
  }
}
</style> 