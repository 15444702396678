<template>
  <div class="avatar-form">
    <el-form
      ref="formRef"
      :model="formData"
      label-position="top"
      :rules="rules"
    >
      <el-form-item label="名称" prop="name">
        <el-input 
          v-model="formData.name" 
          placeholder="请输入数字人名称" 
        />
      </el-form-item>

      <el-form-item label="描述" prop="description">
        <el-input
          v-model="formData.description"
          type="textarea"
          :rows="2"
          placeholder="请输入数字人描述"
        />
      </el-form-item>

      <el-form-item label="语音" prop="voice">
        <VoiceSelector v-model="formData.voice" />
      </el-form-item>

      <el-form-item label="知识库" prop="knowledgeBaseId">
        <el-select 
          v-model="formData.knowledgeBaseId"
          placeholder="请选择知识库"
          clearable
          style="width: 100%"
        >
          <el-option
            v-for="item in knowledgeBases"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span>{{ item.name }}</span>
            <span class="knowledge-model">{{ item.embeddingModel }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <PromptSelector
        v-model="formData.systemPrompt"
        :avatar-name="formData.name"
      />
    </el-form>
  </div>
</template>

<script>
import { ref, watch, nextTick, onMounted } from 'vue'
import axios from 'axios'
import VoiceSelector from './VoiceSelector.vue'
import PromptSelector from './PromptSelector.vue'

// 添加防抖函数
const useDebouncedFn = (fn, delay) => {
  let timeout
  return (...args) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

export default {
  name: 'AvatarForm',
  components: {
    VoiceSelector,
    PromptSelector
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        name: '',
        description: '',
        voice: '',
        systemPrompt: '',
        knowledgeBaseId: null
      })
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const formRef = ref(null)
    const formData = ref({
      name: '',
      description: '',
      voice: '',
      systemPrompt: '',
      knowledgeBaseId: null
    })
    const knowledgeBases = ref([])

    // 优化后的数据发射函数
    const debouncedEmit = useDebouncedFn((value) => {
      emit('update:modelValue', value)
    }, 300)

    // 简化的对象比较函数
    const isObjectEqual = (obj1, obj2) => {
      if (!obj1 || !obj2) return false
      return Object.keys(obj1).every(key => obj1[key] === obj2[key])
    }

    // 加载知识库列表
    const loadKnowledgeBases = async () => {
      try {
        const response = await axios.get('/api/knowledge/directories')
        if (response.data.code === 0) {
          knowledgeBases.value = response.data.data
          console.log('加载知识库列表:', knowledgeBases.value)
        }
      } catch (error) {
        console.error('加载知识库列表失败:', error)
      }
    }

    // 表单验证规则
    const rules = {
      name: [
        { required: true, message: '请输入数字人名称', trigger: 'blur' },
        { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
      ],
      description: [
        { required: true, message: '请输入数字人描述', trigger: 'blur' },
        { max: 200, message: '不能超过 200 个字符', trigger: 'blur' }
      ],
      voice: [
        { required: true, message: '请选择语音', trigger: 'change' }
      ],
      systemPrompt: [
        { required: true, message: '请输入或选择系统提示词', trigger: 'blur' }
      ]
    }

    // 监听 modelValue 变化
    watch(
      () => props.modelValue,
      (newValue) => {
        if (newValue && !isObjectEqual(newValue, formData.value)) {
          formData.value = {
            name: newValue.name || '',
            description: newValue.description || '',
            voice: newValue.voice || '',
            systemPrompt: newValue.systemPrompt || '',
            knowledgeBaseId: Number(newValue.knowledgeBaseId) || null
          }
        }
      },
      { deep: true, immediate: true }
    )

    // 监听表单数据变化
    watch(
      () => formData.value,
      (newValue) => {
        if (!isObjectEqual(newValue, props.modelValue)) {
          debouncedEmit({ ...newValue })
        }
      },
      { deep: true }
    )

    // 表单验证方法
    const validate = async () => {
      if (!formRef.value) return false
      try {
        await formRef.value.validate()
        return true
      } catch (error) {
        return false
      }
    }

    // 在组件挂载时只加载一次知识库列表
    onMounted(async () => {
      await loadKnowledgeBases()
    })

    return {
      formRef,
      formData,
      rules,
      validate,
      knowledgeBases
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-form {
  :deep(.el-form) {
    .el-form-item {
      margin-bottom: 20px;
      
      .el-form-item__label {
        font-size: 14px;
        color: var(--text-primary);
        padding-bottom: 8px;
        font-weight: 500;
      }

      .el-input__wrapper,
      .el-textarea__inner {
        background-color: var(--el-fill-color-blank);
        border: 1px solid var(--el-border-color);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
        transition: all 0.3s ease;

        &:hover, &:focus {
          border-color: var(--primary-color);
          box-shadow: 0 2px 8px rgba(0, 114, 255, 0.1);
        }
      }
    }
  }

  .knowledge-model {
    float: right;
    color: var(--el-text-color-secondary);
    font-size: 13px;
  }
}
</style> 